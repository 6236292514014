export interface AddressApi {
	_id: {
		state: string
		city: string
	}
	neighborhoods: {
		neighborhood: string
	}[]
}

export type Address = {
	state: string
	city: string
	neighborhoods: {
		neighborhood: string
	}[]
}
