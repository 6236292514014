import { Directive, OnInit, inject } from '@angular/core'
import { AuthService } from './auth.service'
import { Store } from '@ngrx/store'
import { ClientActions } from '../state/client/client.actions'
import { NavigationEnd, Router } from '@angular/router'
import { filter, take } from 'rxjs'
import { LoginPageRegex } from '../tokens/tokens'

@Directive({ selector: '[domainAuthDirective]', standalone: true })
export class AuthDirective implements OnInit {
	private authService = inject(AuthService)
	private store = inject(Store)
	private router = inject(Router)
	private loginRegex = inject(LoginPageRegex)

	ngOnInit(): void {
		this.router.events
			.pipe(
				filter(event => event instanceof NavigationEnd),
				take(1)
			)
			.subscribe(event => {
				const url = (event as NavigationEnd).url
				const match = this.loginRegex.test(url)
				if (!match) {
					const { clientId, token } = this.authService.getClientIdAndToken()
					if (clientId && token) {
						this.store.dispatch(ClientActions.loadClient({ id: clientId }))
						return
					}
				}
			})
	}
}
