import { AbstractControl } from '@angular/forms'

export const virtualCards = [
	'400473',
	'400475',
	'400497',
	'400532',
	'406655',
	'409602',
	'409603',
	'410424',
	'410425',
	'411801',
	'411802',
	'411804',
	'434546',
	'439027',
	'439276',
	'455182',
	'455183',
	'457515',
	'457577',
	'464129',
	'493116',
	'650486',
	'650491',
	'650527',
	'650584',
	'373781',
	'376446',
	'376463',
	'376464',
	'376522',
	'376523',
	'376524',
	'376526',
	'376527',
	'376528',
	'376529',
	'376620',
	'376621',
	'376622',
	'376623',
	'376624',
	'376625',
	'376626',
	'376627',
	'376628',
	'376629',
	'377169',
	'377174',
	'379966',
	'379967',
	'408194',
	'415896',
	'415918',
	'422012',
	'422013',
	'422053',
	'422087',
	'422463',
	'427164',
	'427165',
	'428269',
	'434602',
	'441629',
	'447454',
	'448489',
	'457291',
	'457293',
	'466713',
	'466715',
	'466970',
	'466971',
	'466972',
	'466973',
	'466974',
	'466975',
	'466976',
	'466977',
	'466978',
	'466979',
	'466981',
	'466982',
	'466983',
	'466984',
	'468211',
	'468212',
	'470749',
	'473897',
	'474116',
	'477231',
	'477232',
	'477233',
	'477234',
	'477235',
	'477236',
	'479309',
	'479310',
	'479311',
	'479312',
	'479313',
	'479341',
	'479420',
	'479421',
	'479422',
	'479561',
	'479562',
	'479563',
	'479564',
	'479565',
	'479566',
	'484378',
	'489741',
	'491615',
	'498582',
	'499984',
	'506720',
	'506732',
	'506774',
	'509066',
	'509104',
	'650031',
	'650524',
	'650525',
	'650528',
	'650556',
	'650561',
	'650583',
	'650722',
	'650727',
	'650915',
	'650919',
	'650928',
	'650939',
	'650947',
	'650955',
	'650971',
	'651656',
	'651666',
	'651667',
	'651675',
	'655006',
	'655051',
	'515464',
	'515498',
	'515498',
	'515514',
	'518047',
	'521437',
	'544690',
	'544690',
	'544734',
	'544972',
	'545960',
	'547198',
	'559542',
]
export function binIsNotVirtual(control: AbstractControl): { [key: string]: boolean } | null {
	const bin = control?.value?.substring(0, 6)
	if (virtualCards.includes(bin)) {
		return { invalidBin: true }
	}
	return null
}
