import { createFeatureSelector, createSelector } from '@ngrx/store'
import { OrderHistoryState, featureOrderHistoryName } from './order-history.state'

export const getOrderFeatureState = createFeatureSelector<OrderHistoryState>(featureOrderHistoryName)

export const getOrders = createSelector(getOrderFeatureState, state => state.orders)
export const getOrderError = createSelector(getOrderFeatureState, state => state.errorOrderHistory)
export const loadingOrderHistory = createSelector(
	getOrderFeatureState,
	state => state.statusOrderHistory === 'pending'
)
export const loadingCancelOrder = createSelector(
	getOrderFeatureState,
	state => state.statusCancelOrder === 'pending'
)
export const getStatusCancelOrder = createSelector(getOrderFeatureState, state => state.statusCancelOrder)
export const getCancelOrderError = createSelector(getOrderFeatureState, state => state.errorCancelOrder)
export const getSelectOrder = createSelector(getOrderFeatureState, state => state.selectedOrder)
export const getHasPaymentReady = createSelector(getOrderFeatureState, state => state.hasPaymentReady)
export const getOrdersToPay = createSelector(getOrderFeatureState, state => state.ordersToPay)
