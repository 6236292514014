@if (client$ | async; as client) {
	@if (isLoading()) {
		<div style="height: 50vh; display: flex; justify-content: center; align-items: center">
			<ui-bb-loading-page />
		</div>
	} @else {
		<div class="page px-16">
			<h1 class="bbTitle" style="margin-bottom: 32px">Indique um restaurante</h1>

			<div style="display: flex; gap: 17px">
				<ui-bb-button
					[type]="selectOption === 'Cliente' ? 'secondary' : 'secondary-ghost'"
					style="width: 100%; font-size: 12px"
					(clickButton)="selectOption = 'Cliente'"
				>
					SOU CLIENTE
				</ui-bb-button>
				<ui-bb-button
					[type]="selectOption === 'Responsável' ? 'secondary' : 'secondary-ghost'"
					style="width: 100%; font-size: 12px"
					(clickButton)="selectOption = 'Responsável'"
				>
					SOU RESPONSÁVEL
				</ui-bb-button>
			</div>

			<div class="box-info">
				<div class="info" *ngIf="client">
					<p class="info__title">Nome</p>
					<p class="info__value">{{ client.fullName }}</p>
				</div>
				<div class="info">
					<p class="info__title">Data</p>
					<p class="info__value">
						{{ today | date: "EEEE, d 'de' MMMM" | capitalizeFirst }}
					</p>
				</div>
			</div>

			<div style="height: 16px"></div>

			<form [formGroup]="form" (ngSubmit)="handleSubmit(client)">
				<ng-container *ngIf="form.controls.venueName as venueName">
					<label class="boxi7kr3 input-error-relative">
						<div class="boxi7kr3__title">Nome do restaurante</div>
						<input class="boxi7kr3__input" formControlName="venueName" />
						<div
							class="input-error input-error--touched"
							style="right: 0"
							*ngIf="venueName.touched && venueName.invalid"
						>
							Nome do restaurante obrigatório.
						</div>
					</label>
					<ui-common-divisor
						bottom="0px"
						[ngStyle]="{
							'--content-divider':
								venueName.touched && venueName.invalid ? 'var(--background-negative)' : ''
						}"
					/>
				</ng-container>
				<ng-container *ngIf="form.controls.city as city">
					<label class="boxi7kr3 input-error-relative">
						<div class="boxi7kr3__title">Cidade</div>
						<input class="boxi7kr3__input" formControlName="city" />
						<div
							class="input-error input-error--touched"
							style="right: 0"
							*ngIf="city.touched && city.invalid"
						>
							Nome da cidade obrigatório.
						</div>
					</label>
					<ui-common-divisor
						bottom="0px"
						[ngStyle]="{
							'--content-divider':
								city.touched && city.invalid ? 'var(--background-negative)' : ''
						}"
					/>
				</ng-container>
				<ng-container *ngIf="form.controls.state as state">
					<label class="boxi7kr3 input-error-relative">
						<div class="boxi7kr3__title">Estado</div>
						<input class="boxi7kr3__input" formControlName="state" />
						<div
							class="input-error input-error--touched"
							style="right: 0"
							*ngIf="state.touched && state.invalid"
						>
							Nome do estado obrigatório.
						</div>
					</label>
					<ui-common-divisor
						bottom="0px"
						[ngStyle]="{
							'--content-divider':
								state.touched && state.invalid ? 'var(--background-negative)' : ''
						}"
					/>
				</ng-container>
				<ng-container *ngIf="form.controls.neighborhood as neighborhood">
					<label class="boxi7kr3 input-error-relative">
						<div class="boxi7kr3__title">Bairro</div>
						<input class="boxi7kr3__input" formControlName="neighborhood" />
						<div
							class="input-error input-error--touched"
							style="right: 0"
							*ngIf="neighborhood.touched && neighborhood.invalid"
						>
							Nome do bairro obrigatório.
						</div>
					</label>
					<ui-common-divisor
						bottom="0px"
						[ngStyle]="{
							'--content-divider':
								neighborhood.touched && neighborhood.invalid
									? 'var(--background-negative)'
									: ''
						}"
					/>
				</ng-container>
			</form>
		</div>

		<ui-bb-footer [position]="isMobile ? 'fixed' : 'static'">
			<ui-bb-button
				type="secondary"
				style="width: 343px"
				(clickButton)="handleSubmit(client)"
				footer
				[disabled]="!selectOption || form.invalid"
			>
				ENVIAR
			</ui-bb-button>
		</ui-bb-footer>
	}
}

<ui-bb-modal-bottom title="Indicação enviada!" height="260px" #modalSuccess>
	<div style="margin-top: 20px">
		<p style="font-size: 16px; color: #585b65">
			Obrigada por indicar um restaurante! <br />
			Entraremos em contato em breve.
		</p>
	</div>
	<div style="height: 36px"></div>
</ui-bb-modal-bottom>

<ui-bb-modal-bottom-failure title="Error" #modalFailure>
	<div style="margin-top: 20px">
		<p style="font-size: 16px; color: #585b65">Oops. Algum erro ocorreu.</p>
	</div>
</ui-bb-modal-bottom-failure>
