import { Injectable, inject } from '@angular/core'
import { Observable, fromEvent, combineLatest, map } from 'rxjs'
import { isAllLoaded, loadingMoreVenues } from './venue.selectors'
import { Store } from '@ngrx/store'

@Injectable({ providedIn: 'root' })
export class InfiniteScrollService {
	private store = inject(Store)
	scrollAction$: Observable<Event> = fromEvent(document, 'scroll')
	isAllLoaded$: Observable<boolean> = this.store.select(isAllLoaded)
	isLoadingMoreVenues$ = this.store.select(loadingMoreVenues)

	loadMoreVenue$: Observable<boolean> = combineLatest([
		this.scrollAction$,
		this.isAllLoaded$,
		this.isLoadingMoreVenues$,
	]).pipe(
		map(([, allLoaded, isLoadingMoreVenues]) => {
			if (!allLoaded && !isLoadingMoreVenues) {
				return (
					document.documentElement.scrollTop >=
					0.8 * document.documentElement.scrollHeight - document.documentElement.clientHeight
				)
			}
			return false
		})
	)
}
