<!--<ng-container *ngIf="orderAction$ | async as orderAction">
	<ui-bb-alert-top
		*ngIf="orderAction.type === 'cancel' && orderAction.value"
		type="secondary"
		icon="fa fa-success-filled"
	>
		Sua reserva foi cancelada.
	</ui-bb-alert-top>
</ng-container>-->
<ng-container *ngIf="cancelOrder$ | async as statusCancel">
	<div
		[style]="isMobile ? 'height: calc(100vh - 152px); margin: 0' : 'height:600px'"
		*ngIf="statusCancel === 'pending'; else noLoadCancel"
	>
		<ui-bb-loading-page>Cancelando reserva</ui-bb-loading-page>
	</div>
</ng-container>

<ng-template #noLoadCancel>
	<div class="px-16 page"><p class="title">Reservas e pagamento</p></div>

	<div class="emptyOrders" *ngIf="(orders$ | async)?.length === 0">
		<p>Você não possui reservas no momento.</p>
	</div>

	<div
		*ngIf="loadingHistory$ | async"
		[style]="isMobile ? 'height: calc(100vh - 152px); margin: 0' : 'height:520px'"
	>
		<ui-bb-loading-page>Carregando</ui-bb-loading-page>
	</div>

	<main class="page px-16" *ngIf="(loadingHistory$ | async) === false">
		<ng-container *ngFor="let order of orders$ | async; let last = last">
			<article class="order" (click)="goOrderDetails(order)">
				<div
					class="cardOrder"
					*ngIf="
						order.canPay || (order.canCancel$ | async) || order.payment || order.paymentBlocked
					"
					[ngClass]="{ cardOrderPay: order.payment || order.paymentBlocked }"
				>
					<div style="width: 100%">
						<div class="cardOrder__header">
							<p>{{ order.reservationDateTime | date: 'dd/MM/YYYY' }}</p>
							<i style="font-size: 4px" class="tagmeicon tagmeicon-cor iconTxt"></i>
							<p>{{ order.intent }}</p>
						</div>
						<p class="cardOrder__venueName">{{ order.venue.name }}</p>
						<p
							style="cursor: pointer"
							*ngIf="order.canCancel$ | async"
							class="cardOrder__cancel"
							(click)="openModalCancel(order, $event)"
						>
							Cancelar reserva
						</p>
						<ng-container *ngIf="order.payment">
							<p class="cardOrder__alert">
								<i class="fa fa-success-filled"></i> Pagamento realizado
							</p>
							<ui-common-divisor />
							<div class="cardOrder__points">
								<p class="cardOrder__points__title">Pontos acumulados</p>
								<p class="cardOrder__points__info">
									{{ order.payment.points }} pontos Livelo
								</p>
							</div>
						</ng-container>
						<ng-container *ngIf="order.paymentBlocked">
							<ui-common-divisor />
							<p class="cardOrder__alertDanger">
								<i class="fa fa-close-round"></i> Reserva cancelada
							</p>
						</ng-container>
					</div>

					<ui-bb-button
						*ngIf="order.canPay"
						type="primary"
						style="width: 170px"
						(clickButton)="goToPayment(order)"
					>
						PAGAR CONTA
					</ui-bb-button>
				</div>

				<!--<div class="cardOrderPay" *ngIf="(order.payment)">
				<div>
					<div class="cardOrderPay__header">
						<p>{{order.reservationDateTime | date: 'dd/MM/YYYY'}}</p>
						<i style="font-size: 4px" class="tagmeicon tagmeicon-cor iconTxt"></i>
						<p>{{order.intent}}</p>
					</div>
					<p class="cardOrderPay__venueName">{{order.venue.name}}</p>
					<p class="cardOrderPay__alert">
						<i class="fa fa-success-filled"></i> Pagamento realizado
					</p>
					<ui-common-divisor />
					<div class="cardOrderPay__points">
						<p class="cardOrderPay__points__title">Pontos acumulados</p>
						<p class="cardOrderPay__points__info">{{order.payment.points}} pontos Livelo</p>
					</div>
				</div>
			</div>

			<div class="cardOrderPay" *ngIf="((order.payment) && (order.canCancel$ | async)) === false">
				<div>
					<div class="cardOrderPay__header">
						<p>{{order.reservationDateTime | date: 'dd/MM/YYYY'}}</p>
						<i style="font-size: 4px" class="tagmeicon tagmeicon-cor iconTxt"></i>
						<p>{{order.intent}}</p>
					</div>
					<p class="cardOrderPay__venueName">{{order.venue.name}}</p>
					<p class="cardOrderPay__alertDanger">
						<i class="fa fa-close-round"></i> Pagamento não identificado
					</p>
				</div>
			</div>-->
			</article>
		</ng-container>
		<div style="height: 20px"></div>
	</main>
</ng-template>
