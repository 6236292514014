import { Component, Input } from '@angular/core'
import { CommonModule } from '@angular/common'

@Component({
	selector: 'ui-bb-generic-error',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './generic-error.component.html',
	styleUrls: ['./generic-error.component.scss'],
})
export class GenericErrorUiComponent {
	@Input() title!: string
	@Input() type: string = 'error'
}
