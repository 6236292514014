import { createFeatureSelector, createSelector } from '@ngrx/store'
import { ClientState, featureClientName } from './client.state'

export const getClientFeatureState = createFeatureSelector<ClientState>(featureClientName)

export const getClient = createSelector(getClientFeatureState, state => state.client)
export const getStatusClient = createSelector(getClientFeatureState, state => state.loginStatus)
export const getRegisterCardStatus = createSelector(getClientFeatureState, state => state.registerCardStatus)

export const isLoadingRegisterCard = createSelector(
	getClientFeatureState,
	state => state.registerCardStatus === 'pending'
)

export const isLoadingMainCard = createSelector(
	getClientFeatureState,
	state => state.mainCardStatus === 'pending'
)

export const mainCardStatus = createSelector(getClientFeatureState, state => state.mainCardStatus)

export const isLoadingRemoveCard = createSelector(
	getClientFeatureState,
	state => state.removeCardStatus === 'pending'
)

export const isLoggedIn = createSelector(getClientFeatureState, state => state.isLoggedIn)
export const getErrorLogin = createSelector(getClientFeatureState, state => state.errorLoginStatus)
export const getErrorRegisterCard = createSelector(getClientFeatureState, state => state.errorRegisterCard)
export const getErrorMainCard = createSelector(getClientFeatureState, state => state.errorMainCard)
export const getErrorRemoveCard = createSelector(getClientFeatureState, state => state.errorRemoveCard)

export const getUpdateClientBackendStatus = createSelector(
	getClientFeatureState,
	state => state.statusUpdateClientBackend
)
