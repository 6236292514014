import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core'
import { CommonModule } from '@angular/common'

import {
	TitleUiComponent,
	TicketUiComponent,
	FooterUiComponent,
	ButtonUiComponent,
	ConfirmSliderUiComponent,
	CounterUiComponent,
	LoadingPageComponent,
	TermsAndConditionsComponent,
	ModalBottomFailureComponent,
	OrderSuccessComponent,
} from '@monorepo-channels/components/ui-bb'

import { Store } from '@ngrx/store'
import {
	CheckinActions,
	CheckinDate,
	VenueActions,
	getCheckinAvailabilities,
	getCheckinStatusAvailabilities,
	getClient,
	getSelectedVenue,
	getCheckinStatusConfirm,
	ClientActions,
} from '@monorepo-channels/channels/domain'

import { ActivatedRoute, Router, RouterLink } from '@angular/router'
import { combineLatest, map } from 'rxjs'

import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { CapitalizeFirstPipe, FormatDateHrs, isMobile } from '@monorepo-channels/shared/util-helpers'
import { Actions } from '@ngrx/effects'
import { ModalCenterComponent } from '@monorepo-channels/components/ui-bb'

@Component({
	standalone: true,
	selector: 'feature-bb-checkin',
	imports: [
		CommonModule,
		TitleUiComponent,
		FooterUiComponent,
		TicketUiComponent,
		ButtonUiComponent,
		ConfirmSliderUiComponent,
		FormatDateHrs,
		CounterUiComponent,
		LoadingPageComponent,
		CapitalizeFirstPipe,
		TermsAndConditionsComponent,
		ModalBottomFailureComponent,
		RouterLink,
		OrderSuccessComponent,
		ModalCenterComponent,
	],
	templateUrl: './checkin.component.html',
	styleUrls: ['./checkin.component.scss'],
})
export class CheckinComponent implements OnInit {
	private store = inject(Store)
	private route = inject(ActivatedRoute)
	private actions$ = inject(Actions)
	router = inject(Router)

	@Output() seeReservation = new EventEmitter<void>()
	@Output() registerCard = new EventEmitter<void>()
	@Output() seeLater = new EventEmitter<void>()
	@Output() goOrders = new EventEmitter<void>()

	sliderValue = false
	checkinDate = new CheckinDate()
	partySize = 1
	eula = false
	isDisabled = !this.sliderValue || !this.eula
	venueId: string | null = null
	isMobile = isMobile()

	vm$ = combineLatest([
		this.store.select(getClient),
		this.store.select(getCheckinStatusAvailabilities),
		this.store.select(getCheckinAvailabilities),
		this.store.select(getCheckinStatusConfirm),
		this.store.select(getSelectedVenue),
	]).pipe(
		map(([client, statusAvailability, availabilities, statusConfirm, venue]) => ({
			client,
			statusAvailability,
			availabilities,
			statusConfirm,
			venue,
		}))
	)

	constructor() {
		this.store
			.select(getClient)
			.pipe(takeUntilDestroyed())
			.subscribe(client => {
				this.eula = client?.agreements.eula ?? false
				this.isDisabled = !this.sliderValue || !this.eula
			})
	}

	ngOnInit(): void {
		this.store.dispatch(CheckinActions.checkinReset())
		this.venueId = this.route.snapshot.paramMap.get('venueId')
		if (this.venueId) {
			this.store.dispatch(VenueActions.getOneVenue({ venueId: this.venueId }))
			this.store.dispatch(CheckinActions.loadAvailabilities({ venueId: this.venueId }))
		}
		this.store
			.select(getClient)
			.pipe(takeUntilDestroyed())
			.subscribe(client => {
				this.eula = client?.agreements.eula ?? false
				this.isDisabled = !this.sliderValue || !this.eula
			})
	}

	changeSlider(value: boolean) {
		this.sliderValue = value
		this.isDisabled = !this.sliderValue || !this.eula
	}

	acceptTerms(event: boolean) {
		this.eula = event
		this.isDisabled = !this.sliderValue || !this.eula
	}

	setPartysize(partysize: number) {
		this.partySize = partysize
	}

	confirmCheckin() {
		this.store.dispatch(
			CheckinActions.checkInConfirmation({ checkInDate: this.checkinDate, partySize: this.partySize })
		)
		this.store.dispatch(
			ClientActions.updateClientBackend({ client: { agreements: { eula: this.eula } } })
		)
	}

	goBack() {
		this.store.dispatch(CheckinActions.checkinReset())
		window.history.back()
	}

	goToReservation() {
		if (this.venueId) {
			this.router.navigate([`/restaurante/${this.venueId}/reseva`])
		}
	}

	goToOrders() {
		if (this.isMobile) {
			this.router.navigate(['/reservas-e-pagamento'])
		} else {
			this.goOrders.emit()
		}
	}
}
