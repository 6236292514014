<bb-header></bb-header>
<ui-bb-swiper [urls]="urlsImg" (bannerClicked)="clickBanner($event)"></ui-bb-swiper>

<div class="space"></div>

<feature-bb-alert-home
	(registerCardClicked)="clickRegisterCard()"
	(payClicked)="clickPay($event)"
></feature-bb-alert-home>

<main class="venueList">
	<feature-bb-filter (searchNameChanged)="setSearchName($event)" />
	<div class="venueList__boxTitle"><p>Restaurantes</p></div>
	<feature-bb-venue-card-list [searchName]="searchName" />
</main>

<feature-bb-handle-login />
<div style="height: 24px"></div>
