import { Component, EventEmitter, inject, Output, signal, ViewChild } from '@angular/core'
import { CommonModule } from '@angular/common'

import {
	FooterUiComponent,
	ButtonUiComponent,
	ModalBottomUiComponent,
	ModalBottomFailureComponent,
	LoadingPageComponent,
} from '@monorepo-channels/components/ui-bb'
import { Router } from '@angular/router'
import {
	isMobile,
	CapitalizeFirstPipe,
	SuggestRestaurantService,
} from '@monorepo-channels/shared/util-helpers'
import { Store } from '@ngrx/store'
import { Client, getClient } from '@monorepo-channels/channels/domain'
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms'
import { DivisorUiComponent } from '@monorepo-channels/components/ui-common'

@Component({
	standalone: true,
	selector: 'bb-suggest-a-restaurant-body',
	imports: [
		CommonModule,
		FooterUiComponent,
		ButtonUiComponent,
		CapitalizeFirstPipe,
		ReactiveFormsModule,
		DivisorUiComponent,
		ModalBottomUiComponent,
		ModalBottomFailureComponent,
		LoadingPageComponent,
	],
	templateUrl: './suggest-a-restaurant-body.component.html',
	styleUrls: ['./suggest-a-restaurant-body.component.scss'],
})
export class SuggestARestaurantBodyComponent {
	private store = inject(Store)
	private suggestRestaurantService = inject(SuggestRestaurantService)
	router = inject(Router)
	isMobile = isMobile()
	selectOption: null | 'Cliente' | 'Responsável' = 'Cliente'
	client$ = this.store.select(getClient)
	today = new Date()
	isLoading = signal(false)

	@ViewChild('modalSuccess')
	modalSuccess!: ModalBottomUiComponent

	@ViewChild('modalFailure')
	modalFailure!: ModalBottomFailureComponent

	@Output()
	clickSubmit = new EventEmitter<string>()

	private fb = inject(FormBuilder)
	form = this.fb.nonNullable.group({
		venueName: [null, [Validators.required]],
		city: [null, Validators.required],
		state: [null, Validators.required],
		neighborhood: [null, Validators.required],
	})

	handleSubmit(client: Client) {
		if (this.form.invalid) {
			return
		}
		this.isLoading.set(true)
		const formValuesString = `
<pre>
Origem: BB Gastronomia
Indicação do: ${this.selectOption}
Nome: ${client.fullName}
Email: ${client.email}
Nome do Restaurante: ${this.form.value.venueName}
Local:${this.form.value.city} - ${this.form.value.state}, bairro: ${this.form.value.neighborhood}</pre>`
		this.suggestRestaurantService.sendEmail(formValuesString).subscribe({
			next: () => {
				if (!this.isMobile) {
					this.clickSubmit.emit('success')
				} else {
					this.modalSuccess.openModal()
				}
			},
			error: () => {
				if (!this.isMobile) {
					this.clickSubmit.emit('failure')
				} else {
					this.modalFailure.openModal()
				}
			},
			complete: () => {
				this.isLoading.set(false)
			},
		})
	}
}
