import { Route } from '@angular/router'
import { HowItWorkPageComponent } from './pages/menu/how-it-work/how-it-work.page'
import { FaqComponent } from './pages/menu/faq/faq.component'
import { TermsOfUseComponent } from './pages/menu/terms-of-use/terms-of-use.component'
import { ContactsComponent } from './pages/menu/contacts/contacts.component'
import { authGuard } from '@monorepo-channels/channels/domain'
import { NewCardComponent } from './pages/wallet/new-card/new-card.component'
import { WalletPageComponent } from './pages/wallet/wallet.page'
import { VenueListPageComponent } from './pages/venues-list/venues-list.page'
import { VenueDetailsPageComponent } from './pages/venues-list/venue-details/venue-details.page'
import { ReservationPageComponent } from './pages/reservation/reservation.page'
import { CompleteRegistrationComponent } from './pages/complete-registration/complete-registration.component'
import { PaymentPageComponent } from './pages/payment/payment.page'
import { OrdersHistoryPageComponent } from './pages/orders-history/orders-history.page'
import { OrderDetailsPageComponent } from './pages/orders-history/order-details/order-details.page'
import { CheckinPageComponent } from './pages/checkin/checkin.page'
import { LoginBBPageComponent } from './pages/login-bb/login-bb.component'
import { SuggestARestaurantPageComponent } from './pages/menu/suggest-a-restaurant/suggest-a-restaurant.page'

export const appRoutes: Route[] = [
	{
		path: '',
		component: VenueListPageComponent,
		title: 'BB Gastronomia',
	},
	{
		path: 'login/complete-seu-cadastro',
		component: CompleteRegistrationComponent,
		title: 'BB Gastronomia - Complete seu cadastro',
	},
	{
		path: 'como-funciona',
		component: HowItWorkPageComponent,
		title: 'BB Gastronomia - Como funciona',
	},
	{
		path: 'faq',
		component: FaqComponent,
		title: 'BB Gastronomia - FAQ',
	},
	{
		path: 'terms',
		component: TermsOfUseComponent,
		title: 'BB Gastronomia - Termos e condições',
	},
	{
		path: 'contacts',
		component: ContactsComponent,
		title: 'BB Gastronomia - Contatos',
	},
	{
		path: 'indique-um-restaurante',
		component: SuggestARestaurantPageComponent,
		title: 'BB Gastronomia - Indique um restaurante',
		canActivate: [authGuard],
	},
	{
		path: 'restaurante/:venueId',
		component: VenueDetailsPageComponent,
		title: 'BB Gastronomia - Restaurante',
	},
	{
		path: 'restaurante/:venueId/reserva',
		component: ReservationPageComponent,
		title: 'BB Gastronomia - Restaurante - Reserva',
		canActivate: [authGuard],
	},
	{
		path: 'restaurante/:venueId/checkin',
		component: CheckinPageComponent,
		title: 'BB Gastronomia - Restaurante - Checkin',
		canActivate: [authGuard],
	},
	{
		path: 'carteira',
		component: WalletPageComponent,
		title: 'BB Gastronomia - Carteira',
		canActivate: [authGuard],
	},
	{
		path: 'carteira/cadastrar-cartao',
		component: NewCardComponent,
		title: 'BB Gastronomia - Cadastro de cartão',
		canActivate: [authGuard],
	},

	{
		path: 'reservas-e-pagamento',
		component: OrdersHistoryPageComponent,
		title: 'BB Gastronomia - Histórico de reservas/checkins',
		canActivate: [authGuard],
	},
	{
		path: 'complete-seu-cadastro',
		component: CompleteRegistrationComponent,
		title: 'BB Gastronomia - Complete seu cadastro',
		canActivate: [authGuard],
	},
	{
		path: 'reservas-e-pagamento/reserva/:orderId',
		title: 'BB Gastronomia - Detalhes da Reserva',
		component: OrderDetailsPageComponent,
		canActivate: [authGuard],
	},
	{
		path: 'reservas-e-pagamento/pagamento/:orderId',
		component: PaymentPageComponent,
		title: 'BB Gastronomia - Pagamento',
		canActivate: [authGuard],
	},
	{
		path: 'login-bb',
		component: LoginBBPageComponent,
		title: 'BB Gastronomia - Login BB',
	},
	{ path: '**', redirectTo: '', pathMatch: 'full' },
]
