import { Address } from '../vo/address'
import { CoverURL } from '../vo/coverURL'
import { VenuesFilterProperties } from './venues-filter.interface'
import { Benefits } from '../benefits/benefits'

export class VenueFilter {
	id: string
	name: string
	cuisines: string[]
	address: Address
	coverURL: CoverURL
	isNotEmpty: boolean
	benefits!: Benefits[]
	constructor(props: VenuesFilterProperties | null) {
		// isNotEmpty
		this.isNotEmpty = Boolean(props)

		this.id = props?._id ?? ''
		this.name = props?.name ?? ''
		this.cuisines = props?.cousines.map(cuisine => cuisine.name) ?? ['']

		if (props) {
			this.benefits = props.benefits.map(benefit => new Benefits(benefit))
		}

		// address
		this.address = new Address(
			props?.address.city ?? '',
			props?.address.state ?? '',
			props?.address.neighborhood ?? '',
			props?.address.address ?? '',
			props?.address.number ?? ''
		)

		// coverURL
		this.coverURL = new CoverURL(
			props?.partner?.images?.thumb[0]?.coverUrl,
			props?.partner?.images?.thumb[0]?.optimizedCoverUrl
		)
	}
}
