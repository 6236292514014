import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'
import { DivisorUiComponent } from '@monorepo-channels/components/ui-common'
import { MatIconModule } from '@angular/material/icon'

@Component({
	standalone: true,
	selector: 'bb-contacts-body',
	imports: [CommonModule, DivisorUiComponent, MatIconModule],
	templateUrl: './contacts-body.component.html',
	styleUrls: ['./contacts-body.component.scss'],
})
export class ContactsBodyComponent {}
