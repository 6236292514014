import { Component, HostListener, inject, ViewChild } from '@angular/core'
import { NavigationEnd, Router, RouterModule } from '@angular/router'
import { AuthDirective } from '@monorepo-channels/channels/domain'
import { ModalBottomUiComponent } from '@monorepo-channels/components/ui-bb'
import { CampaignService, DataLayerService } from '@monorepo-channels/shared/util-helpers'
import { take } from 'rxjs'
import { ContactsBodyComponent } from './pages/menu/contacts/contacts-body/contacts-body.component'

@Component({
	standalone: true,
	imports: [RouterModule, ModalBottomUiComponent, ContactsBodyComponent],
	selector: 'bb-root',
	template: ` <router-outlet></router-outlet>
		<ui-bb-modal-bottom title="conexão perdida" overflow="auto" height="230px" #modalnosignal>
			<div style="margin-top: 20px">
				<p style="font-size: 16px; color: #585b65">
					parece haver um problema na conexão com o servidor, verifique sua conexão e recarregue a
					página.
				</p>
			</div>
		</ui-bb-modal-bottom>`,

	styleUrl: './app.component.scss',
	hostDirectives: [AuthDirective],
})
export class AppComponent {
	private router = inject(Router)
	private dataLayerService = inject(DataLayerService)

	@ViewChild('modalNoSignal')
	modalNoSignal!: ModalBottomUiComponent

	@HostListener('window:offline')
	setNetworkOffline(): void {
		this.modalNoSignal.openModal()
	}

	constructor() {
		inject(CampaignService).isActive(new Date()).pipe(take(1)).subscribe()
		this.router.events.subscribe(event => {
			// subscribe to router events
			if (event instanceof NavigationEnd) {
				// if our event is of our interest
				this.dataLayerService.logPageView(event.url) //call our dataLayer service's page view method to ping home with the url value.
			}
		})
	}
}
