import { CommonModule } from '@angular/common'
import { Component, Input, OnInit } from '@angular/core'

const templates = {
	accumulatePoints: false,
	makeItEasierPayment: false,
	checkinUnavailable: false,
	reservationInfo: false,
}

type Templates = keyof typeof templates

@Component({
	standalone: true,
	imports: [CommonModule],
	selector: 'ui-bb-box-message',
	templateUrl: './box-message.component.html',
	styleUrl: './box-message.component.scss',
})
export class BoxMessageComponent implements OnInit {
	@Input({ required: true }) template!: Templates

	templates = { ...templates }

	ngOnInit(): void {
		this.templates[this.template] = true
	}
}
