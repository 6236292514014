import { Component, EventEmitter, Output, OnInit, Input } from '@angular/core'
import { CommonModule, registerLocaleData, DatePipe } from '@angular/common'
import localePt from '@angular/common/locales/pt'

import { BoxInfoUiComponent } from '../box-info/box-info.component'
import { formatDate } from '@monorepo-channels/shared/util-helpers'
import { DivisorUiComponent } from '@monorepo-channels/components/ui-common'

registerLocaleData(localePt)

@Component({
	selector: 'ui-bb-calendar',
	standalone: true,
	imports: [CommonModule, BoxInfoUiComponent, DivisorUiComponent],
	templateUrl: './calendar.component.html',
	styleUrls: ['./calendar.component.scss'],
	//providers: [{ provide: LOCALE_ID, useValue: 'pt' }],
})
export class CalendarUiComponent implements OnInit {
	seeCalendar = false
	datePipe = new DatePipe('pt')
	teste = this.datePipe.transform(new Date(), "EEEE, d 'de' MMMM")
	today: Date = new Date()
	days: Date[] = []
	_selectedDate: Date | null = null
	@Output() daySelected = new EventEmitter<{ dateString: string; date: Date }>()
	@Input() availabilitiesDates!: Date[]

	@Input()
	set selectedDate(value: string | null) {
		if (value) {
			this._selectedDate = new Date(`${value}T10:00:00.000Z`)
		}
	}

	get transformedDate() {
		return this._selectedDate
	}

	ngOnInit() {
		this.buildCalendar()
	}

	changeMonth(offsetMonth: number) {
		this.today.setMonth(this.today.getMonth() + offsetMonth)
		this.today = new Date(this.today.getTime())
		this.buildCalendar()
	}

	getFirstDayAvailable() {
		const day = new Date()
		let attempts = 0
		const maxAttempts = 50

		while (!this.availabilityDate(day) && attempts < maxAttempts) {
			day.setDate(day.getDate() + 1)
			attempts++
		}

		if (attempts === maxAttempts) {
			console.error('Exceeded maximum attempts. No available day found.')
			return null
		}

		return day
	}

	beforeToday(day: Date) {
		const NOW = new Date()
		NOW.setHours(0, 0, 0, 0)
		return day.getTime() < NOW.getTime()
	}

	notMonth(day: Date) {
		return this.today.getMonth() !== day.getMonth()
	}

	selectDay(day: Date) {
		if (this.beforeToday(day) || !this.availabilityDate(day)) {
			return
		}
		this._selectedDate = day
		if (day.getMonth() < this.today.getMonth()) {
			this.changeMonth(-1)
		} else if (day.getMonth() > this.today.getMonth()) {
			this.changeMonth(+1)
		}
		this.daySelected.emit({ date: day, dateString: formatDate(day) })
	}

	availabilityDate(day: Date) {
		const adjustedDay = new Date(day)
		adjustedDay.setHours(0, 0, 0, 0)
		if (this._selectedDate) {
			const adjustedSelectedDay = new Date(this._selectedDate.getTime())
			adjustedSelectedDay.setHours(0, 0, 0, 0)
			if (adjustedSelectedDay.getTime() === adjustedDay.getTime()) {
				return false
			}
		}
		for (const date of this.availabilitiesDates) {
			const adjusteAvailabilityDate = new Date(date)
			adjusteAvailabilityDate.setHours(0, 0, 0, 0)
			if (adjusteAvailabilityDate.getTime() === adjustedDay.getTime()) {
				return true
			}
		}
		return false
	}

	isSelected(day: Date) {
		if (!this._selectedDate) return false
		return day.toDateString() === this._selectedDate.toDateString()
	}

	buildCalendar() {
		const year = this.today.getFullYear()
		const month = this.today.getMonth()

		const firstDayOfWeek = 1 // segunda
		const lastDayOfWeek = 0 // domingo

		const dateBegin = new Date(year, month, 1)
		while (dateBegin.getDay() !== firstDayOfWeek) {
			dateBegin.setDate(dateBegin.getDate() - 1)
		}

		const dateEnd = new Date(year, month + 1, 0)
		while (dateEnd.getDay() !== lastDayOfWeek) {
			dateEnd.setDate(dateEnd.getDate() + 1)
		}

		this.days = []
		for (let data = new Date(dateBegin.getTime()); data <= dateEnd; data.setDate(data.getDate() + 1)) {
			this.days.push(new Date(data.getTime()))
		}
	}

	openCalendar() {
		this.seeCalendar = !this.seeCalendar
	}

	onDayClick(day: Date): void {
		if (this.availabilityDate(day)) {
			this.selectDay(day)
			this.openCalendar()
		}
	}
}
