import { AsyncPipe } from '@angular/common'
import { Component, HostListener, inject, ViewChild } from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { Router } from '@angular/router'
import { ClientActions, getClient, getErrorLogin } from '@monorepo-channels/channels/domain'
import { ModalBBService, ModalBottomFailureComponent } from '@monorepo-channels/components/ui-bb'
import { isMobile } from '@monorepo-channels/shared/util-helpers'
import { Actions, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { filter, switchMap } from 'rxjs'

@Component({
	standalone: true,
	selector: 'feature-bb-handle-login',
	imports: [ModalBottomFailureComponent, AsyncPipe],
	template: `
		<ui-bb-modal-bottom-failure title="Falha no login" #modalLoginFailure>
			@if (error) {
				{{ error }}
			} @else {
				Ocorreu uma falha no login. Por favor, tente novamente.
			}
		</ui-bb-modal-bottom-failure>
	`,
})
export class HandleLoginComponent {
	private actions$ = inject(Actions)
	private store = inject(Store)
	private router = inject(Router)
	@ViewChild('modalLoginFailure')
	modalLoginFailure!: ModalBottomFailureComponent
	error: string | null = null
	isMobile = isMobile()
	@HostListener('window:resize')
	onResize() {
		this.isMobile = isMobile()
	}
	private modalBBService = inject(ModalBBService)
	constructor() {
		this.store
			.select(getErrorLogin)
			.pipe(takeUntilDestroyed())
			.subscribe({
				next: error => {
					this.error = error
				},
			})
		this.actions$.pipe(ofType(ClientActions.loadClientFail), takeUntilDestroyed()).subscribe({
			next: () => {
				this.modalLoginFailure.openModal()
			},
		})
		this.actions$
			.pipe(
				ofType(ClientActions.loadClientSuccess),
				switchMap(() => this.store.select(getClient)),
				filter(Boolean),
				takeUntilDestroyed()
			)
			.subscribe({
				next: client => {
					if (!client?.phone) {
						this.isMobile
							? this.router.navigate(['/complete-seu-cadastro'])
							: this.modalBBService.openModalOthers('complete-seu-cadastro')
					}
				},
			})
	}
}
