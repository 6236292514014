import { Component, Input } from '@angular/core'
import { CommonModule } from '@angular/common'
import { InfoSVGComponent } from '@monorepo-channels/components/ui-common'

@Component({
	selector: 'ui-bb-tooltip',
	standalone: true,
	imports: [CommonModule, InfoSVGComponent],
	templateUrl: './tooltip.component.html',
	styleUrl: './tooltip.component.scss',
})
export class TooltipUiComponent {
	@Input() tooltipType: 'primary' | 'secondary' = 'primary'
	@Input() tooltipSize: 'small' | 'large' = 'large'
	@Input() tooltipPosition: 'left' | 'right' = 'right'

	showTooltip = false

	openTooltip() {
		this.showTooltip = true
	}

	clickOverlay() {
		this.showTooltip = false
	}

	get tooltipStyles(): { color: string; backgroundColor: string; width: string; margin: string } {
		const styleTooltip = {
			color: 'var(--primitive-black-500)',
			backgroundColor: 'var(--background-secondary-100)',
			width: '225px',
			margin: '0',
		}

		if (this.tooltipType === 'secondary') {
			styleTooltip.color = 'var(--content-seocondary)'
			styleTooltip.backgroundColor = 'var(--primitives-grey-100)'
		}

		if (this.tooltipSize === 'small') {
			styleTooltip.width = '209px'
		}

		if (this.tooltipPosition === 'left') {
			styleTooltip.margin = '0 0 0 -100px'
		}

		return styleTooltip
	}
}
