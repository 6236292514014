import { HttpClient } from '@angular/common/http'
import { inject, Injectable } from '@angular/core'
import { environment } from '@monorepo-channels/shared/util-environments'
import { PartnerToken } from '../misc/partners.util'

@Injectable({ providedIn: 'root' })
export class SuggestRestaurantService {
	private http = inject(HttpClient)
	private partnerId = inject(PartnerToken)
	constructor() {}

	sendEmail(content: string) {
		const body = {
			venue: this.partnerId,
			origin: 'CHANNELS',
			sender: 'noreply@tagme.com.br',
			content: content,
			subject: 'Indicação de restaurante',
			targets: [{ destination: 'canais@tagme.com.br' }],
		}
		return this.http.post(`${environment.channelsAPIURL}/communications/send`, body)
	}
}
