import { Component, EventEmitter, Input, LOCALE_ID, Output } from '@angular/core'
import { CommonModule } from '@angular/common'

import { BoxInfoUiComponent } from '../box-info/box-info.component'
import { DivisorUiComponent } from '@monorepo-channels/components/ui-common'
import { ReservationState } from '@monorepo-channels/channels/domain'

@Component({
	selector: 'ui-bb-select-environment',
	standalone: true,
	imports: [CommonModule, BoxInfoUiComponent, DivisorUiComponent],
	templateUrl: './select-environment.component.html',
	styleUrls: ['./select-environment.component.scss'],
	providers: [{ provide: LOCALE_ID, useValue: 'pt' }],
})
export class SelectEnvironmentUiComponent {
	@Input() sections: ReservationState['sections'] = []
	@Output() valueChange = new EventEmitter()
	@Input() selectedItem: string | null = null
	seeBox = false

	valueChanged(event: Event) {
		const target = event.target as HTMLInputElement
		this.selectedItem = target.value
		this.seeBox = false
		this.valueChange.emit(this.selectedItem)
	}

	openBox() {
		this.seeBox = !this.seeBox
	}
}
