import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { PaymentOrder, PaymentSuccessResponse } from './payment'

export const PaymentActions = createActionGroup({
	source: 'Payment',
	events: {
		'Set Payment Order': props<{ paymentOrder: PaymentOrder }>(),
		'Create Payment': props<{ dynamicCVV: string }>(),
		'Create Payment Success': props<{ res: PaymentSuccessResponse }>(),
		'Create Payment Failure': props<{ error: string }>(),
		'Reset Payment Status': emptyProps(),
	},
})
