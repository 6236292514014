<div class="page px-16">
	<p class="bbTitle">REGULAMENTO BB GASTRONOMIA</p>
	<br /><br />
	<div class="info">
		<p>
			1. O BB GASTRONOMIA é uma solução oferecida pelo Programa Benefícios BB e Livelo, em parceria com
			a Tagme. <br /><br />

			2. São critérios de elegibilidade ao BB Gastronomia: <br />
			(i) Possuir conta junto à Livelo; <br />
			(ii) Possuir cartão de crédito Ourocard, sob o mesmo CPF de titularidade da conta Livelo; <br />
			(iii) Realizar reserva ou check-in em restaurante participante do BB Gastronomia através do
			aplicativo do Banco do Brasil; <br />
			(iv) Efetuar o cadastro do cartão de crédito Ourocard previsto no item (ii) no BB Gastronomia
			através do aplicativo do Banco do Brasil; <br />
			(v) Efetuar o pagamento da conta de consumo no restaurante participante através do aplicativo do
			Banco do Brasil, utilizando o cartão de crédito Ourocard previsto no item (ii). <br />
			(vi) Para realização do pagamento por meio do aplicativo do Banco do Brasil, previsto no item (v)
			é necessário ter o dispositivo liberado para transações financeiras. <br /><br />

			3. Para que não reste dúvidas quanto à mecânica: para usufruir do acúmulo de pontos Livelo, é
			necessário possuir conta junto à Livelo vinculada ao cartão de crédito Ourocard a ser utilizado
			para pagamento no restaurante participante, e efetuar o check-in ou reserva através do aplicativo
			do Banco do Brasil. Ao chegar ao restaurante participante, deve-se mencionar que a reserva ou
			check-in foi feito pelo BB GASTRONOMIA. O pagamento pelo consumo no restaurante participante
			deverá ser feito pelo aplicativo do Banco do Brasil, usando qualquer cartão de crédito Ourocard
			previamente cadastrado no momento da reserva/check-in. Cabe ressaltar que o check-in,
			diferentemente da reserva, não garante uma mesa para o usuário. <br /><br />

			4. Os pontos serão contabilizados pelo valor pago através do aplicativo considerando somente o
			consumo, excluindo-se a taxa de serviço. <br /><br />

			5. Os Participantes poderão acumular: <br />
			(i) Acúmulo Padrão: 1 (um) Ponto Livelo a cada R$ 1 (um real) em compras para todos os
			Participantes elegíveis, podendo ser alterado pela Tagme a qualquer tempo; ou <br />
			(ii) Acúmulo Especial: 2 (dois) ou mais Pontos Livelo a cada real em campanhas especiais válidas
			por tempo determinado, conforme regras e mecânicas definidas pela Tagme junto ao restaurante
			parceiro na própria oferta/campanha. <br /><br />

			6. Os pontos referentes ao pagamento da conta pelo aplicativo, considerando a condição oferecida
			pelo restaurante naquela data (Acúmulo Padrão ou Acúmulo Especial), serão creditados em até 30
			(tinta) dias úteis na conta Livelo vinculada ao CPF de titularidade do cartão de crédito Ourocard
			utilizado para pagamento. <br /><br />

			7. Em caso de o pagamento ser feito com um cartão de crédito Ourocard que acumule pontos, além dos
			pontos acumulados referentes ao BB Gastronomia, também haverá o ganho dos pontos referentes à
			transação, que serão creditados conforme regulamento de acúmulo padrão Ourocard.
			<br /><br /><br />
		</p>
	</div>

	<p class="bbTitle">TERMOS E CONDIÇÕES DE USO E POLÍTICA DE PRIVACIDADE</p>
	<br />
	<div class="info">
		<p>
			Bem-vindo ao BB GASTRONOMIA.<br /><br />
			A seguir apresentamos a você (USUÁRIO) os Termos e Condições do BB GASTRONOMIA oferecido pelo
			Programa Benefícios BB e Livelo, em parceria com a TAGME. <br /><br />

			A TAGME é uma empresa parceira da LIVELO, responsável pela gestão de reservas e esperas
			multicanais perante os restaurantes participantes do BB GASTRONOMIA. Assim, USUÁRIO, ao realizar
			uma reserva, os seus dados também passam a ser tratados pela TAGME e, portanto, ao BB GASTRONOMIA
			aplica-se este documento e, também, as disposições constantes no seguinte link.<br /><br />

			Este documento estabelece as regras a serem cumpridas por todos que acessam ou utilizam as
			funcionalidades do BB GASTRONOMIA. Como condição para acesso e uso dos serviços oferecidos, o
			USUÁRIO deve declarar que fez a leitura completa e atenta das regras deste documento, estando
			plenamente ciente e de acordo com elas. <br /><br />

			1. INFORMAÇÕES GERAIS SOBRE O BB GASTRONOMIA: <br />
			1.1. Permite ao USUÁRIO ter acesso a sugestões de estabelecimentos gastronômicos e seus serviços,
			tais como: menu completo, sistema de reserva e check-in, entre outros, nas principais capitais e
			cidades do Brasil. <br /><br />

			1.2. Para acessar de forma segura, é de inteira responsabilidade do USUÁRIO dispor de recursos
			compatíveis, serviço de conexão à Internet com antivírus e firewall habilitados, softwares
			devidamente atualizados – tais como navegadores e gerenciadores de download e upload –, além da
			adoção de medidas de segurança mínimas, tais como uso de senha segura. <br /><br />

			1.3. A solução e suas funcionalidades são apresentadas aos USUÁRIOS na maneira como estão
			disponíveis, podendo passar por constantes aprimoramentos e atualizações, obrigando-se a TAGME
			a:<br />
			1.3.1. Preservar a funcionalidade do BB GASTRONOMIA, evitando links quebrados. Manter a utilização
			de layout adequado à usabilidade e navegabilidade, sempre que possível. Envidar os melhores
			esforços no sentido de manter as informações e materiais contidos nesta solução tão precisos,
			atualizados e completos quanto possível. <br />
			1.3.2. Exibir as funcionalidades de maneira clara, completa, precisa e suficiente de modo que
			exista a exata percepção das operações realizadas. <br />
			1.3.3. Garantir, por meio do estado da técnica disponível, o sigilo dos dados pessoais quando
			inseridos nas funcionalidades oferecidas no BB GASTRONOMIA, sendo realizado o tratamento de seus
			dados pessoais com finalidades específicas e de acordo com as bases legais previstas na Lei Geral
			de Proteção de Dados (Lei nº 13.709/18 ou “LGPD”). <br />
			1.3.4. Remover os conteúdos que forem publicados contendo: <br />
			a. Ofensa à honra, imagem, reputação e dignidade de terceiros; <br />
			b. Pornografia, pedofilia, e outras modalidades de satisfação sexual; <br />
			c. Racismo ou discriminação de qualquer natureza; <br />
			d. Bullying, stalking ou qualquer outra espécie de constrangimento ilegal ou assédio; <br />
			e. Manifesta violação a direito autoral ou direito de imagem e personalidade; <br />
			f. Utilização de marcas, símbolos, logotipos ou emblemas de terceiros; <br />
			g. Instigação ou apologia à prática de crimes, como tráfico ou uso de entorpecentes, estupro,
			homicídio, estelionato, dentre outros; <br />
			h. Manifesta prática de ato contrário à lei, à ordem pública ou aos bons costumes; <br />
			i. Uso da violência, disseminação de ódio ou qualquer outra forma de agressão ao bem-estar físico
			e/ou psicológico de alguém; <br />
			j. Atividade comercial similar a TAGME ou que o utilize para angariar recursos por publicidade de
			terceiros ao invés de praticar a interação regular do ambiente; e <br />
			k. Erros ou suspeita de equívocos. <br /><br />

			1.4. A TAGME envida seus esforços para a disponibilidade contínua e permanente do BB GASTRONOMIA.
			No entanto, pode ocorrer, eventualmente, alguma indisponibilidade temporária decorrente de
			manutenção necessária ou mesmo gerada por motivo de força maior, como desastres naturais, falhas
			ou colapsos nos sistemas centrais de comunicação e acesso à Internet ou fatos de terceiro que
			fogem de sua esfera de vigilância e responsabilidade. <br />
			1.4.1. Se isso ocorrer, a TAGME fará o que estiver ao seu alcance para restabelecer o acesso ao BB
			GASTRONOMIA o mais breve possível, dentro das limitações técnicas de seus serviços e dos serviços
			de terceiros, dos quais o BB GASTRONOMIA depende para ficar disponível. <br /><br />

			1.5. Eventuais procedimentos de manutenção serão informados por meio dos canais oficiais de
			comunicação da TAGME (a exemplo, mas não se limitando a e-mails, perfis oficiais em mídias sociais
			ou telefone de atendimento), caso seja necessário que esta fique indisponível por longos períodos.
			<br /><br />

			2. FUNCIONALIDADES DO BB GASTRONOMIA <br />
			2.1. A solução permite ao USUÁRIO ter acesso a sugestões de estabelecimentos gastronômicos e seus
			serviços, tais como: menu completo, sistema de reserva e check-in, entre outros, nas principais
			capitais e cidades do Brasil. <br /><br />

			2.2. Os serviços do BB GASTRONOMIA são destinados a pessoas físicas maiores de 18 (dezoito) anos.
			Caso o USUÁRIO não esteja de acordo com o exigido, deverá solicitar a assistência de seus
			responsáveis legais. <br /><br />

			2.3. Para visualizar a relação de restaurantes participantes do BB GASTRONOMIA, o USUÁRIO deverá
			ser cadastrado no programa de fidelidade da Livelo e acessar a opção BB GASTRONOMIA através do App
			BB, no menu Meus Benefícios. Para usufruir de todos os benefícios oferecidos pela solução, o
			usuário deverá cadastrar o seu cartão de crédito. <br />
			2.3.1. Se a qualquer momento for constatado que o USUÁRIO forneceu informações falsas ou não
			condizentes com a realidade, a TAGME se reserva ao direito de suspender o seu uso, sem prejuízo de
			adotar as medidas que entender cabíveis. <br /><br />

			3. DAS RESPONSABILIDADES E OBRIGAÇÕES DO USUÁRIO <br />
			3.1. O USUÁRIO se obriga a utilizar com retidão e de forma ética o APLICATIVO, obedecendo aos
			propósitos aqui estabelecidos, sempre respeitando as condições que regem a utilização do BB
			GASTRONOMIA e sua finalidade. <br /><br />

			3.2. O USUÁRIO reconhece e afirma que todos os acessos realizados após sua autenticação
			bem-sucedida são interpretados como tendo sido feitos por ele próprio de forma incontestável. Por
			isso, será responsável por todos os acessos e operações no BB GASTRONOMIA praticados e após sua
			identificação bem-sucedida, inclusive aqueles derivados de uso indevido ou divulgação desta para
			terceiros. <br /><br />

			3.3. É de responsabilidade do USUÁRIO deixar seus sistemas de AntiSpam, filtros similares ou
			configurações de redirecionamento de mensagens ajustados de modo que não interfiram no recebimento
			dos comunicados e materiais da TAGME, não sendo aceitável nenhuma escusa caso não tenha tido
			acesso a algum e-mail ou mensagem eletrônica em virtude dos recursos mencionados. <br /><br />

			3.4. Ao acessar o BB GASTRONOMIA, o USUÁRIO declara que irá respeitar todos os direitos de
			propriedade intelectual de titularidade da TAGME, incluindo, mas não se limitando a marcas,
			software, direitos autorais, patentes, tal qual todos os direitos referentes a terceiros que
			porventura estejam, ou estiveram, de alguma forma disponíveis no BB GASTRONOMIA. <br /><br />

			3.5. As funcionalidades que compõem o BB GASTRONOMIA são oferecidas na forma de prestação de
			serviço, não conferindo ao USUÁRIO nenhum direito sobre o software utilizado pelo TAGME ou sobre
			suas estruturas de informática que sustentam o BB GASTRONOMIA. <br />
			3.5.1. O USUÁRIO está ciente de que poderá ter seu acesso ao BB GASTRONOMIA cancelado sem aviso
			prévio caso o utilize para realizar qualquer atividade que viole direitos do TAGME ou de
			terceiros. <br /><br />

			3.6. Não é permitido o acesso às áreas de programação, seu banco de dados ou qualquer outro
			conjunto de informações que faça parte da atividade de Webmastering. <br /><br />

			3.7. Também não é autorizado ao USUÁRIO realizar ou permitir engenharia reversa, nem traduzir,
			decompilar, copiar, modificar, reproduzir, alugar, sublicenciar, publicar, divulgar, transmitir,
			emprestar, distribuir ou, de outra maneira, dispor das ferramentas do BB GASTRONOMIA e de suas
			funcionalidades. <br /><br />

			3.8. No BB GASTRONOMIA é proibida a utilização de softwares spider, ou de mineração de dados, de
			qualquer tipo ou espécie, além de outro aqui não tipificado, mas que atue de modo automatizado,
			tanto para realizar operações massificadas ou para quaisquer outras finalidades. <br /><br />

			3.9. O descumprimento de quaisquer das obrigações aqui estipuladas poderá acarretar na suspensão
			das funcionalidades e do acesso do USUÁRIO, conforme previsto neste documento. <br /><br />

			3.10. A eventual remoção, bloqueio ou suspensão de qualquer conteúdo ou funcionalidade do BB
			GASTRONOMIA em decorrência de alguma reclamação, deverá ser sempre compreendida como demonstração
			de boa-fé e intenção de solução amigável de conflitos, jamais como reconhecimento de culpa ou de
			qualquer infração pela TAGME a direito de terceiro. <br /><br />

			3.11. Na incidência de danos à TAGME ou a terceiros, o responsável se compromete a arcar com todas
			as obrigações de indenizar o sujeito lesado. <br /><br />

			4. DA ISENÇÃO E LIMITAÇÕES DE RESPONSABILIDADE DA TAGME <br />
			4.1. A TAGME não se responsabiliza por quaisquer usos indevidos dos conteúdos disponibilizados no
			BB GASTRONOMIA. É obrigação exclusiva do USUÁRIO verificar a quem pertence a titularidade dos
			conteúdos e solicitar a licença ou autorização necessária. As licenças e autorizações obtidas pela
			TAGME não permitem o uso dos conteúdos por terceiros. <br /><br />

			4.2. A TAGME não se responsabiliza por qualquer dano direto ou indireto ocasionado por eventos de
			terceiros, como ataque de hackers, falhas no sistema, no servidor ou na conexão à internet,
			inclusive por ações de softwares maliciosos como vírus, cavalos de Tróia, e outros que possam, de
			algum modo, danificar recursos ou a conexão do USUÁRIO em decorrência do acesso, utilização ou
			navegação no BB GASTRONOMIA bem como a transferência de dados, arquivos, imagens, textos, áudios
			ou vídeos contidos neste. <br /><br />

			4.3. O USUÁRIO não possui qualquer direito para exigir a disponibilidade do BB GASTRONOMIA
			conforme melhor lhes convêm, tampouco poderá pleitear indenização ou reparação de danos no caso de
			o BB GASTRONOMIA permanecer fora do ar, independente da motivação. <br /><br />

			4.4. A TAGME não detém qualquer responsabilidade pela navegação do USUÁRIO nos links externos
			publicados no BB GASTRONOMIA, sendo dever a leitura de eventuais Termos de Uso e Política de
			Privacidade dos links acessados e agir conforme o determinado. <br />
			4.4.1. A TAGME não verifica, controla, aprova ou garante a adequação ou exatidão das informações
			ou dados disponibilizados em tais links, não sendo, portanto, responsável por prejuízos, perdas ou
			danos ocorridos pela visita a tais sites, cabendo ao interessado verificar a confiabilidade das
			informações e dados ali exibidos antes de tomar alguma decisão ou praticar algum ato. <br /><br />

			5. PRIVACIDADE DO USUÁRIO <br />
			5.1. Os dados pessoais são obtidos (i) diretamente do USUÁRIO por meio do uso do serviço da
			Livelo, (ii) ao se cadastrar voluntariamente no BB GASTRONOMIA ou (iii) ao entrar em contato por
			meio dos canais disponíveis. <br /><br />

			5.2. O BB GASTRONOMIA pode realizar a coleta de dados do USUÁRIO para funcionar adequadamente e
			oferecer a melhor experiência possível da comodidade proposta, a saber:<br />
			a. Dados de uso e acesso ao BB GASTRONOMIA, quais sejam: <br />
			▪ Conexões existentes com outros USUÁRIOS, conforme a espécie; <br />
			▪ Quantidade de interações com outros USUÁRIOS, conforme a espécie; <br />
			▪ Quantidade de interações com o BB GASTRONOMIA, conforme a espécie; <br />
			▪ Lugares marcados como visitados ou nos quais o USUÁRIO se encontra durante determinada
			interação com o BB GASTRONOMIA ou com demais USUÁRIOS; e, <br />
			▪ Frequência de uso do BB GASTRONOMIA e duração de interações realizadas, quando possível de
			serem medidas. <br /><br />

			5.3. Os dados coletados estarão armazenados em ambiente seguro, observado o estado da técnica
			disponível, e somente serão ser acessados por pessoas qualificadas e autorizadas pela TAGME.
			<br /><br />

			5.4. Considerando que nenhum sistema de segurança é absolutamente seguro, a TAGME se exime de
			quaisquer responsabilidades por eventuais danos e/ou prejuízos decorrentes de falhas, vírus ou
			invasões do banco de dados utilizado pelo BB GASTRONOMIA, salvo nos casos em que tiver agido com
			dolo ou culpa. <br /><br />

			5.5. Os dados do USUÁRIO descritos em 5.1., além dos conteúdos de caráter não privado poderão ser
			utilizados para as seguintes finalidades: <br />
			a. Responder a eventuais dúvidas e solicitações do USUÁRIO; <br />
			b. Cumprimento de ordem legal ou judicial; <br />
			c. Constituir, defender ou exercer regularmente direitos em âmbito judicial ou administrativo;
			<br />
			d. Garantir a segurança do USUÁRIO; <br />
			e. Manter atualizados os dados de cadastro do USUÁRIO e para fins de contato por telefone fixo,
			celular, correio eletrônico, SMS, mala direta, redes sociais ou por outros meios de comunicação;
			<br />
			f. Realizar o enriquecimento da base de dados dos USUÁRIOS com a finalidade de customização dos
			serviços, melhor atendimento, promoção do BB GASTRONOMIA e desenvolvimento de novos produtos da
			TAGME; <br />
			g. Promover o BB GASTRONOMIA e/ou de futuros softwares desenvolvidos ou comercializados pela TAGME
			aos contatos do USUÁRIO por meio de envio de mensagens instantâneas ou mensagens por meio do BB
			GASTRONOMIA; <br />
			h. Promover o serviço e seus parceiros, comerciais ou não, e informar sobre novas oportunidades e
			benefícios para o USUÁRIO; <br />
			i. Gerar análises e estudos, sejam estatísticos ou identificáveis, com base no comportamento de
			uso das funcionalidades do BB GASTRONOMIA; <br />
			j. Aperfeiçoar o uso e a experiência interativa durante a navegação do USUÁRIO no BB GASTRONOMIA;
			e, <br />
			k. Aprimorar o funcionamento do BB GASTRONOMIA bem como dos demais serviços da TAGME que se
			relacionam, direta ou indiretamente, com o BB GASTRONOMIA, seu ambiente ou propostas de uso.
			<br /><br />

			5.6. A base de dados formada pela TAGME, por meio da coleta de dados do pelo BB GASTRONOMIA não
			será compartilhada, vendida, cedida, transferida, informada ou alugada a terceiros, podendo ser
			compartilhada apenas com o grupo econômico da TAGME ou parceiros de negócios, como o Banco do
			Brasil. <br />
			5.6.1 Mediante a utilização do BB GASTRONOMIA, a Livelo poderá tratar, coletar, armazenar e
			compartilhar seus dados pessoais e informações cadastrais com as sociedades sob controle direto ou
			indireto do Banco do Brasil, sempre com a estrita observância à Lei, para fazer ofertas de
			produtos e serviços adequados e relevantes aos seus interesses e necessidades de acordo com o seu
			perfil, para outras hipóteses baseadas em finalidades legítimas ou para a prestação de serviços
			que beneficiem os clientes. <br /><br />

			5.7. Os dados adquiridos somente poderão ser acessados por profissionais devidamente autorizados
			pela TAGME, respeitando a necessidade a que serão submetidos, a relevância para os objetivos do BB
			GASTRONOMIA e os interesses do USUÁRIO, além de preservar sua privacidade, nos termos da lei e
			deste documento. <br /><br />

			5.8. Caso o USUÁRIO deixe de utilizar os serviços disponibilizados pelo BB GASTRONOMIA, a TAGME
			poderá, para fins de auditoria e preservação de direitos, permanecer com o registro de dados do
			USUÁRIO pelo período máximo de 5 (cinco) anos, a não ser que seja exigido mantê-los por período
			superior por motivo legal, requisição administrativa de autoridade ou ordem judicial, possuindo a
			faculdade de excluí-los definitivamente segundo sua conveniência, nos termos da lei. <br /><br />

			5.9. O USUÁRIO poderá exigir da TAGME os dados registrados que lhe dizem respeito, da mesma forma
			que poderá solicitar sua alteração ou exclusão definitiva quando encerrar o uso do BB GASTRONOMIA.
			Para tanto, deverá entrar em contato através dos meios disponibilizados no BB GASTRONOMIA para
			solicitar essas providências caso não seja oferecida a funcionalidade correspondente na interface.
			<br />
			5.9.1. O pedido de exclusão de dados feito pelo USUÁRIO será totalmente atendido após vencer o
			prazo indicado em 5.8, contudo, ainda que armazenados para fins de auditoria e judiciais, não mais
			passarão pelo tratamento discriminado em 5.5, f., g., h., i., j. e k. <br /><br />

			5.10. A TAGME efetuará registro de todas as atividades efetuadas pelo USUÁRIO no BB GASTRONOMIA,
			também por meio de logs, incluindo: <br />
			a. Endereço IP do USUÁRIO; <br />
			b. Ações efetuadas pelo USUÁRIO no BB GASTRONOMIA; <br />
			c. Mensagens, avisos e informações acessadas pelo USUÁRIO no uso do BB GASTRONOMIA; <br />
			d. Ferramentas e funcionalidades acessadas pelo USUÁRIO; <br />
			e. Data e horário de cada ação no BB GASTRONOMIA; <br />
			f. Informações sobre os recursos utilizados pelo USUÁRIO, localização, sistema operacional e
			outros disponíveis; <br />
			g. Session ID, quando disponível; e, <br />
			h. Outras informações necessárias para a sua adequada autenticação, quando necessário.
			<br /><br />

			5.11. Os registros mencionados no item 5.1 poderão ser utilizados pela TAGME como meio probatório
			em casos de: <br />
			a. Atos ilícitos ou contrários a este documento; <br />
			b. Identificação de alterações indevidas em seus sistemas e cadastros; <br />
			c. Demais ações que possam colocar em risco a segurança e continuidade da plataforma, do BB
			GASTRONOMIA, de seus USUÁRIOS ou dispositivos nos quais o BB GASTRONOMIA está instalado.
			<br /><br />

			5.12. Outras tecnologias poderão ser utilizadas para a obtenção de dados de utilização do BB
			GASTRONOMIA pelo USUÁRIO, respeitando sempre os termos deste documento e as opções do USUÁRIO a
			respeito de sua coleta e armazenamento. <br /><br />

			6. DISPOSIÇÕES GERAIS <br />
			6.1. Os presentes Termos e Condições de Uso estão sujeitos a constante melhoria e aprimoramento.
			Assim, a TAGME se reserva ao direito de modificá-los a qualquer momento, conforme a finalidade do
			BB GASTRONOMIA ou conveniência de sua controladora, tal qual para adequação e conformidade legal
			de disposição de lei ou norma que tenha força jurídica equivalente, cabendo ao USUÁRIO verificá-lo
			sempre que efetuar o acesso ao BB GASTRONOMIA. <br /><br />

			6.2. A tolerância do eventual descumprimento de quaisquer das cláusulas e condições do presente
			instrumento não constituirá novação das obrigações aqui estipuladas e tampouco impedirá ou inibirá
			a exigibilidade das mesmas a qualquer tempo. <br /><br />

			6.3. Caso alguma disposição destes Termos de Uso seja julgada inaplicável ou sem efeito, o
			restante do documento continua a viger, sem a necessidade de medida judicial que declare tal
			assertiva. <br /><br />

			6.4. O BB GASTRONOMIA tem como base a data e horários oficiais de Brasília. <br /><br />

			6.5. Para cumprimento da legislação de Dados vigente, informamos o nome e endereço de e-mail do
			Encarregado de Proteção de Dados da TAGME: João Victor de Faria Cordeiro -
			joao.victor&#64;tagme.com.br <br /><br />

			7. LEI APLICÁVEL E JURISDIÇÃO <br />
			7.1. Os Termos e Condições de Uso aqui descritos são interpretados segundo a legislação
			brasileira, no idioma português, sendo eleito o Foro da Capital do Estado de Rio de Janeiro para
			dirimir qualquer litígio ou controvérsia envolvendo o presente documento, salvo ressalva
			específica de competência pessoal, territorial ou funcional pela legislação aplicável.
			<br /><br />

			8. GLOSSÁRIO <br />
			8.1. Para os fins deste documento, devem se considerar as seguintes definições e descrições para
			seu melhor entendimento: <br />
			▪ Anti-Spam: Sistema que evita correspondências não desejadas, como publicidade em massa, pelo
			bloqueio de mensagens ou as movendo para pasta específica. <br />
			▪ Solução: refere-se ao BB GASTRONOMIA <br />
			▪ TAGME: TAGME – ALVES CUNHA TORRES INFORMATICA LTDA., pessoa jurídica de direito privado com
			sede na cidade do Rio de Janeiro, Estado do Rio de Janeiro, à Rua Dalcídio Jurandir, nº 255, Barra
			da Tijuca, inscrita no CNPJ/MF sob o nº 10.842.304/0001-50. <br />
			▪ IP: Abreviatura de Internet Protocolo. É conjunto alfanumérico que identifica os dispositivos
			dos USUÁRIOS na Internet. <br />
			▪ Layout: Conjunto compreendido entre aparência, design e fluxos do PROGRAMA. <br />
			▪ Link: Terminologia para endereço de Internet. <br />
			▪ USUÁRIOS: Pessoas que acessam ou interagem com o PROGRAMA. <br /><br />
		</p>
	</div>
</div>
