import { HttpClient } from '@angular/common/http'
import { Observable, delay, map, of, switchMap, throwError } from 'rxjs'
import { Injectable, inject } from '@angular/core'
import { environment } from '@monorepo-channels/shared/util-environments'
import { PartnerToken } from '@monorepo-channels/shared/util-helpers'
import { Address, AddressApi } from '../state/address/address'

export const DEFAULT_LIMIT = 10

@Injectable({ providedIn: 'root' })
export class AddressGateway {
	private baseURL = `${environment.channelsAPIURL}/api/v1/address`
	private partnerId = inject(PartnerToken)
	private http = inject(HttpClient)
	getAddresses(): Observable<Address[]> {
		// Uncomment the scenario you want to test
		// return this.errorScenario()

		return this.http
			.get<AddressApi[]>(`${this.baseURL}`, { headers: { partnerId: this.partnerId } })
			.pipe(
				map(addressApi =>
					addressApi.map(address => ({
						state: address._id.state,
						city: address._id.city,
						neighborhoods: address.neighborhoods,
					}))
				)
			)
	}

	private errorScenario(): Observable<string[]> {
		return of(['1']).pipe(
			delay(1000),
			switchMap(() => throwError(() => 'error'))
		)
	}
}
