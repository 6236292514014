import { Component, Input, inject } from '@angular/core'
import { AsyncPipe, NgFor, NgIf } from '@angular/common'
import { Router, ActivatedRoute } from '@angular/router'
import { InfiniteScrollService, getFilterVenues } from '@monorepo-channels/channels/domain'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { Subscription, combineLatest, filter, map } from 'rxjs'
import { getStatusVenues, getVenues, VenueActions } from '@monorepo-channels/channels/domain'
import { Store } from '@ngrx/store'
import {
	AlertErrorUiComponent,
	LoadingPageComponent,
	VenueCardUiComponent,
} from '@monorepo-channels/components/ui-bb'

import { DataLayerService, GeolocationService } from '@monorepo-channels/shared/util-helpers'
import { Title } from '@angular/platform-browser'

@Component({
	selector: 'feature-bb-venue-card-list',
	standalone: true,
	imports: [NgIf, NgFor, AsyncPipe, VenueCardUiComponent, LoadingPageComponent, AlertErrorUiComponent],
	styles: `
	:host {
		@import 'mixin';
		display: grid;
		grid-template-columns: 1fr;
		gap: 24px;
		@include laptop-up {
			grid-template-columns: repeat(3, 1fr);
			margin: 0 auto;
			gap: 32px;
			max-width: var(--container-desktop);
		}
	}
	`,
	template: `
		@if ((statusVenues$ | async) === 'pending') {
			<div style="padding: 58px 0;">
				<ui-bb-loading-page>Carregando</ui-bb-loading-page>
			</div>
		} @else {
			<ng-container *ngIf="vm$ | async as vm">
				<ui-bb-alert-error *ngIf="this.searchName && vm.venues?.length === 0">
					Nenhum restaurante<br />
					encontrado, refaça sua busca.
				</ui-bb-alert-error>
				<!--<p *ngIf="venues.length === 0">{{ searchName }} vazio</p>-->
				@if (vm.filterVenues && vm.filterVenues.length > 0) {
					<ui-bb-venue-card
						*ngFor="let venue of vm.filterVenues"
						[venue]="venue"
						(clickCard)="
							router.navigate(['restaurante', venue.id], { relativeTo: activatedRoute })
						"
					/>
				} @else {
					<ui-bb-venue-card
						*ngFor="let venue of vm.venues"
						[venue]="venue"
						(clickCard)="
							dataLayerService.logEvent({
								event: 'CH_venue_card_click',
								venueId: venue.id,
								venueName: venue.name
							});
							router.navigate(['restaurante', venue.id], { relativeTo: activatedRoute })
						"
					/>
				}
			</ng-container>
		}
	`,
})
export class VenueCardListComponent {
	private store = inject(Store)
	public router = inject(Router)
	public activatedRoute = inject(ActivatedRoute)
	public dataLayerService = inject(DataLayerService)

	@Input() searchName = ''

	venues$ = this.store.select(getVenues)
	filterVenues$ = this.store.select(getFilterVenues)
	vm$ = combineLatest([this.venues$, this.filterVenues$]).pipe(
		map(([venues, filterVenues]) => ({ venues, filterVenues }))
	)
	statusVenues$ = this.store.select(getStatusVenues)
	sub = new Subscription()

	constructor(
		private title: Title, //???
		private geoLocation: GeolocationService
	) {
		inject(InfiniteScrollService)
			.loadMoreVenue$.pipe(
				filter(loadMore => loadMore),
				takeUntilDestroyed()
			)
			.subscribe(() => this.store.dispatch(VenueActions.loadMoreVenue()))

		// Get currentLocation
		this.geoLocation.getCurrentLocation()

		this.geoLocation.location$.pipe(takeUntilDestroyed()).subscribe({
			next: ({ state, position }) => {
				if (state === 'denied' || state === 'prompt') {
					this.store.dispatch(VenueActions.getVenues())
					return
				}
				if (state === 'granted' && position) {
					this.store.dispatch(
						VenueActions.setLocation({
							latitude: position.latitude,
							longitude: position.longitude,
							maxDistance: 42_500_000,
						})
					)
				}
			},
		})
	}
}
