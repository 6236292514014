import { Directive, OnInit, inject } from '@angular/core'
import { Store } from '@ngrx/store'
import { ClientActions } from '../state/client/client.actions'
import { ActivatedRoute } from '@angular/router'

@Directive({ selector: '[domainLoginHomolog]', standalone: true })
export class LoginHomologationDirective implements OnInit {
	private store = inject(Store)
	private activatedRoute = inject(ActivatedRoute)

	ngOnInit(): void {
		const clientId = this.activatedRoute.snapshot.queryParamMap.get('loginHomolog')
		if (clientId) {
			this.store.dispatch(ClientActions.loginHomologation({ clientId }))
		}
	}
}
