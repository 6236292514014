<bb-header></bb-header>

<feature-bb-reservation />

<ui-bb-modal-bottom-failure title="Reserva não realizada" #modalReservationFailure>
	<ng-container *ngIf="errorConfirmReservation$ | async as error">
		@if (error.includes('permitido fazer')) {
		<p>Não é possível realizar mais de uma reserva por dia no mesmo restaurante.</p>
		} @else {
		<p>Não conseguimos realizar sua reserva para o dia/horário que você deseja</p>
		}
	</ng-container>
</ui-bb-modal-bottom-failure>

<ui-bb-modal-bottom-failure
	title="Reserva não disopnível"
	#modalAvailabilitiesFailure
	buttonTxt="VOLTAR"
	(buttonClick)="location.back()"
>
	<ng-container *ngIf="errorAvailability$ | async as error"> {{error}} </ng-container>
</ui-bb-modal-bottom-failure>
