import { Component } from '@angular/core'

@Component({
	standalone: true,
	selector: 'ui-common-bb-user-svg',
	template: `
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="white" />
			<path
				d="M12.3334 14.3333V13.2963C12.3334 12.7462 12.1051 12.2187 11.6988 11.8297C11.2925 11.4407 10.7414 11.2222 10.1667 11.2222H5.83341C5.25878 11.2222 4.70768 11.4407 4.30135 11.8297C3.89502 12.2187 3.66675 12.7462 3.66675 13.2963V14.3333M10.1667 7.07407C10.1667 8.21955 9.1967 9.14815 8.00008 9.14815C6.80346 9.14815 5.83341 8.21955 5.83341 7.07407C5.83341 5.92859 6.80346 5 8.00008 5C9.1967 5 10.1667 5.92859 10.1667 7.07407Z"
				stroke="white"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	`,
})
export class UserSVGComponent {}
