import { Component, OnInit, ViewChild, inject } from '@angular/core'
import { CommonModule } from '@angular/common'

import { Store } from '@ngrx/store'
import { getOrders, OrderHistory, OrderHistoryActions } from '@monorepo-channels/channels/domain'
import { DivisorUiComponent } from '@monorepo-channels/components/ui-common'
import { Router, RouterLink } from '@angular/router'
import { OrderHistoryService } from './order-history-body/order-history.service'
import {
	TitleUiComponent,
	ButtonUiComponent,
	AlertTopUiComponent,
	LoadingPageComponent,
	ModalBottomUiComponent,
	FooterUiComponent,
} from '@monorepo-channels/components/ui-bb'
import { OrdersHistoryBodyComponent } from './order-history-body/orders-history-body.component'
import { HeaderComponent } from '../../header/header.component'

@Component({
	standalone: true,
	imports: [
		CommonModule,
		HeaderComponent,
		TitleUiComponent,
		ButtonUiComponent,
		AlertTopUiComponent,
		LoadingPageComponent,
		DivisorUiComponent,
		RouterLink,
		FooterUiComponent,
		ModalBottomUiComponent,
		OrdersHistoryBodyComponent,
	],
	templateUrl: './orders-history.page.html',
	styleUrls: ['./orders-history.page.scss'],
})
export class OrdersHistoryPageComponent implements OnInit {
	private router = inject(Router)
	private store = inject(Store)
	private orderHistoryService = inject(OrderHistoryService)
	orderAction$ = this.orderHistoryService.orderAction$
	orders$ = this.store.select(getOrders)

	@ViewChild('modalRegisterCard')
	modalRegisterCard!: ModalBottomUiComponent

	@ViewChild('cancelOrderModal')
	cancelOrderModal!: ModalBottomUiComponent

	ngOnInit(): void {
		this.store.dispatch(OrderHistoryActions.loadOrders())
	}

	registerCard() {
		this.router.navigate(['/carteira/cadastrar-cartao'])
	}

	openModalCancel() {
		this.cancelOrderModal.openModal()
	}

	openModalRegisterCard() {
		this.modalRegisterCard.openModal()
	}

	cancelOrder(order: OrderHistory | null) {
		if (!order) return
		this.store.dispatch(OrderHistoryActions.cancelOrder({ orderId: order.id }))
		this.cancelOrderModal.closeModal()
	}
}
