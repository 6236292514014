import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { Cuisine } from '../../gateways/venues.gateway'

export const CuisineActions = createActionGroup({
	source: 'Cuisine',
	events: {
		'Get Cuisines': emptyProps(),
		'Load Cuisines': emptyProps(),
		'Load Cuisines Success': props<{ cuisines: Cuisine[] }>(),
		'Load Cuisines Failure': props<{ error: string }>(),
		'Set Current Cuisine': props<{ cuisine: Cuisine }>(),
		'Clean Cuisines': emptyProps(),
	},
})
