import { provideEnvironmentNgxCurrency, NgxCurrencyInputMode } from 'ngx-currency'

export const ProviderCurrency = () =>
	provideEnvironmentNgxCurrency({
		align: 'left',
		allowNegative: true,
		allowZero: true,
		decimal: ',',
		precision: 2,
		prefix: 'R$ ',
		suffix: '',
		thousands: '.',
		nullable: true,
		min: undefined,
		max: undefined,
		inputMode: NgxCurrencyInputMode.Financial,
	})
