import { Component, inject } from '@angular/core'
import { CommonModule, Location } from '@angular/common'

import { ButtonUiComponent, FooterUiComponent } from '@monorepo-channels/components/ui-bb'
import { TermsOfUseBodyComponent } from './terms-of-use-body/terms-of-use-body.component'
import { HeaderComponent } from '../../../header/header.component'

@Component({
	standalone: true,
	selector: 'bb-terms-of-use',
	imports: [CommonModule, HeaderComponent, FooterUiComponent, ButtonUiComponent, TermsOfUseBodyComponent],
	template: `
		<bb-header></bb-header>
		<bb-terms-of-use-body />
		<div style="height: 32px"></div>
		<ui-bb-footer position="fixed">
			<button class="btnTerms" (click)="location.back()">VOLTAR</button>
		</ui-bb-footer>
	`,
})
export class TermsOfUseComponent {
	public location = inject(Location)
}
