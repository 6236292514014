import { of } from 'rxjs'
import { Store } from '@ngrx/store'
import { Injectable, inject } from '@angular/core'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { AddressActions } from './address.actions'
import { mergeMap, map, catchError, filter } from 'rxjs/operators'
import { AddressGateway } from '../../gateways/address.gateway'
import { AppState } from '../helper.state'
import { getAddresses } from './address.selectors'

@Injectable()
export class AddressEffects {
	private actions$ = inject(Actions)
	private addressGateway = inject(AddressGateway)
	private store = inject(Store<AppState>)

	getAddresses$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AddressActions.getAddresses),
			concatLatestFrom(() => this.store.select(getAddresses)),
			// Only load addresses if the array has at least one address
			filter(([, addresses]) => !addresses.length),
			map(() => AddressActions.loadAddresses())
		)
	})

	loadAddresses$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AddressActions.loadAddresses),
			mergeMap(() =>
				this.addressGateway.getAddresses().pipe(
					map(addresses => AddressActions.loadAddressesSuccess({ addresses })),
					catchError(error => of(AddressActions.loadAddressesFailure({ error })))
				)
			)
		)
	})
}
