export class CheckinDate {
	readonly day: string
	readonly time: string
	readonly today = new Date()
	constructor() {
		const tzoffset = this.today.getTimezoneOffset() * 60000 //offset in milliseconds
		const [day, time] = new Date(Date.now() - tzoffset).toISOString().slice(0, -8).split('T')
		this.day = day
		this.time = time
	}
}
