import { Component, EventEmitter, Input, Output } from '@angular/core'
import { CommonModule } from '@angular/common'

import { BoxIconUiComponent } from '../box-icon/box-icon.component'

@Component({
	selector: 'ui-bb-counter',
	standalone: true,
	imports: [CommonModule, BoxIconUiComponent],
	templateUrl: './counter.component.html',
	styleUrls: ['./counter.component.scss'],
})
export class CounterUiComponent {
	@Input({ required: true }) digit!: number
	@Input({ required: true }) maxDigit!: number
	@Input({ required: true }) minDigit!: number
	@Output() counter = new EventEmitter<number>()

	increase() {
		if (this.digit < this.maxDigit) {
			this.digit = this.digit + 1
		}
		this.counter.emit(this.digit)
	}

	decrease() {
		if (this.digit > this.minDigit) {
			this.digit = this.digit - 1
		}
		this.counter.emit(this.digit)
	}
}
