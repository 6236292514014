<div class="page px-16">
	<h1 class="bbTitle">FAQ</h1>
	<div class="info" (click)="showTxt(0)">
		<div class="info__header">
			<p class="title">01. O que é o BB Gastronomia?</p>
			<i class="fa fa fa-chevron-up icon" [ngClass]="{ rotated: shows[0] }"></i>
		</div>
		<p class="txt" style="margin-top: 16px" *ngIf="shows[0]">
			É um serviço do Banco do Brasil - em parceria com a Livelo em que você pode fazer reservas ou
			check-ins em restaurantes e acumular pontos Livelo depois do pagamento da conta, utilizando um
			cartão Ourocard. Tudo simples e rápido pelo aplicativo do Banco do Brasil
		</p>
	</div>
	<div class="info" (click)="showTxt(1)">
		<div class="info__header">
			<p class="title">02. Como funciona este serviço?</p>
			<i class="fa fa fa-chevron-up icon" [ngClass]="{ rotated: shows[1] }"></i>
		</div>
		<p class="txt" style="margin-top: 16px" *ngIf="shows[1]">
			Basta fazer o login no app do Banco do Brasil, acessar menu Meus Benefícios, opção BB Gastronomia,
			cadastrar um cartão Ourocard com a função crédito ativa, escolher um dos estabelecimentos
			parceiros e fazer a reserva de mesa ou check-in por lá. Quando chegar ao restaurante, apresente
			sua reserva ou check-in, pague a conta pelo app utilizando o cartão cadastrado no momento da
			reserva ou check-in, e para receber pontos a cada real gasto na conta. Por exemplo, se a conta do
			restaurante deu um total de R$100 e o estabelecimento oferecia 5 pontos a cada real, você receberá
			500 pontos Livelo. O valor da gorjeta e taxa de serviço não estão inclusos para o recebimento dos
			pontos. A reserva e o check-in também, por si só, não garante o recebimento dos pontos. Além
			disso, é preciso consumir no restaurante e pagar a conta pelo app utilizando um cartão de crédito
			Ourocard cadastrado.
		</p>
	</div>

	<div class="info" (click)="showTxt(2)">
		<div class="info__header">
			<p class="title">
				03. É obrigatório fazer a reserva ou check-in e pagar pelo app do Banco do Brasil?
			</p>
			<i class="fa fa fa-chevron-up icon" [ngClass]="{ rotated: shows[2] }"></i>
		</div>
		<p class="txt" style="margin-top: 16px" *ngIf="shows[2]">
			Sim. Tanto o procedimento de reserva quanto de pagamento devem ser feitos pelo aplicativo do Banco
			do Brasil, utilizando um cartão de crédito Ourocard.
		</p>
	</div>
	<div class="info" (click)="showTxt(3)">
		<div class="info__header">
			<p class="title">04. Como faço uma reserva em um restaurante?</p>
			<i class="fa fa fa-chevron-up icon" [ngClass]="{ rotated: shows[3] }"></i>
		</div>
		<p class="txt" style="margin-top: 16px" *ngIf="shows[3]">
			Pelo app do Banco do Brasil. Depois de logar em sua conta pelo app BB, acesse menu Meus
			Benefícios, opção BB Gastronomia, encontre o estabelecimento de seu interesse e clique em
			“Reservar”. Você escolhe o dia, o número de pessoas, o ambiente e o horário. Depois, clique em
			“Confirmar Reserva”. A reserva é realizada e você recebe um e-mail de confirmação. Para fazer uma
			reserva é necessário cadastrar um cartão Ourocard na função crédito.
		</p>
	</div>

	<div class="info" (click)="showTxt(4)">
		<div class="info__header">
			<p class="title">
				05. Quando faço a reserva, preciso pagar a conta com cartão do Banco do Brasil?
			</p>
			<i class="fa fa fa-chevron-up icon" [ngClass]="{ rotated: shows[4] }"></i>
		</div>
		<p class="txt" style="margin-top: 16px" *ngIf="shows[4]">
			Sim. Para esse serviço, você deve pagar com o seu cartão Ourocard na função crédito. Na primeira
			vez em que usar, vai ter uma etapa de cadastro do cartão. Esta etapa não tem custo, é apenas para
			validação. Depois, quando for pagar a conta do restaurante reservado, você paga pelo app do Banco
			do Brasil dentro da opção Meus benefícios, BB Gastronomia e o pagamento vai ser debitado do cartão
			que cadastrou.
		</p>
	</div>

	<div class="info" (click)="showTxt(5)">
		<div class="info__header">
			<p class="title">06. Preciso ligar para o restaurante para confirmar minha reserva?</p>
			<i class="fa fa fa-chevron-up icon" [ngClass]="{ rotated: shows[5] }"></i>
		</div>
		<p class="txt" style="margin-top: 16px" *ngIf="shows[5]">
			Não. As reservas feitas pelo app são agendadas automaticamente no e-mail cadastrado, em sua conta
			Livelo. Próximo à data da reserva, você recebe um novo e-mail para confirmação ou cancelamento da
			reserva. Em caso de não recebimento do e-mail de confirmação, basta comparecer ao restaurante no
			horário agendado na reserva.
		</p>
	</div>

	<div class="info" (click)="showTxt(6)">
		<div class="info__header">
			<p class="title">07. Como cancelo minhas reservas?</p>
			<i class="fa fa fa-chevron-up icon" [ngClass]="{ rotated: shows[6] }"></i>
		</div>
		<p class="txt" style="margin-top: 16px" *ngIf="shows[6]">
			Caso não possa estar presente no restaurante reservado, basta acessar o app do Banco do Brasil,
			Menu Meus Benefícios, opção BB Gastronomia, em “Minhas Reservas” e clicar no botão de
			cancelamento. É um procedimento sem custo.
		</p>
	</div>

	<div class="info" (click)="showTxt(7)">
		<div class="info__header">
			<p class="title">08. Com altero minhas reservas?</p>
			<i class="fa fa fa-chevron-up icon" [ngClass]="{ rotated: shows[7] }"></i>
		</div>
		<p class="txt" style="margin-top: 16px" *ngIf="shows[7]">
			Para alterar a reserva é necessário cancelar a original e realizar uma nova pela plataforma BB
			Gastronomia.
		</p>
	</div>

	<div class="info" (click)="showTxt(8)">
		<div class="info__header">
			<p class="title">09. O que acontece se eu não comparecer a uma reserva realizada?</p>
			<i class="fa fa fa-chevron-up icon" [ngClass]="{ rotated: shows[8] }"></i>
		</div>
		<p class="txt" style="margin-top: 16px" *ngIf="shows[8]">
			Caso não possa comparecer no restaurante reservado, e nem tenha cancelado a reserva com
			antecedência, na aba “Reservas e Check-in” constará o status “Reserva cancelada”.
		</p>
	</div>

	<div class="info" (click)="showTxt(9)">
		<div class="info__header">
			<p class="title">10. Posso ter mais de uma reserva?</p>
			<i class="fa fa fa-chevron-up icon" [ngClass]="{ rotated: shows[9] }"></i>
		</div>
		<p class="txt" style="margin-top: 16px" *ngIf="shows[9]">
			Se for para o mesmo dia e para o mesmo restaurante, apenas uma reserva. Se for para locais
			diferentes, em datas diferentes, você pode fazer quantas reservas quiser.
		</p>
	</div>

	<div class="info" (click)="showTxt(10)">
		<div class="info__header">
			<p class="title">
				11. O valor da taxa de serviço, pago pelo app, também é contabilizado para o acúmulo de
				pontos?
			</p>
			<i class="fa fa fa-chevron-up icon" [ngClass]="{ rotated: shows[10] }"></i>
		</div>
		<p class="txt" style="margin-top: 16px" *ngIf="shows[10]">
			Não. Os pontos são contabilizados levando em consideração o valor de consumo, sem considerar taxa
			de serviço
		</p>
	</div>

	<div class="info" (click)="showTxt(11)">
		<div class="info__header">
			<p class="title">12. Como eu realizo o pagamento da conta?</p>
			<i class="fa fa fa-chevron-up icon" [ngClass]="{ rotated: shows[11] }"></i>
		</div>
		<p class="txt" style="margin-top: 16px" *ngIf="shows[11]">
			A opção de pagamento da conta fica disponível no app, menu Meus Benefícios, opção BB Gastronomia,
			na área “Minhas Reservas”. Você digita o valor da conta a ser paga e confere a quantidade de
			pontos Livelo que irá receber. Seleciona o seu cartão de crédito Ourocard cadastrado e confirma o
			código de segurança. Você recebe o comprovante e o restaurante visualiza o pagamento. Se a conta
			for paga fora do app do Banco do Brasil, os pontos não serão creditados.
		</p>
	</div>

	<div class="info" (click)="showTxt(12)">
		<div class="info__header">
			<p class="title">13. E se eu não quiser pagar pela taxa de serviço?</p>
			<i class="fa fa fa-chevron-up icon" [ngClass]="{ rotated: shows[12] }"></i>
		</div>
		<p class="txt" style="margin-top: 16px" *ngIf="shows[12]">
			O pagamento da taxa de serviço no restaurante participante não é obrigatório. Caso não queira
			pagá-la, basta informar ao garçom antes de efetuar o pagamento de sua conta de consumo no
			aplicativo, para que ele efetue o procedimento necessário. Então, é só atualizar o seu aplicativo,
			e confirmar se o valor da taxa foi desconsiderado.
		</p>
	</div>

	<div class="info" (click)="showTxt(13)">
		<div class="info__header">
			<p class="title">14. Depois que paguei a conta, em quanto tempo vou receber os pontos?</p>
			<i class="fa fa fa-chevron-up icon" [ngClass]="{ rotated: shows[13] }"></i>
		</div>
		<p class="txt" style="margin-top: 16px" *ngIf="shows[13]">
			Os pontos referentes ao pagamento da conta, serão creditados em até 30 dias úteis. Se você pagar
			com cartão Ourocard do tipo que acumula pontos, também vai receber os pontos referentes à
			transação, que são creditados em até 7 dias corridos do pagamento da fatura.
		</p>
	</div>

	<div class="info" (click)="showTxt(14)">
		<div class="info__header">
			<p class="title">
				15. Qual é a validade dos pontos que vou receber por ter usado o serviço de reserva?
			</p>
			<i class="fa fa fa-chevron-up icon" [ngClass]="{ rotated: shows[14] }"></i>
		</div>
		<p class="txt" style="margin-top: 16px" *ngIf="shows[14]">
			24 meses contatos a partir da data de crédito dos pontos.
		</p>
	</div>
</div>
