import { Component } from '@angular/core'

import { FaqBodyComponent } from './faq-body/faq-body.component'
import { HeaderComponent } from '../../../header/header.component'

@Component({
	standalone: true,
	selector: 'bb-faq',
	imports: [HeaderComponent, FaqBodyComponent],
	template: `
		<bb-header></bb-header>
		<bb-faq-body />
	`,
})
export class FaqComponent {}
