import { CommonModule, Location } from '@angular/common'
import { Component, inject } from '@angular/core'
import { Store } from '@ngrx/store'
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms'
import { ClientActions, getClient, getUpdateClientBackendStatus } from '@monorepo-channels/channels/domain'
import {
	ButtonUiComponent,
	FooterUiComponent,
	LoadingPageComponent,
} from '@monorepo-channels/components/ui-bb'
import { DivisorUiComponent } from '@monorepo-channels/components/ui-common'
import { NgxMaskDirective } from 'ngx-mask'
import { Actions, ofType } from '@ngrx/effects'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { combineLatest, map } from 'rxjs'
import { isMobile } from '@monorepo-channels/shared/util-helpers'

@Component({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		DivisorUiComponent,
		NgxMaskDirective,
		FooterUiComponent,
		ButtonUiComponent,
		LoadingPageComponent,
	],
	standalone: true,
	selector: 'bb-complete-registration-body',
	template: `
		@if (vm$ | async; as vm) {
			@if (vm.status === 'idle') {
				<main class="page px-16" *ngIf="client$ | async as client">
					<h1>Complete seu cadastro</h1>
					<article>
						<form [formGroup]="form">
							<label class="boxi7kr3">
								<div class="boxi7kr3__title">Nome</div>
								<div class="boxi7kr3__input disabled">{{ client.fullName }}</div>
							</label>
							<ui-common-divisor bottom="16px" />
							<label class="boxi7kr3">
								<div class="boxi7kr3__title">E-mail</div>
								<div class="boxi7kr3__input disabled">{{ client.email }}</div>
							</label>
							<ui-common-divisor bottom="16px" />
							<label class="boxi7kr3">
								<div class="boxi7kr3__title">Celular</div>
								<input
									class="boxi7kr3__input"
									type="text"
									mask="(00) 00000-0000"
									formControlName="phone"
									inputmode="numeric"
									placeholder="(00) 00000-0000"
								/>
							</label>
							<ui-common-divisor />
						</form>
					</article>
				</main>
				<ui-bb-footer [position]="isMobile ? 'fixed' : 'static'">
					<div class="page px-16">
						<ui-bb-button
							type="secondary"
							[disabled]="form.invalid"
							(clickButton)="updatePhone()"
							footer
						>
							CADASTRAR
						</ui-bb-button>
					</div>
				</ui-bb-footer>
			} @else if (vm.status === 'pending') {
				<div style="height: 50vh; display: flex; justify-content: center; align-items: center">
					<ui-bb-loading-page />
				</div>
			}
		}
	`,
	styleUrl: 'complete-registration-body.component.scss',
})
export class CompleteRegistrationBodyComponent {
	private store = inject(Store)
	private fb = inject(FormBuilder)
	private location = inject(Location)
	form = this.fb.group({ phone: ['', [Validators.required]] })
	client$ = this.store.select(getClient)
	statusUpdateClientBackend$ = this.store.select(getUpdateClientBackendStatus)
	vm$ = combineLatest([this.client$, this.statusUpdateClientBackend$]).pipe(
		map(([client, statusUpdateClientBackend]) => ({ client, status: statusUpdateClientBackend }))
	)
	isMobile = isMobile()

	constructor() {
		inject(Actions)
			.pipe(ofType(ClientActions.updateClientBackendSuccess), takeUntilDestroyed())
			.subscribe(() => {
				this.isMobile ? this.location.back() : window.location.reload()
			})
	}

	updatePhone() {
		if (this.form.invalid) return
		const phone = this.form.controls.phone.value
		const formattedPhone = `55${phone}`
		this.store.dispatch(ClientActions.updateClientBackend({ client: { phone: formattedPhone } }))
	}
}
