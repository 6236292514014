import { Component } from '@angular/core'

@Component({
	standalone: true,
	selector: 'ui-common-bb-background-card',
	template: `
		<svg width="343" height="78" viewBox="10 8 343 78" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask
				id="mask0_843_7390"
				style="mask-type:alpha"
				maskUnits="userSpaceOnUse"
				x="10"
				y="8"
				width="343"
				height="70"
			>
				<rect x="10" y="8" width="343" height="70" rx="8" fill="url(#paint0_linear_843_7390)" />
			</mask>
			<g mask="url(#mask0_843_7390)">
				<g filter="url(#filter0_d_843_7390)">
					<rect x="10" y="8" width="343" height="70" rx="8" fill="url(#paint1_linear_843_7390)" />
				</g>
				<ellipse cx="112" cy="77" rx="133" ry="101" fill="url(#paint2_linear_843_7390)" />
			</g>
			<defs>
				<filter
					id="filter0_d_843_7390"
					x="0"
					y="0"
					width="363"
					height="90"
					filterUnits="userSpaceOnUse"
					color-interpolation-filters="sRGB"
				>
					<feFlood flood-opacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="2" />
					<feGaussianBlur stdDeviation="5" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_843_7390" />
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow_843_7390"
						result="shape"
					/>
				</filter>
				<linearGradient
					id="paint0_linear_843_7390"
					x1="181.5"
					y1="8"
					x2="181.5"
					y2="78"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#505050" />
					<stop offset="1" stop-color="#B6B6B6" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_843_7390"
					x1="181.5"
					y1="8"
					x2="181.5"
					y2="78"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#313874" />
					<stop offset="1" stop-color="#B6B6B6" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_843_7390"
					x1="112"
					y1="-24"
					x2="112"
					y2="178"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#373E76" />
					<stop offset="1" stop-color="#272F72" />
				</linearGradient>
			</defs>
		</svg>
	`,
	styles: `svg {
        position: relative;
    }`,
})
export class BackgroundCardSVGComponent {}
