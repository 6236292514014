import { Pipe, PipeTransform } from '@angular/core'
// Exemplo: 'Terça-feira, 07 de Novembro'

@Pipe({ name: 'formatDateWeekday', standalone: true })
export class FormatDateWeekday implements PipeTransform {
	transform(utcDate: Date) {
		// Create an Intl.DateTimeFormat object for the desired time zone ("America/Sao_Paulo")
		const dateFormat = new Intl.DateTimeFormat('pt-BR', {
			timeZone: 'America/Sao_Paulo',
			weekday: 'long',
			year: 'numeric',
			month: 'long',
			day: '2-digit',
		})

		// Format the date
		const [{ value: dayOfWeek }, , { value: day }, , { value: month }] = dateFormat.formatToParts(utcDate)

		// Convert the first character of the month to uppercase
		const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1)
		const capitalizedDayOfWeek = dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1)

		// Format the desired phrase
		return `${capitalizedDayOfWeek}, ${day} de ${capitalizedMonth}`
	}
}
