import { ApplicationConfig, LOCALE_ID } from '@angular/core'
import { provideRouter, withEnabledBlockingInitialNavigation } from '@angular/router'
import { appRoutes } from './app.routes'
import { registerLocaleData } from '@angular/common'
import { provideHttpClient, withInterceptors } from '@angular/common/http'
import {
	RedirectToken,
	LoginPageRegex,
	clientPartnerInterceptor,
	tokenInterceptor,
	ProvidersRedux,
} from '@monorepo-channels/channels/domain'
import {
	PartnerToken,
	PartnersId,
	ProviderCurrency,
	provideIcons,
} from '@monorepo-channels/shared/util-helpers'
import { provideEnvironmentNgxMask } from 'ngx-mask'
import ptBr from '@angular/common/locales/pt'
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async'
registerLocaleData(ptBr)

export const appConfig: ApplicationConfig = {
	providers: [
		{ provide: PartnerToken, useValue: PartnersId.BB },
		{ provide: RedirectToken, useValue: '/' },
		{ provide: LoginPageRegex, useValue: /\/login\?login=([^&]+)/ },
		{ provide: LOCALE_ID, useValue: 'pt-BR' },
		provideRouter(appRoutes, withEnabledBlockingInitialNavigation()),
		provideHttpClient(withInterceptors([clientPartnerInterceptor, tokenInterceptor])),
		ProvidersRedux(),
		ProviderCurrency(),
		provideEnvironmentNgxMask(),
		provideAnimationsAsync(),
		provideIcons(),
	],
}
