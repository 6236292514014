<div class="benefits" *ngFor="let benefit of benefits">
	@if (benefit.type === 'points') {
		<i class="fa fa-livelo benefits__icon"></i>
		@if (campaign$ | async; as campaign) {
			<p>
				Ganhe
				<span style="text-decoration: line-through; color: unset; font-weight: 400">
					{{ benefit.value }} {{ benefit.value === '1' ? 'ponto' : 'pontos' }}
				</span>
				<span class="benefits__text"> {{ campaign.value }} pontos Livelo </span>
				a cada 1 real gasto, pagando com cartão Ourocard
			</p>
		} @else {
			<p>
				Ganhe<span class="benefits__text">
					{{ benefit.value }} {{ benefit.value === '1' ? 'ponto' : 'pontos' }} Livelo
				</span>
				a cada 1 real gasto, pagando com cartão Ourocard
			</p>
		}
	}
</div>
