import { environment } from '@monorepo-channels/shared/util-environments'
import { provideEffects } from '@ngrx/effects'
import { provideStore } from '@ngrx/store'
import { provideStoreDevtools } from '@ngrx/store-devtools'
import {
	featureAddressName,
	addressReducer,
	featureVenueName,
	venueReducer,
	featureClientName,
	clientReducer,
	featureReservationName,
	reservationReducer,
	AddressEffects,
	VenueEffects,
	ClientEffects,
	ReservationEffects,
	featureCheckinName,
	checkinReducer,
	CheckinEffects,
	featureOrderHistoryName,
	orderHistoryReducer,
	OrderHistoryEffects,
	featureCuisineName,
	cuisinesReducer,
	CuisinesEffects,
	PaymentEffects,
	paymentReducer,
	featurePaymentName,
} from '../state'

export const ProvidersRedux = () => [
	provideStore({
		[featureAddressName]: addressReducer,
		[featureVenueName]: venueReducer,
		[featureClientName]: clientReducer,
		[featureReservationName]: reservationReducer,
		[featureCheckinName]: checkinReducer,
		[featureOrderHistoryName]: orderHistoryReducer,
		[featureCuisineName]: cuisinesReducer,
		[featurePaymentName]: paymentReducer,
	}),
	provideEffects([
		AddressEffects,
		VenueEffects,
		ClientEffects,
		ReservationEffects,
		CheckinEffects,
		OrderHistoryEffects,
		CuisinesEffects,
		PaymentEffects,
	]),
	provideStoreDevtools({
		name: 'App Dev tools',
		maxAge: 25,
		logOnly: environment.production,
	}),
]
