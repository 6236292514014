import { Component, EventEmitter, Output, Input, booleanAttribute } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'

@Component({
	selector: 'ui-bb-confirm-slider',
	standalone: true,
	imports: [CommonModule, FormsModule],
	templateUrl: './confirm-slider.component.html',
	styleUrls: ['./confirm-slider.component.scss'],
})
export class ConfirmSliderUiComponent {
	@Input({ transform: booleanAttribute }) line = false
	@Input() isChecked = false
	@Output() sliderValueChange = new EventEmitter<boolean>()

	sliderChanged(event: Event) {
		const target = event.target as HTMLInputElement
		this.sliderValueChange.emit(target.checked)
	}
}
