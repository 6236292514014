import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ModalBottomUiComponent } from '../modal-bottom/modal-bottom.component'
import { FooterUiComponent } from '../../footer/footer.component'
import { ButtonUiComponent } from '../../button/button.component'
import { RouterLink } from '@angular/router'
import { CloseFillSVGComponent } from '@monorepo-channels/components/ui-common'

@Component({
	selector: 'ui-bb-modal-bottom-failure',
	standalone: true,
	imports: [
		CommonModule,
		ModalBottomUiComponent,
		FooterUiComponent,
		ButtonUiComponent,
		RouterLink,
		CloseFillSVGComponent,
	],
	templateUrl: './modal-bottom-failure.component.html',
	styleUrl: './modal-bottom-failure.component.scss',
})
export class ModalBottomFailureComponent {
	@Input({ required: true }) title!: string
	@Input() height?: string
	@Input() buttonTxt?: string
	@Output() buttonClick = new EventEmitter<void>()

	@ViewChild(ModalBottomUiComponent)
	modalBottom!: ModalBottomUiComponent

	clickButton() {
		this.buttonClick.emit()
		this.modalBottom.closeModal()
	}

	openModal() {
		this.modalBottom.openModal()
	}

	closeModal() {
		this.modalBottom.closeModal()
	}
}
