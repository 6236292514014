<div class="filter">
	<div class="filter__selector">
		<!-- Template selectedAdress não definido -->
		<ng-template #noAddress>
			<ui-bb-button type="tertiary" rounded style="width: 166px" (clickButton)="openModalAddress()">
				Cidade <i class="fa fa-chevron-up iconArrow"></i>
			</ui-bb-button>
		</ng-template>
		<!-- btn selectedAdress definido -->
		<ui-bb-button
			type="quaternary"
			rounded
			style="width: 166px"
			*ngIf="selectedAddress$ | async as selectedAddress; else noAddress"
			(clickButton)="openModalAddress()"
		>
			<span class="limited">{{ selectedAddress.city }}</span
			><i class="fa fa-chevron-up iconArrow"></i>
		</ui-bb-button>

		<!-- Template cuisines não definido -->
		<ng-template #noCuisines>
			<ui-bb-button type="tertiary" rounded style="width: 122px" (clickButton)="openModalCuisine()">
				Filtrar <i class="fa fa-chevron-up iconArrow"></i>
			</ui-bb-button>
		</ng-template>

		<!-- btn uisines definido -->
		<ui-bb-button
			type="quaternary"
			rounded
			style="width: 122px"
			*ngIf="selectedCuisine?.selected; else noCuisines"
			(clickButton)="openModalCuisine()"
		>
			Filtros
			<div class="filterNumber">1</div>
		</ui-bb-button>

		<div class="filter__boxInput">
			<i class="fa fa-search filter__boxInput__icon" (click)="openInput()"></i>
			<input
				[placeholder]="statusOpen ? 'Pesquisar restaurante' : ''"
				class="filter__input"
				[(ngModel)]="searchName"
				[ngClass]="{ openInput: statusOpen }"
				(input)="onInputSearchChange()"
				name="search"
				(click)="openInput()"
				#inputSearch
			/>
			<i class="fa fa-times filter__boxInput__iconEnd" *ngIf="statusOpen" (click)="closeInput()"></i>
		</div>
	</div>
	<div class="closeFilter">
		<div
			class="closeFilter__item"
			*ngIf="selectedNeighborhood$ | async as selectedNeighborhood"
			(click)="cleanFilterAdress()"
		>
			<p class="closeFilter__item__text">{{ selectedNeighborhood }}<i class="fa fa-close-round"></i></p>
		</div>
		<div
			class="closeFilter__item"
			*ngIf="
				(selectedNeighborhood$ | async) === undefined && (selectedAddress$ | async) as selectedAddress
			"
			(click)="cleanFilterAdress()"
		>
			<p class="closeFilter__item__text">Todos<i class="fa fa-close-round"></i></p>
		</div>
		<div class="closeFilter__item" *ngIf="selectedCuisine?.selected" (click)="cleanFilterCuisines()">
			<p class="closeFilter__item__text">
				{{ selectedCuisine?.name }}<i class="fa fa-close-round"></i>
			</p>
		</div>
	</div>
</div>

<ng-template #cuisineTemplate>
	<div class="modal__box">
		<div class="modal__box__options">
			<ui-bb-button
				*ngFor="let cuisine of cuisines"
				type="tertiary"
				[type]="cuisine.selected ? 'quaternary' : 'tertiary'"
				(clickButton)="setCuisine(cuisine._id)"
				style="margin-top: 16px"
			>
				<p class="textCuisine">{{ cuisine.name }}</p>
			</ui-bb-button>
		</div>
	</div>

	<ui-bb-footer position="static" footer>
		<ui-bb-button
			type="secondary"
			style="width: 160px"
			[disabled]="!selectedCuisine"
			(clickButton)="cleanFilterCuisines()"
		>
			LIMPAR FILTRO
		</ui-bb-button>
		<ui-bb-button
			type="primary"
			style="width: 160px"
			[disabled]="!selectedCuisine"
			(clickButton)="applyFilter()"
		>
			FILTRAR
		</ui-bb-button>
	</ui-bb-footer>
</ng-template>

<ui-bb-modal-bottom title="Tipo de culinária" overflow="auto" *ngIf="isMobile" #modalBottomCuisine>
	<ng-container *ngTemplateOutlet="cuisineTemplate"></ng-container>
</ui-bb-modal-bottom>
<ui-bb-modal-center
	title="Tipo de Culinária"
	*ngIf="!isMobile"
	#modalCenterCuisine
	(clickOutside)="modalCenterCuisine.closeModal()"
>
	<div style="padding-inline: 16px; height: 50dvh; overflow-y: auto; overflow-x: hidden">
		<ng-container *ngTemplateOutlet="cuisineTemplate"></ng-container>
	</div>
</ui-bb-modal-center>
<!-- *ngIf="statusAddress$ | async as statusAddress" -->
@if (statusAddress$ | async; as statusAddress) {
	<ng-template #addressTemplate>
		<ng-container *ngIf="statusAddress === 'pending'"> <ui-bb-loading-page /> </ng-container>

		<ng-container *ngIf="statusAddress === 'success'">
			<div style="margin-top: 24px">
				<ng-container
					*ngFor="let address of filteredAddresses$ | async; let i = index; let last = last"
				>
					<div class="boxAdress" (click)="openNeighborhood(i)">
						<p class="boxAdress__text">{{ address.city }}</p>
						<i
							class="fa fa-chevron-up boxAdress__icon"
							[ngClass]="{ rotateOpen: viewNeighborhood && openIndex === i }"
						></i>
					</div>

					<p
						class="neighborhoods"
						[ngClass]="{ view: viewNeighborhood && openIndex === i }"
						(click)="setCurrentNeighborhoodAndAddress(undefined, address)"
					>
						Todos
					</p>
					<p
						class="neighborhoods"
						[ngClass]="{ view: viewNeighborhood && openIndex === i }"
						*ngFor="let neighborhood of address.neighborhoods"
						(click)="setCurrentNeighborhoodAndAddress(neighborhood.neighborhood, address)"
					>
						{{ neighborhood.neighborhood }}
					</p>
				</ng-container>
			</div>
		</ng-container>

		<ng-container *ngIf="statusAddress === 'failure'">
			<ui-bb-generic-error title="Não foi possível carregar as cidades">
				Nosso sistema ficou indisponível por alguns instantes. Por favor, tente novamente.
			</ui-bb-generic-error>
		</ng-container>

		<ng-container *ngIf="statusAddress === 'failure'" footer>
			<ui-bb-footer position="static">
				<ui-bb-button type="primary" style="width: 220px" (clickButton)="tryLoadingAddressAgain()">
					Tente novamente
				</ui-bb-button>
			</ui-bb-footer>
		</ng-container>
		<ui-bb-footer position="static" footer></ui-bb-footer>
	</ng-template>

	<ui-bb-modal-bottom overflow="auto" title="Cidade" #modalBottomAddress *ngIf="isMobile">
		<ng-container *ngTemplateOutlet="addressTemplate"></ng-container>
	</ui-bb-modal-bottom>

	<ui-bb-modal-center
		title="Cidade"
		#modalCenterAddress
		*ngIf="!isMobile"
		(clickOutside)="modalCenterAddress.closeModal()"
	>
		<div style="padding-inline: 16px; height: 50dvh; overflow-y: auto; overflow-x: hidden">
			<ng-container *ngTemplateOutlet="addressTemplate"></ng-container>
		</div>
	</ui-bb-modal-center>
}
