import { Component, inject } from '@angular/core'
import { CommonModule } from '@angular/common'

import { Router } from '@angular/router'
import { HeaderComponent } from '../../../header/header.component'
import { SuggestARestaurantBodyComponent } from './suggest--a-restaurant-body/suggest-a-restaurant-body.component'
import { ModalBottomUiComponent } from '@monorepo-channels/components/ui-bb'

@Component({
	standalone: true,
	selector: 'bb-how-it-work-page',
	imports: [CommonModule, HeaderComponent, SuggestARestaurantBodyComponent, ModalBottomUiComponent],
	template: ` <bb-header></bb-header> <bb-suggest-a-restaurant-body />`,
})
export class SuggestARestaurantPageComponent {
	router = inject(Router)
}
