export class Reservation {
	reservationDateUTC: Date
	constructor(
		readonly partySize: number | null,
		readonly sectionLabel: string | null,
		readonly reservationDay: string | null,
		readonly reservationTime: string | null,
		readonly sectionId: string | null
	) {
		this.reservationDateUTC = new Date(`${reservationDay}T${reservationTime}`)
	}
}
