import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

export type ChooseComponentHeaderMenu = '/como-funciona' | '/faq' | '/terms' | '/contacts' | '/'

type ModalOthers = 'register-card' | 'payment' | 'complete-seu-cadastro'

@Injectable({ providedIn: 'root' })
export class ModalBBService {
	private openModalSub$ = new Subject<ChooseComponentHeaderMenu>()
	public openModal$ = this.openModalSub$.asObservable()

	private openModalOthersSub$ = new Subject<ModalOthers>()
	public openModalOthers$ = this.openModalOthersSub$.asObservable()

	openModalMenu(route: ChooseComponentHeaderMenu) {
		this.openModalSub$.next(route)
	}

	openModalOthers(route: ModalOthers) {
		this.openModalOthersSub$.next(route)
	}
}
