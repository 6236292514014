import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import {
	ButtonUiComponent,
	FooterUiComponent,
	LoadingPageComponent,
} from '@monorepo-channels/components/ui-bb'
import { DivisorUiComponent } from '@monorepo-channels/components/ui-common'
import { NgxMaskDirective } from 'ngx-mask'
import { HeaderComponent } from '../../header/header.component'
import { CompleteRegistrationBodyComponent } from './complete-registration-body/complete-registration-body.component'

@Component({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		DivisorUiComponent,
		NgxMaskDirective,
		FooterUiComponent,
		ButtonUiComponent,
		LoadingPageComponent,
		HeaderComponent,
		CompleteRegistrationBodyComponent,
	],
	standalone: true,
	template: `
		<bb-header></bb-header>
		<bb-complete-registration-body />
	`,
	styleUrl: 'complete-registration.component.scss',
})
export class CompleteRegistrationComponent {}
