import { capitalizeEachWord } from '@monorepo-channels/shared/util-helpers'
import { Category } from '../vo/category'
import { Agreements, ClientProps } from './client.interface'

export class Client {
	private constructor(
		readonly id: string,
		readonly firstName: string,
		readonly fullName: string,
		readonly email: string,
		readonly cpf: string,
		readonly phone: string | null,
		readonly token: string,
		public selectedCategory: Category | null,
		readonly cards: Category[],
		readonly agreements: Agreements,
		readonly selectedCardDetails: Category | null = null
	) {}

	static create(props: ClientProps) {
		const id = props._id
		const fullName = capitalizeEachWord(`${props.name} ${props.lastName}`)
		const cpf = props.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
		const token = props?.access_token?.token
		const phone = props?.phone ?? null
		const mainCard = props.categories.find(card => card.mainCard === true)
		const cards = props.categories.filter(category => category?.gatewayId)
		const selectCard = mainCard ?? cards[0] ?? props.categories[0]
		const selectedCategory = selectCard ? new Category(selectCard) : null
		return new Client(
			id,
			props.name,
			fullName,
			props.email,
			cpf,
			phone,
			token,
			selectedCategory,
			cards.map(card => new Category(card)),
			props.agreements
		)
	}

	// return new client because redux is immutable
	setCard(gatewayId: string, lastFourDigits: string): Client {
		const selectedCard = this.cards.find(
			card => card.gatewayId === gatewayId && card.lastFourDigits === lastFourDigits
		)
		if (!selectedCard) {
			return this
		}
		return new Client(
			this.id,
			this.firstName,
			this.fullName,
			this.email,
			this.cpf,
			this.phone,
			this.token,
			selectedCard,
			this.cards,
			this.agreements
		)
	}
}
