import { Component, Input } from '@angular/core'
import { CommonModule } from '@angular/common'

@Component({
	selector: 'ui-bb-tag',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './tag.component.html',
	styleUrls: ['./tag.component.scss'],
})
export class TagUiComponent {
	@Input() optionalBorder: boolean = true
}
