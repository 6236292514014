import { CommonModule } from '@angular/common'
import {
	AfterViewInit,
	Component,
	CUSTOM_ELEMENTS_SCHEMA,
	ElementRef,
	EventEmitter,
	inject,
	Input,
	Output,
	ViewChild,
} from '@angular/core'
import { Router } from '@angular/router'
import { isMobile } from '@monorepo-channels/shared/util-helpers'
import { ModalBBService } from '../services/modal-bb.service'

@Component({
	standalone: true,
	imports: [CommonModule],
	selector: 'ui-bb-swiper',
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	template: `
		<swiper-container init="false" #swiperContainer style="z-index: 0; position: relative;">
			<swiper-slide *ngFor="let banner of urls">
				<img
					[src]="banner"
					alt="banner"
					(click)="bannerClicked.emit(banner)"
					style="cursor: pointer;"
				/>
			</swiper-slide>
		</swiper-container>
	`,
	styles: `
    img {
		@import 'mixin';
        min-width: 100%;
        height: 234px;
        object-fit: cover;
        transition: opacity 1s ease-in-out;

	@include laptop-up {
		max-width: 1320px;
		height: 408px;
		aspect-ratio: 1320/408;
		margin: 0 auto;
		margin-top: 32px;
		min-width: unset;
		border-radius: 16px;
	}
    }
    `,
})
export class SwiperUiComponent implements AfterViewInit {
	@Input() urls: string[] = []
	@Output() bannerClicked = new EventEmitter<string>()
	isMobile = isMobile()

	@ViewChild('swiperContainer')
	swiperContainer!: ElementRef

	public modalBBService = inject(ModalBBService)
	public router = inject(Router)

	private config = {
		injectStyles: [
			`
      .swiper-pagination-bullet {
            width: 8px;
			height: 8px;
			background-color: var(--background-tertiary);
			opacity: 0.5;
			border-radius: 30px;
			cursor: pointer;
			transition: background-color 0.3s; 
      }

      .swiper-pagination-bullet-active {
			    width: 24px;
				opacity: 1;
				background-color: var(--background-tertiary);
      }
      `,
		],
		pagination: {
			clickable: true,
		},
		autoplay: { delay: 5000 },
		speed: 700,
		loop: true,
	}

	ngAfterViewInit(): void {
		Object.assign(this.swiperContainer.nativeElement, this.config)
		this.swiperContainer.nativeElement.initialize()
	}
}
