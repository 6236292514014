import { createFeatureSelector, createSelector } from '@ngrx/store'
import { VenueState, featureVenueName } from './venue.state'

const getVenueFeatureState = createFeatureSelector<VenueState>(featureVenueName)

export const getLocation = createSelector(getVenueFeatureState, state => state.location)
export const getVenues = createSelector(getVenueFeatureState, state => state.venues)
export const getFilterVenues = createSelector(getVenueFeatureState, state => state.filterVenues)

export const getErrorVenues = createSelector(getVenueFeatureState, state => state.errorGetVenues)
export const isLoadingGetVenues = createSelector(
	getVenueFeatureState,
	state => state.statusGetVenues === 'pending'
)
export const getSelectedVenue = createSelector(getVenueFeatureState, state => state.selectedVenue)
export const isLoadingSelectedVenue = createSelector(
	getVenueFeatureState,
	state => state.statusSelectedVenue === 'pending'
)
export const loadingMoreVenues = createSelector(
	getVenueFeatureState,
	state => state.statusLoadingMoreVenues === 'pending'
)
export const isAllLoaded = createSelector(getVenueFeatureState, state => state.allLoaded)

export const getStatusVenues = createSelector(getVenueFeatureState, state => state.statusGetVenues)

export const getVenueName = createSelector(getVenueFeatureState, state => state.name)
