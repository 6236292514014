import { Component } from '@angular/core'

@Component({
	standalone: true,
	selector: 'ui-common-info-svg',
	template: `
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M8 0C3.6 0 0 3.57778 0 8C0 12.4222 3.6 16 8 16C12.4222 16 16 12.4222 16 8C16 3.57778 12.4 0 8 0ZM8 15.1111C4.06667 15.1111 0.888889 11.9333 0.888889 8C0.888889 4.06667 4.06667 0.888889 8 0.888889C11.9333 0.888889 15.1111 4.08889 15.1111 8C15.1111 11.9111 11.9333 15.1111 8 15.1111ZM8.44439 6.91113H7.5555V11.7556H8.44439V6.91113ZM7.5555 4.24447H8.44439V6.02224H7.5555V4.24447Z"
				fill="#585B65"
			/>
		</svg>
	`,
})
export class InfoSVGComponent {}
