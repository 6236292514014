import { Component, EventEmitter, Input, Output, ViewChild, inject } from '@angular/core'
import { CommonModule } from '@angular/common'
import {
	ButtonUiComponent,
	FooterUiComponent,
	ModalBottomUiComponent,
	ModalUiComponent,
} from '@monorepo-channels/components/ui-bb'
import { Store } from '@ngrx/store'
import { ClientActions } from '@monorepo-channels/channels/domain'

@Component({
	selector: 'feature-bb-terms-and-conditions',
	standalone: true,
	imports: [CommonModule, ModalUiComponent, FooterUiComponent, ButtonUiComponent, ModalBottomUiComponent],
	templateUrl: './terms-and-conditions.component.html',
	styleUrls: ['./terms-and-conditions.component.scss'],
})
export class TermsAndConditionsComponent {
	private store = inject(Store)
	@ViewChild(ModalBottomUiComponent)
	modalTermsAndConditions!: ModalBottomUiComponent

	@Input({ required: true }) type!: 'reservation' | 'checkin'

	@Output()
	checkboxChange = new EventEmitter<boolean>()

	toggleCheckbox(event: Event) {
		const target = event.target as HTMLInputElement
		this.checkboxChange.emit(target.checked)
		const clientToUpdate = {
			agreements: { [this.type]: target.checked },
		}
		this.store.dispatch(ClientActions.updateClientBackend({ client: clientToUpdate }))
	}
}
