import { Component, HostListener, OnInit, inject } from '@angular/core'
import { CommonModule } from '@angular/common'

import {
	CounterUiComponent,
	CalendarUiComponent,
	ButtonUiComponent,
	FooterUiComponent,
	LoadingPageComponent,
	AlertTopUiComponent,
	SelectEnvironmentUiComponent,
	TermsAndConditionsComponent,
	OrderSuccessComponent,
	BoxMessageComponent,
} from '@monorepo-channels/components/ui-bb'
import { Store } from '@ngrx/store'
import {
	ClientActions,
	ReservationActions,
	VenueActions,
	getAvailabilities,
	getAvailabilitiesDates,
	getClient,
	getConfirmReservation,
	getErrorConfirmation,
	getMinMaxPartySize,
	getReservationTimes,
	getSchedules,
	getSections,
	getSelectedDate,
	getSelectedPartySize,
	getSelectedReservationTime,
	getSelectedSection,
	getSelectedVenue,
	getStatusAvailabilities,
	getStatusConfirmReservation,
	isLoadingConfirmReservation,
} from '@monorepo-channels/channels/domain'
import { ActivatedRoute, Router } from '@angular/router'
import { combineLatest, map } from 'rxjs'
import { isMobile } from '@monorepo-channels/shared/util-helpers'

import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { Actions, concatLatestFrom, ofType } from '@ngrx/effects'

@Component({
	standalone: true,
	selector: 'feature-bb-reservation',
	imports: [
		CommonModule,
		CounterUiComponent,
		CalendarUiComponent,
		ButtonUiComponent,
		FooterUiComponent,
		TermsAndConditionsComponent,
		LoadingPageComponent,
		AlertTopUiComponent,
		SelectEnvironmentUiComponent,
		OrderSuccessComponent,
		BoxMessageComponent,
	],
	templateUrl: './reservation.component.html',
	styleUrls: ['./reservation.component.scss'],
})
export class ReservationComponent implements OnInit {
	private store = inject(Store)
	private activatedRoute = inject(ActivatedRoute)
	public router = inject(Router)
	private actions$ = inject(Actions)
	showAlert = false

	isMobile = isMobile()

	@HostListener('window:resize', ['$event'])
	getScreenSize() {
		this.isMobile = isMobile()
	}

	vm$ = combineLatest([
		this.store.select(getSelectedVenue),
		this.store.select(getStatusAvailabilities),
		this.store.select(getStatusConfirmReservation),
		this.store.select(getErrorConfirmation),
		this.store.select(getSelectedSection),
		this.store.select(getSelectedDate),
		this.store.select(isLoadingConfirmReservation),
		this.store.select(getAvailabilities),
		this.store.select(getAvailabilitiesDates),
		this.store.select(getSections),
		this.store.select(getSchedules),
		this.store.select(getMinMaxPartySize),
		this.store.select(getSelectedPartySize),
		this.store.select(getReservationTimes),
		this.store.select(getSelectedReservationTime),
		this.store.select(getConfirmReservation),
		this.store.select(getClient),
	]).pipe(
		map(
			([
				venueDetail,
				statusAvailabilities,
				statusConfirmReservation,
				errorReservation,
				selectedSection,
				selectedDate,
				isLoadingConfirmReservation,
				availabilities,
				availabilitiesDates,
				sections,
				schedules,
				maxMinPartysize,
				selectedPartySize,
				reservationTimes,
				selectedReservationTime,
				confirmReservation,
				client,
			]) => ({
				venueDetail,
				statusAvailabilities,
				statusConfirmReservation,
				errorReservation,
				selectedSection,
				selectedDate,
				isLoadingConfirmReservation,
				availabilities,
				availabilitiesDates,
				sections,
				schedules,
				maxMinPartysize,
				selectedPartySize,
				reservationTimes,
				selectedReservationTime,
				confirmReservation,
				client,
			})
		)
	)

	eula = false

	constructor() {
		this.store
			.select(getClient)
			.pipe(takeUntilDestroyed())
			.subscribe({
				next: client => {
					this.eula = client?.agreements?.eula ?? false
				},
			})
		this.actions$
			.pipe(
				ofType(ReservationActions.setSelectedSection),
				concatLatestFrom(() => this.store.select(getSections)),
				takeUntilDestroyed(),
				map(([action, sections]) => {
					const section = sections?.find(sc => sc.label === action.selectedSectionLabel)
					if (!section) return
					this.store.dispatch(
						ReservationActions.setScheduleFromPartysize({
							selectedPartySize: String(section.schedules[0].partySize),
						})
					)
				})
			)
			.subscribe()
	}

	ngOnInit(): void {
		this.store.dispatch(ReservationActions.resetReservationState())
		const paramVenueId = this.activatedRoute.snapshot.paramMap.get('venueId')
		if (paramVenueId) {
			this.store.dispatch(VenueActions.getOneVenue({ venueId: paramVenueId }))
			this.store.dispatch(ReservationActions.loadAvailabilities({ venueId: paramVenueId }))
		}
	}

	setReservationDay(day: string) {
		this.store.dispatch(ReservationActions.resetInitialStateSuccess())
		this.store.dispatch(ReservationActions.setSelectedDate({ selectedDate: day }))
	}

	setSection(sectionLabel: string) {
		this.store.dispatch(ReservationActions.setSelectedSection({ selectedSectionLabel: sectionLabel }))
	}

	setPartysize(partysize: number) {
		this.store.dispatch(
			ReservationActions.setScheduleFromPartysize({ selectedPartySize: String(partysize) })
		)
	}

	setReservationTime(reservationTime: string) {
		this.store.dispatch(
			ReservationActions.setReservationTime({ selectedReservationTime: reservationTime })
		)
	}

	showAlertTop() {
		this.showAlert = true
		setTimeout(() => {
			this.showAlert = false
		}, 5000)
	}

	openReservation() {
		this.store.dispatch(ReservationActions.confirmReservation())
		this.store.dispatch(
			ClientActions.updateClientBackend({ client: { agreements: { eula: this.eula } } })
		)
	}

	acceptTerms(event: boolean) {
		this.eula = event
	}
}
