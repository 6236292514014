import { Component, EventEmitter, HostListener, Output, ViewChild, inject } from '@angular/core'
import { CommonModule } from '@angular/common'

import {
	ButtonUiComponent,
	FooterUiComponent,
	ModalBottomUiComponent,
	LoadingPageComponent,
	GenericErrorUiComponent,
	TooltipUiComponent,
	BenefitsUiComponent,
} from '@monorepo-channels/components/ui-bb'
import { Store } from '@ngrx/store'
import {
	OrderHistory,
	OrderHistoryActions,
	getClient,
	getSelectOrder,
	getStatusCancelOrder,
} from '@monorepo-channels/channels/domain'
import { combineLatest, map, take } from 'rxjs'
import { Router, RouterLink } from '@angular/router'
import { DivisorUiComponent } from '@monorepo-channels/components/ui-common'
import { Actions, ofType } from '@ngrx/effects'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { OrderHistoryService } from '../../order-history-body/order-history.service'
import { isMobile } from '@monorepo-channels/shared/util-helpers'

@Component({
	standalone: true,
	selector: 'bb-order-details-body',
	imports: [
		CommonModule,
		ButtonUiComponent,
		FooterUiComponent,
		ModalBottomUiComponent,
		DivisorUiComponent,
		RouterLink,
		LoadingPageComponent,
		GenericErrorUiComponent,
		TooltipUiComponent,
		BenefitsUiComponent,
	],
	templateUrl: './order-details-body.component.html',
	styleUrls: ['./order-details-body.component.scss'],
})
export class OrderDetailsBodyComponent {
	router = inject(Router)
	private store = inject(Store)
	client$ = this.store.select(getClient)
	private actions$ = inject(Actions)
	private orderHistoryService = inject(OrderHistoryService)
	isMobile = isMobile()

	@Output() openModalCancelDetailsEmitter = new EventEmitter<OrderHistory>()
	@Output() openModalDetailsToHistoryEmitter = new EventEmitter<OrderHistory>()
	@Output() openModalNoCardDetailsEmitter = new EventEmitter<OrderHistory>()
	@Output() openModalPaymentDetailsEmitter = new EventEmitter<string>()

	@HostListener('window:resize')
	onResize() {
		this.isMobile = isMobile()
	}

	vm$ = combineLatest([
		this.store.select(getClient),
		this.store.select(getSelectOrder),
		this.store.select(getStatusCancelOrder),
	]).pipe(map(([client, order, statusCancel]) => ({ client, order, statusCancel })))

	@ViewChild('modalRegisterCard')
	modalRegisterCard!: ModalBottomUiComponent

	@ViewChild('cancelOrderModal')
	cancelOrderModal!: ModalBottomUiComponent

	constructor() {
		this.actions$
			.pipe(ofType(OrderHistoryActions.cancelOrderSuccess), takeUntilDestroyed())
			.subscribe(() => {
				if (this.isMobile) {
					this.router.navigate(['/reservas-e-pagamento'])
				}
				this.orderHistoryService.setOrderAction('cancel', true)
			})
	}

	cancelOrder(order: OrderHistory | null) {
		if (!order) return
		this.store.dispatch(OrderHistoryActions.cancelOrder({ orderId: order.id }))
		this.cancelOrderModal.closeModal()
	}

	goToPayment(order: OrderHistory | null) {
		this.client$.pipe(take(1)).subscribe(client => {
			if (!client?.selectedCategory?.isRealCard) {
				if (this.isMobile) {
					this.modalRegisterCard.openModal()
				} else {
					this.openModalNoCardDetailsEmitter.emit()
				}
			} else {
				if (this.isMobile) {
					this.router.navigate(['/reservas-e-pagamento/pagamento', order?.id])
				} else {
					this.openModalPaymentDetailsEmitter.emit(order?.id)
				}
			}
		})
	}
	returnDetails() {
		if (this.isMobile) {
			this.router.navigate(['/reservas-e-pagamento'])
		} else {
			this.openModalDetailsToHistoryEmitter.emit()
		}
	}

	registerCard() {
		this.router.navigate(['/carteira/cadastrar-cartao'])
	}

	openCancel() {
		if (this.isMobile) {
			this.cancelOrderModal.openModal()
		} else {
			this.openModalCancelDetailsEmitter.emit()
		}
	}
}
