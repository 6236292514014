import { Injectable, inject } from '@angular/core'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { AppState } from '../helper.state'
import { Store } from '@ngrx/store'
import { CheckinActions } from './checkin.actions'
import { getCheckinOrderId } from './checkin.selectors'
import { catchError, map, of, switchMap } from 'rxjs'
import { getClient } from '../client/client.selectors'
import { OrderGateway, ResponseAvailabilityCheckin } from '../../gateways/order.gateway'
import { DataLayerService } from '@monorepo-channels/shared/util-helpers'

@Injectable()
export class CheckinEffects {
	private actions$ = inject(Actions)
	private orderGateway = inject(OrderGateway)
	private store = inject(Store<AppState>)
	private dataLayerService = inject(DataLayerService)

	loadAvailabilities$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(CheckinActions.loadAvailabilities),
			concatLatestFrom(() => this.store.select(getClient)),
			switchMap(([action, client]) => {
				return this.orderGateway
					.checkAvailability({
						venueId: action.venueId,
						clientId: client!.id,
						categoryId: client!.selectedCategory!.categoryId,
						intent: 'checkin',
					})
					.pipe(
						switchMap((response: unknown) => {
							const correctResponse = response as ResponseAvailabilityCheckin
							if (!correctResponse.success) {
								this.dataLayerService.logEvent({
									event: 'CH_checkin_load_availabilities_unavailable',
									clientId: client?.id,
								})
								return [
									CheckinActions.checkInAvailabilitiesUnavailable({
										error: correctResponse.error?.message,
										availabilities: correctResponse.availabilities,
									}),
									CheckinActions.updateAvailabilities({
										availabilities: correctResponse.availabilities,
									}),
								]
							}
							this.dataLayerService.logEvent({
								event: 'CH_checkin_load_availabilities_success',
								venueId: action.venueId,
								clientId: client?.id,
							})
							return [
								CheckinActions.loadAvailabilitiesSuccess({
									availabilities: correctResponse.availabilities,
								}),
								CheckinActions.setOrderIdAfterLoadingAvailabilities({
									orderId: correctResponse.order._id,
								}),
							]
						}),
						catchError(error => {
							this.dataLayerService.logEvent({
								event: 'CH_checkin_load_availabilities_error',
								clientId: client?.id,
								venueId: action.venueId,
								error: error?.error?.message,
							})
							return of(CheckinActions.loadAvailabilitiesFail({ error: error.error?.message }))
						})
					)
			})
		)
	})

	confirmCheckin$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(CheckinActions.checkInConfirmation),
			concatLatestFrom(() => [this.store.select(getCheckinOrderId), this.store.select(getClient)]),
			switchMap(([action, orderId, client]) =>
				this.orderGateway
					.confirmOrder(orderId as string, {
						reservationDay: action.checkInDate.day,
						reservationTime: action.checkInDate.time,
						partySize: action.partySize,
						clientId: client!.id,
					})
					.pipe(
						map(() => {
							this.dataLayerService.logEvent({
								event: 'CH_checkin_success',
								clientId: client?.id,
								reservationDay: action.checkInDate.day,
								reservationTime: action.checkInDate.time,
								orderId: orderId,
							})
							return CheckinActions.checkInConfirmSuccess()
						}),
						catchError(error => {
							this.dataLayerService.logEvent({
								event: 'CH_checkin_error',
								clientId: client?.id,
								reservationDay: action.checkInDate.day,
								reservationTime: action.checkInDate.time,
								orderId: orderId,
							})
							return of(CheckinActions.checkInConfirmFailure({ error: error?.error?.message }))
						})
					)
			)
		)
	})
}
