<div class="overlay" (click)="openBox()" [ngStyle]="{ display: seeBox ? 'block' : 'none' }"></div>

<div style="position: relative">
	<div class="initalMode" (click)="openBox()">
		<div class="initalMode__inside">
			<p class="initalMode__inside__title">Ambiente</p>
			<p class="initalMode__inside__text">{{ selectedItem }}</p>
			<p class="initalMode__inside__text deselect" *ngIf="selectedItem === null">
				Selecione o ambiente
			</p>
		</div>
		<i class="fa fa-chevron-up" style="rotate: 180deg"></i>
	</div>

	<div class="box" *ngIf="seeBox">
		<div class="box__initial">
			<p class="box__initial__title">Ambiente</p>
			<p class="box__initial__text">{{ selectedItem }}</p>
		</div>
		<ui-common-divisor></ui-common-divisor>
		<div style="padding: 0 16px">
			<ng-container *ngIf="selectedItem !== null">
				<label class="box__options" *ngFor="let section of sections">
					<input
						[checked]="selectedItem === section.label"
						type="radio"
						class="box__options__radio"
						[value]="section.label"
						name="box"
						(change)="valueChanged($event)"
					/>
					<p class="box__options__text">{{ section.label }}</p>
				</label></ng-container
			>
			<ng-container *ngIf="selectedItem === null">
				<label class="box__options" *ngFor="let section of sections">
					<input
						[checked]="selectedItem === section.label"
						type="radio"
						class="box__options__radio"
						[value]="section.label"
						name="box"
						(change)="valueChanged($event)"
					/>
					<p class="box__options__text textForce">{{ section.label }}</p>
				</label></ng-container
			>
		</div>
	</div>
</div>
