import { Component, inject, ViewChild } from '@angular/core'

import { ModalBottomFailureComponent } from '@monorepo-channels/components/ui-bb'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { Store } from '@ngrx/store'
import { ClientActions, getErrorRegisterCard } from '@monorepo-channels/channels/domain'
import { Actions, ofType } from '@ngrx/effects'
import { HeaderComponent } from '../../../header/header.component'
import { NewCardBodyComponent } from './new-card-body/new-card-body.component'
import { AsyncPipe, NgIf } from '@angular/common'

@Component({
	standalone: true,
	imports: [HeaderComponent, NewCardBodyComponent, ModalBottomFailureComponent, NgIf, AsyncPipe],
	templateUrl: './new-card.component.html',
})
export class NewCardComponent {
	private actions$ = inject(Actions)
	private store = inject(Store)

	registerCardError$ = this.store.select(getErrorRegisterCard)

	@ViewChild('modalRegisterCardFailure')
	registerCardFailure!: ModalBottomFailureComponent
	constructor() {
		this.actions$.pipe(ofType(ClientActions.registerCardFailure), takeUntilDestroyed()).subscribe(() => {
			this.registerCardFailure.openModal()
		})
	}
}
