import { Component, HostListener, OnInit, ViewChild, inject } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CloseFillSVGComponent, DivisorUiComponent } from '@monorepo-channels/components/ui-common'

import {
	BenefitsUiComponent,
	TagUiComponent,
	FooterUiComponent,
	ButtonUiComponent,
	LoadingPageComponent,
	ModalBottomUiComponent,
	ModalCenterComponent,
	SwiperUiComponent,
	BoxMessageComponent,
} from '@monorepo-channels/components/ui-bb'
import { ActivatedRoute, Router } from '@angular/router'
import {
	CheckinActions,
	ClientActions,
	getCheckinAvailabilities,
	getCheckinErrorConfirm,
	getClient,
	getErrorAvailability,
	getErrorConfirmation,
	getSelectedVenue,
	isLoadingSelectedVenue,
	ReservationActions,
	VenueActions,
	VenueDetail,
} from '@monorepo-channels/channels/domain'
import { Store } from '@ngrx/store'
import { take } from 'rxjs'
import { HandleLoginComponent, ReservationComponent } from '@monorepo-channels/feature-bb'
import { CheckinComponent } from '@monorepo-channels/feature-bb'
import { Actions, ofType } from '@ngrx/effects'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { isMobile } from '@monorepo-channels/shared/util-helpers'
import { HeaderComponent } from '../../../header/header.component'
import { Location } from '@angular/common'

@Component({
	standalone: true,
	imports: [
		CommonModule,
		BenefitsUiComponent,
		TagUiComponent,
		FooterUiComponent,
		ButtonUiComponent,
		LoadingPageComponent,
		ModalBottomUiComponent,
		ModalCenterComponent,
		HandleLoginComponent,
		CheckinComponent,
		ReservationComponent,
		CloseFillSVGComponent,
		SwiperUiComponent,
		DivisorUiComponent,
		HeaderComponent,
		BoxMessageComponent,
	],
	templateUrl: './venue-details.page.html',
	styleUrls: ['./venue-details.page.scss'],
})
export class VenueDetailsPageComponent implements OnInit {
	private store = inject(Store)
	private activatedRoute = inject(ActivatedRoute)
	private router = inject(Router)
	private route = inject(ActivatedRoute)
	private actions$ = inject(Actions)

	venueDetail$ = this.store.select(getSelectedVenue)
	loadingVenue$ = this.store.select(isLoadingSelectedVenue)
	availabilitiesCheckin$ = this.store.select(getCheckinAvailabilities)
	client$ = this.store.select(getClient)
	errorConfirmReservation$ = this.store.select(getErrorConfirmation)

	errorAvailability$ = this.store.select(getErrorAvailability)
	location = inject(Location)

	isMobile = isMobile()
	openCheckin = false
	openReservation = false

	@ViewChild(ModalBottomUiComponent)
	modalBottomCheckinUnavaiable!: ModalBottomUiComponent

	@ViewChild('modalBottomReservationUnavailable')
	modalBottomReservationUnavailable!: ModalBottomUiComponent

	@ViewChild('modalBottomLogin')
	modalBottomLogin!: ModalBottomUiComponent

	@ViewChild('modalCenterLogin')
	modalCenterLogin!: ModalCenterComponent

	@ViewChild('modalRegisterPhone')
	modalRegisterPhone!: ModalBottomUiComponent

	@ViewChild('modalCheckin')
	modalChekcin!: ModalCenterComponent

	@ViewChild('modalReserva')
	modalReserva!: ModalCenterComponent

	ngOnInit(): void {
		const venueId = this.activatedRoute.snapshot.paramMap.get('venueId')
		venueId && this.store.dispatch(VenueActions.loadOneVenue({ venueId }))
		this.getScreenSize()
	}

	@HostListener('window:resize', ['$event'])
	getScreenSize() {
		this.isMobile = isMobile()
	}

	errorConfirm = this.store.select(getCheckinErrorConfirm)

	@ViewChild('modalCheckinFailure')
	modalCheckinFailure!: ModalCenterComponent

	@ViewChild('modalReservationFailure')
	modalReservationFailure!: ModalCenterComponent

	@ViewChild('modalCenterCheckinUnavailable')
	modalCenterCheckinUnavailable!: ModalCenterComponent

	@ViewChild('modalCenterReservationUnavailable')
	modalCenterReservationUnavailable!: ModalCenterComponent

	@ViewChild('modalCenterAvailabilitiesFailure')
	modalCenterAvailabilitiesFailure!: ModalCenterComponent

	@ViewChild(HeaderComponent) header!: HeaderComponent

	//@ViewChild('header')	header!: HeaderComponent

	constructor() {
		this.actions$
			.pipe(
				ofType(CheckinActions.checkInConfirmFailure, CheckinActions.loadAvailabilitiesFail),
				takeUntilDestroyed()
			)
			.subscribe({
				next: () => {
					this.closeModalCheckin()
					this.modalCheckinFailure.openModal()
				},
			})

		this.actions$
			.pipe(ofType(ReservationActions.confirmReservationFail), takeUntilDestroyed())
			.subscribe({
				next: () => {
					this.closeModalReserva()
					this.modalReservationFailure.openModal()
				},
			})
		this.actions$
			.pipe(ofType(ReservationActions.loadAvailabilitiesFail), takeUntilDestroyed())
			.subscribe({
				next: () => {
					this.closeModalReserva()
					this.modalCenterAvailabilitiesFailure.openModal()
				},
			})
	}

	openRegisterCard() {
		this.closeModalCheckin()
	}

	closeModalCheckin() {
		this.openCheckin = false
		this.modalChekcin.closeModal()
	}

	closeModalReserva() {
		this.openReservation = false
		this.modalReserva.closeModal()
	}

	onCheckinClick(venueDetail: VenueDetail) {
		if (venueDetail.checkin.status === 'disabled') {
			this.isMobile
				? this.modalBottomCheckinUnavaiable.openModal()
				: this.modalCenterCheckinUnavailable.openModal()
			return
		}
		if (venueDetail.checkin.status === 'unavailable') {
			// this.store.dispatch(CheckinActions.loadAvailabilities({ venueId: venueDetail._id }))
			this.isMobile
				? this.modalBottomCheckinUnavaiable.openModal()
				: this.modalCenterCheckinUnavailable.openModal()
			return
		}
		this.blockUntilAuthenticated('checkin')
	}

	onReservationClick(venueDetail: VenueDetail) {
		if (this.modalCenterCheckinUnavailable) {
			this.modalCenterCheckinUnavailable.closeModal()
		}
		if (venueDetail.reservation?.enabled === false) {
			this.isMobile
				? this.modalBottomReservationUnavailable.openModal()
				: this.modalCenterReservationUnavailable.openModal()
			return
		}

		this.blockUntilAuthenticated('reserva')
	}

	openLoginLivelo() {
		this.store.dispatch(ClientActions.loginClientLiveloGetAuthorizationCode())
	}

	openRegisterPhonePage() {
		this.router.navigate(['/complete-seu-cadastro'])
	}

	private blockUntilAuthenticated(intent: 'reserva' | 'checkin') {
		this.client$.pipe(take(1)).subscribe(client => {
			if (!client) {
				this.modalBottomLogin.openModal()
			} else if (!client?.phone) {
				this.modalRegisterPhone.openModal()
			} else {
				if (!this.isMobile) {
					if (intent === 'checkin') {
						this.openCheckin = true
						this.modalChekcin.openModal()
					}
				} else {
					this.router.navigate([intent], { relativeTo: this.route })
				}
				if (!this.isMobile) {
					if (intent === 'reserva') {
						this.openReservation = true
						this.modalReserva.openModal()
					}
				} else {
					this.router.navigate([intent], { relativeTo: this.route })
				}
			}
		})
	}

	goOrderHistory() {
		this.modalChekcin.closeModal()
		this.header.openModalCenterOrderHistory()
	}
}
