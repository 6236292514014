import { InjectionToken } from '@angular/core'

export const PartnersId = {
	BRADESCO: '595166383625c506d4868f28',
	ESFERA: '63600bcb4a49e96746e30f7d',
	ITAU: '641c6e6ba05e256a0a2e0da8',
	FOODSTER: '643960766e7a2ca377e24005',
	LATAM: '643994cb6e7a2ca377e2400c',
	UNICRED: '650ded0e0d58e49d57af264a',
	BB: '666735bf20415739cd5aa3bd',
} as const

export type PartnerKeys = keyof typeof PartnersId
export type PartnerValues = (typeof PartnersId)[PartnerKeys]

export const PartnerToken = new InjectionToken<PartnerValues>('partnerId')

class BenefitsLatam {}
export type R<T extends (typeof PartnersId)[keyof typeof PartnersId]> = T extends (typeof PartnersId)['LATAM']
	? BenefitsLatam
	: undefined
