import { Component } from '@angular/core'

@Component({
	standalone: true,
	selector: 'ui-common-bb-checkin-icon',
	template: `
		<svg width="39" height="50" viewBox="0 0 39 50" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M21.3786 47.8957C15.9565 43.1932 11.8713 38.8068 9.123 34.7364C6.37433 30.6661 5 26.9395 5 23.5567C5 19.1657 6.48754 15.3085 9.46263 11.9851C12.4373 8.66171 16.3994 7 21.3489 7C26.2984 7 30.2704 8.66171 33.2649 11.9851C36.2598 15.3085 37.7572 19.1657 37.7572 23.5567C37.7572 26.9395 36.393 30.6572 33.6645 34.7097C30.9364 38.7627 26.8411 43.158 21.3786 47.8957Z"
				fill="#E9E9E9"
			/>
			<path
				d="M16.3994 28.7858C17.3296 28.7858 18.1189 28.4547 18.7673 27.7924C19.416 27.1298 19.7404 26.3336 19.7404 25.4038C19.7404 24.4736 19.4091 23.6841 18.7465 23.0353C18.0842 22.3869 17.288 22.0627 16.3578 22.0627C15.4276 22.0627 14.6383 22.3939 13.9899 23.0561C13.3412 23.7187 13.0168 24.5149 13.0168 25.4447C13.0168 26.375 13.3481 27.1644 14.0107 27.8132C14.6729 28.4616 15.4692 28.7858 16.3994 28.7858ZM16.3786 47.3612C20.7237 43.6737 24.223 39.8507 26.8767 35.8924C29.5304 31.934 30.8572 28.4889 30.8572 25.557C30.8572 21.3702 29.5015 17.873 26.79 15.0654C24.0785 12.2582 20.6081 10.8545 16.3786 10.8545C12.1491 10.8545 8.67865 12.2582 5.96719 15.0654C3.25573 17.873 1.9 21.3702 1.9 25.557C1.9 28.4889 3.22683 31.934 5.8805 35.8924C8.53417 39.8507 12.0335 43.6737 16.3786 47.3612ZM16.3786 49.896C10.9565 45.1935 6.87127 40.807 4.123 36.7367C1.37433 32.6663 0 28.9398 0 25.557C0 21.166 1.48754 17.3088 4.46263 13.9854C7.43731 10.662 11.3994 9.00024 16.3489 9.00024C21.2984 9.00024 25.2704 10.662 28.2649 13.9854C31.2598 17.3088 32.7572 21.166 32.7572 25.557C32.7572 28.9398 31.393 32.6574 28.6645 36.71C25.9364 40.7629 21.8411 45.1582 16.3786 49.896Z"
				fill="#585B65"
			/>
			<rect x="19" width="20" height="20" rx="10" fill="#4969E6" />
			<path d="M24 10.0001L27.3333 13.3334L34 6.66675" fill="#4969E6" />
			<path
				d="M24 10.0001L27.3333 13.3334L34 6.66675"
				stroke="white"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	`,
	styles: `svg {
        position: relative;
    }`,
})
export class CheckinIconSVGComponent {}
