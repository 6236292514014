import { CommonModule, NgComponentOutlet } from '@angular/common'
import { Component, HostListener, OnInit, OnDestroy, inject } from '@angular/core'

import {
	VenueCardListComponent,
	FilterComponent,
	AlertHomeFeatureComponent,
	HandleLoginComponent,
} from '@monorepo-channels/feature-bb'
import {
	ClientActions,
	LoginHomologationDirective,
	OrderHistoryActions,
	VenueActions,
} from '@monorepo-channels/channels/domain'
import { BannerUiComponent, ModalBBService, SwiperUiComponent } from '@monorepo-channels/components/ui-bb'
import { Store } from '@ngrx/store'
import { ActivatedRoute, Router } from '@angular/router'
import { ModalCenterComponent } from '@monorepo-channels/components/ui-bb'
import { isMobile } from '@monorepo-channels/shared/util-helpers'
import { HeaderComponent } from '../../header/header.component'

@Component({
	standalone: true,
	imports: [
		SwiperUiComponent,
		CommonModule,
		FilterComponent,
		VenueCardListComponent,
		BannerUiComponent,
		AlertHomeFeatureComponent,
		HandleLoginComponent,
		ModalCenterComponent,
		NgComponentOutlet,
		HeaderComponent,
	],
	templateUrl: 'venues-list.page.html',
	styleUrls: ['venues-list.page.scss'],
	hostDirectives: [LoginHomologationDirective],
})
export class VenueListPageComponent implements OnInit, OnDestroy {
	private store = inject(Store)
	private route = inject(ActivatedRoute)
	private router = inject(Router)
	public modalBBService = inject(ModalBBService)

	urlsImg!: string[]
	searchName = ''
	isMobile = isMobile()

	@HostListener('window:resize')
	isMobileDevice() {
		this.isMobile = isMobile()
	}

	ngOnInit(): void {
		this.store.dispatch(VenueActions.resetSelectedVenue())
		const fragment = this.route.snapshot.fragment
		if (fragment) {
			const params = new URLSearchParams(fragment)
			const code = params.get('code')
			if (!code) return
			const goBackUrl = localStorage.getItem('go_back_url')
			this.router.navigate([goBackUrl ?? ''], { fragment: undefined })
			this.store.dispatch(ClientActions.loginClientLiveloSSO({ code }))
		} else {
			const hasAccess = localStorage.getItem('hasAccess')
			if (!hasAccess) {
				if (this.isMobile) {
					this.router.navigate(['/como-funciona'])
				} else {
					setTimeout(() => {
						this.modalBBService.openModalMenu('/como-funciona')
					}, 500)
				}
				localStorage.setItem('hasAccess', 'sim')
			}
		}

		this.sizeScreen()
		window.addEventListener('resize', () => {
			this.sizeScreen()
		})
	}

	ngOnDestroy() {
		window.removeEventListener('resize', () => {
			this.sizeScreen()
		})
	}
	sizeScreen() {
		if (window.innerWidth < 768) {
			this.urlsImg = ['https://tagmepub.blob.core.windows.net/pubimg/Channels/BB/bannerMobile.png']
		} else {
			this.urlsImg = ['https://tagmepub.blob.core.windows.net/pubimg/Channels/BB/bannerDesk.png']
		}
	}

	setSearchName(name: string) {
		this.searchName = name
	}

	clickPay(orderId: string) {
		if (this.isMobile) {
			this.router.navigate([`reservas-e-pagamento/pagamento/${orderId}`])
		} else {
			this.store.dispatch(OrderHistoryActions.setSelectedOrderId({ orderId }))
			this.modalBBService.openModalOthers('payment')
		}
	}

	clickRegisterCard() {
		if (this.isMobile) {
			this.router.navigate(['/carteira/cadastrar-cartao'])
		} else {
			this.modalBBService.openModalOthers('register-card')
		}
	}

	clickBanner(url: string) {
		if (url === this.urlsImg[0]) {
			if (this.isMobile) {
				this.router.navigate(['/como-funciona'])
			} else {
				this.modalBBService.openModalMenu('/como-funciona')
			}
		}
	}
}
