import { createReducer, on } from '@ngrx/store'
import { VenueState, initialStateVenue } from './venue.state'
import { VenueActions } from './venue.actions'
import { DEFAULT_LIMIT } from '../../gateways/venues.gateway'
import { newRestaurant } from '@monorepo-channels/shared/util-helpers'

export const venueReducer = createReducer<VenueState>(
	initialStateVenue,
	on(VenueActions.getVenues, (state): VenueState => {
		return {
			...state,
			selectedVenue: null,
		}
	}),
	on(VenueActions.loadVenuesSuccess, (state, action): VenueState => {
		return {
			...state,
			errorGetVenues: null,
			venues: action.venues.filter(venue => venue.isNotEmpty),
			statusGetVenues: 'success',
			allLoaded: action.venues.length < DEFAULT_LIMIT,
		}
	}),
	on(VenueActions.loadVenuesFail, (state, action): VenueState => {
		return {
			...state,
			errorGetVenues: action.error,
			statusGetVenues: 'failure',
			venues: null,
			allLoaded: false,
		}
	}),
	on(VenueActions.loadVenues, (state): VenueState => {
		return {
			...state,
			venues: null,
			statusGetVenues: 'pending',
		}
	}),
	on(VenueActions.loadOneVenue, (state): VenueState => {
		return {
			...state,
			selectedVenue: null,
			statusSelectedVenue: 'pending',
		}
	}),
	on(VenueActions.loadOneVenueSuccess, (state, action): VenueState => {
		return {
			...state,
			selectedVenue: action.venue,
			statusSelectedVenue: 'success',
		}
	}),
	on(VenueActions.loadOneVenueFail, (state, action): VenueState => {
		return {
			...state,
			statusSelectedVenue: 'failure',
			erroGetVenue: action.error,
		}
	}),
	on(VenueActions.loadMoreVenue, (state): VenueState => {
		return {
			...state,
			statusLoadingMoreVenues: 'pending',
		}
	}),
	on(VenueActions.loadMoreVenueSuccess, (state, action): VenueState => {
		const venues = action.venues.filter(venue => venue.isNotEmpty)
		return {
			...state,
			errorLoadingMoreVenues: '',
			venues: state.venues ? [...state.venues, ...venues] : [...venues],
			allLoaded: action.venues.length < DEFAULT_LIMIT,
			statusLoadingMoreVenues: 'success',
		}
	}),
	on(VenueActions.loadMoreVenueFail, (state, action): VenueState => {
		return {
			...state,
			venues: null,
			allLoaded: false,
			errorLoadingMoreVenues: action.error,
			statusLoadingMoreVenues: 'failure',
		}
	}),
	on(VenueActions.setLocation, (state, action): VenueState => {
		return {
			...state,
			location: {
				latitude: action.latitude,
				longitude: action.longitude,
				maxDistance: action.maxDistance,
			},
		}
	}),
	on(VenueActions.filterVenuesByName, (state): VenueState => {
		return {
			...state,
			statusGetVenues: 'pending',
			selectedVenue: null,
		}
	}),
	on(VenueActions.filterVenuesByNameV2, (state, action): VenueState => {
		return {
			...state,
			name: action.name,
		}
	}),
	on(VenueActions.filterVenuesByNameSuccess, (state, action): VenueState => {
		return {
			...state,
			errorGetVenues: null,
			selectedVenue: null,
			filterVenues: action.venuesFilter.filter(venue => venue.isNotEmpty),
			statusGetVenues: 'success',
			allLoaded: action.venuesFilter.length < DEFAULT_LIMIT,
		}
	}),
	on(VenueActions.filterVenuesByNameFail, (state, action): VenueState => {
		return {
			...state,
			errorGetVenues: action.error,
			statusGetVenues: 'failure',
			filterVenues: null,
			allLoaded: false,
		}
	}),
	on(VenueActions.cleanFilterVenuesByName, (state): VenueState => {
		return {
			...state,
			filterVenues: null,
			name: undefined,
		}
	}),
	on(VenueActions.cleanFilter, (state): VenueState => {
		return {
			...state,
			selectedVenue: null,
			statusGetVenues: 'idle',
			allLoaded: false,
			errorGetVenues: null,
		}
	}),
	on(VenueActions.resetSelectedVenue, (state): VenueState => {
		return {
			...state,
			selectedVenue: null,
			statusSelectedVenue: 'idle',
			erroGetVenue: null,
		}
	}),
	on(VenueActions.filterVenuesByFavorites, (state): VenueState => {
		return {
			...state,
			errorFavoritesVenues: null,
			statusFavoritesVenues: 'pending',
		}
	}),
	on(VenueActions.filterVenuesByFavoritesSuccess, (state, action): VenueState => {
		return {
			...state,
			errorFavoritesVenues: null,
			favoritesVenues: action.favoritesVenues.filter(venue => venue.isNotEmpty),
			statusFavoritesVenues: 'success',
		}
	}),
	on(VenueActions.loadVenuesFail, (state, action): VenueState => {
		return {
			...state,
			errorFavoritesVenues: action.error,
			statusFavoritesVenues: 'failure',
			favoritesVenues: null,
		}
	}),
	on(VenueActions.setCurrentCategories, (state, action): VenueState => {
		return {
			...state,
			categories: action.categories,
		}
	}),
	on(VenueActions.cleanCategories, (state): VenueState => {
		return {
			...state,
			categories: [],
		}
	}),
	on(VenueActions.setMichelinGuide, (state, action): VenueState => {
		return {
			...state,
			michelinGuide: action.michelinGuide,
		}
	}),
	on(VenueActions.cleanMichelinGuide, (state): VenueState => {
		return {
			...state,
			michelinGuide: undefined,
		}
	}),
	on(VenueActions.setNewRestaurant, (state, action): VenueState => {
		return {
			...state,
			newRestaurant: action.newRestaurant ? newRestaurant() : null,
		}
	}),
	on(VenueActions.cleanNewRestaurant, (state): VenueState => {
		return {
			...state,
			newRestaurant: null,
		}
	})
)
