import { OrderHistory } from '../../models/order-history/order-history'
import { Status } from '../helper.state'

export const featureOrderHistoryName = 'orderHistory'

export interface OrderHistoryState {
	orders: OrderHistory[] | null
	selectedOrder: OrderHistory | null
	statusOrderHistory: Status
	errorOrderHistory: string | null
	hasPaymentReady: boolean
	ordersToPay: OrderHistory[] | null
	// cancel
	statusCancelOrder: Status
	errorCancelOrder: string | null
}

export const initialStateOrderHistory: OrderHistoryState = {
	orders: null,
	selectedOrder: null,
	statusOrderHistory: 'idle',
	errorOrderHistory: null,
	statusCancelOrder: 'idle',
	errorCancelOrder: null,
	hasPaymentReady: false,
	ordersToPay: null,
}
