import { createReducer, on } from '@ngrx/store'
import { PaymentState, initialStatePayment } from './payment.state'
import { PaymentActions } from './payment.actions'

export const paymentReducer = createReducer<PaymentState>(
	initialStatePayment,
	on(PaymentActions.setPaymentOrder, (state, action): PaymentState => {
		return {
			...state,
			paymentOrder: action.paymentOrder,
		}
	}),
	on(PaymentActions.createPayment, (state): PaymentState => {
		return {
			...state,
			statusPayment: 'pending',
		}
	}),
	on(PaymentActions.createPaymentSuccess, (state, action): PaymentState => {
		if (!state.paymentOrder) return { ...state }
		return {
			...state,
			paymentOrder: {
				gratuityValue: state.paymentOrder.gratuityValue,
				value: state.paymentOrder.value,
				points: action.res.points,
				totalValue: action.res.orderTotalValue,
				code: action.res.code,
				paidAt: action.res.paidAt,
			},
			statusPayment: 'success',
			errorPayment: '',
		}
	}),
	on(PaymentActions.createPaymentFailure, (state, action): PaymentState => {
		return {
			...state,
			errorPayment: action.error,
			statusPayment: 'failure',
		}
	}),
	on(PaymentActions.resetPaymentStatus, (state): PaymentState => {
		return {
			...state,
			errorPayment: null,
			statusPayment: 'idle',
		}
	})
)
