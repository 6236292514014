<bb-header></bb-header>

<feature-bb-checkin *ngIf="isMobile" (registerCard)="router.navigate(['/carteira/cadastrar-cartao'])" />

<ui-bb-modal-bottom-failure title="Check-in Não realizado" #modalCheckinFailure>
	<ng-container *ngIf="errorConfirm | async as errorConfirm">
		@if(errorConfirm && errorConfirm.includes('Não é permitido')) { {{errorConfirm}} } @else {
		<p>Não conseguimos realizar seu check-in para o dia/horário que você deseja.</p>
		}
	</ng-container>
</ui-bb-modal-bottom-failure>
