<div class="terms">
	<p class="terms__text">
		Li e aceito os
		<button class="terms__link" (click)="modalTermsAndConditions.openModal()">termos e condições</button>
		para utilizar o Único Gastronomia.
	</p>
	<input type="checkbox" class="confirm" (change)="toggleCheckbox($event)" />
</div>

<ui-bb-modal-bottom title="Termos e condições" height="70vh" overflow="auto">
	;Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsum, quas nobis omnis nemo corporis, qui
	illum ipsam atque itaque unde nostrum hic culpa dignissimos voluptates dolorem fuga eaque asperiores amet.
	Hello World ;Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsum, quas nobis omnis nemo
	corporis, qui illum ipsam atque itaque unde nostrum hic culpa dignissimos voluptates dolorem fuga eaque
	asperiores amet. ;Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsum, quas nobis omnis nemo
	corporis, qui illum ipsam atque itaque unde nostrum hic culpa dignissimos voluptates dolorem fuga eaque
	asperiores amet. Hello World ;Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsum, quas nobis
	omnis nemo corporis, qui illum ipsam atque itaque unde nostrum hic culpa dignissimos voluptates dolorem
	fuga eaque asperiores amet. ;Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsum, quas nobis
	omnis nemo corporis, qui illum ipsam atque itaque unde nostrum hic culpa dignissimos voluptates dolorem
	fuga eaque asperiores amet. Hello World ;Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsum,
	quas nobis omnis nemo corporis, qui illum ipsam atque itaque unde nostrum hic culpa dignissimos voluptates
	dolorem fuga eaque asperiores amet. ;Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsum, quas
	nobis omnis nemo corporis, qui illum ipsam atque itaque unde nostrum hic culpa dignissimos voluptates
	dolorem fuga eaque asperiores amet. Hello World ;Lorem ipsum, dolor sit amet consectetur adipisicing elit.
	Ipsum, quas nobis omnis nemo corporis, qui illum ipsam atque itaque unde nostrum hic culpa dignissimos
	voluptates dolorem fuga eaque asperiores amet.
	<ui-bb-footer position="static" footer>
		<ui-bb-button
			type="primary"
			style="width: 220px"
			(clickButton)="modalTermsAndConditions.closeModal()"
		>
			Concluir
		</ui-bb-button>
	</ui-bb-footer>
</ui-bb-modal-bottom>
