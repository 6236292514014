import {
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	OnInit,
	Output,
	ViewChild,
	computed,
	inject,
	signal,
} from '@angular/core'
import { CommonModule } from '@angular/common'

import {
	FooterUiComponent,
	ButtonUiComponent,
	ModalBottomUiComponent,
	GenericErrorUiComponent,
	LoadingPageComponent,
	ModalCenterComponent,
} from '@monorepo-channels/components/ui-bb'
import {
	getCuisines,
	getAddresses,
	AddressActions,
	CuisineActions,
	Cuisine,
	VenueActions,
	getStatusAddress,
	Address,
	getSelectedAddress,
	getSelectedCuisines,
	getSelectedNeighborhood,
	getVenueName,
	getNewRestaurant,
} from '@monorepo-channels/channels/domain'
import {
	Observable,
	Subject,
	debounceTime,
	distinctUntilChanged,
	filter,
	map,
	startWith,
	switchMap,
	take,
	tap,
} from 'rxjs'
import { Store } from '@ngrx/store'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { FormsModule } from '@angular/forms'
import { isMobile, removeAccents } from '@monorepo-channels/shared/util-helpers'

@Component({
	selector: 'feature-bb-filter',
	standalone: true,
	imports: [
		CommonModule,
		FormsModule,
		FooterUiComponent,
		ButtonUiComponent,
		ModalBottomUiComponent,
		GenericErrorUiComponent,
		LoadingPageComponent,
		ModalCenterComponent,
	],
	templateUrl: './filter.component.html',
	styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {
	private store = inject(Store)
	public cuisines: Array<Cuisine & { selected: boolean }> = []

	@Output()
	public searchNameChanged = new EventEmitter<string>()
	public searchName = ''
	public searchCity = ''
	addresses$ = this.store.select(getAddresses)

	selectedAddress$: Observable<Address | null> = this.store.select(getSelectedAddress)
	selectedNeighborhood$: Observable<string | undefined> = this.store.select(getSelectedNeighborhood)

	openIndex: number | null = null
	viewNeighborhood: boolean = false

	selectedCuisineLocal: Cuisine | undefined
	selectedCuisineGlobal = signal<Cuisine | undefined>(undefined)

	newRestaurantLocal = signal(false)
	newRestaurantGlobal = signal(false)

	count = computed(() => {
		const hasCuisine = this.selectedCuisineGlobal() !== undefined ? 1 : 0
		const hasNewRestaurant = this.newRestaurantGlobal() === true ? 1 : 0
		return hasCuisine + hasNewRestaurant
	})
	private searchAddressChanged = new Subject<string>()
	filteredAddresses$ = this.searchAddressChanged.pipe(
		startWith(''), // Garante que todos os endereços sejam exibidos inicialmente
		distinctUntilChanged(),
		switchMap((searchCity: string) => {
			if (!searchCity.trim()) {
				return this.addresses$ // sem filtro se a pesquisa estiver vazia
			} else {
				return this.addresses$.pipe(
					map(addresses =>
						addresses.filter(address =>
							removeAccents(address.city)
								.toLowerCase()
								.includes(removeAccents(searchCity).toLowerCase())
						)
					)
				)
			}
		})
	)

	@ViewChild('modalBottomCuisine')
	modalBottomCuisine!: ModalBottomUiComponent

	@ViewChild('modalBottomAddress')
	modalBottomAddress!: ModalBottomUiComponent

	@ViewChild('modalCenterCuisine')
	modalCenterCuisine!: ModalCenterComponent

	@ViewChild('modalCenterAddress')
	modalCenterAddress!: ModalCenterComponent

	statusAddress$ = this.store.select(getStatusAddress)
	isMobile = isMobile()

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.isMobile = isMobile()
	}

	statusOpen = false
	openInput() {
		this.statusOpen = true
		if (this.inputSearch) this.inputSearch.nativeElement.focus()
	}
	closeInput() {
		this.searchName = ''
		this.statusOpen = false
		this.onInputSearchChange()
	}

	@ViewChild('inputSearch')
	inputSearch: ElementRef | undefined

	constructor() {
		this.store
			.select(getCuisines)
			.pipe(takeUntilDestroyed(), filter(Boolean))
			.subscribe({
				next: cuisines => {
					this.cuisines = cuisines.map(cuisine => ({ ...cuisine, selected: false }))
				},
			})

		this.searchNameChanged
			.pipe(
				distinctUntilChanged(),
				takeUntilDestroyed(),
				filter(name => name.length === 0)
			)
			.subscribe(() => {
				this.store.dispatch(VenueActions.cleanFilterVenuesByName())
			})

		this.searchNameChanged
			.pipe(
				debounceTime(500),
				distinctUntilChanged(),
				takeUntilDestroyed(),
				filter(name => name.length >= 3)
			)
			.subscribe(name => {
				this.store.dispatch(VenueActions.filterVenuesByNameV2({ name: name }))
			})

		this.store
			.select(getVenueName)
			.pipe(take(1))
			.subscribe({
				next: name => {
					if (name) {
						this.searchName = name
						this.openInput()
					}
				},
			})

		// sync global states
		this.store
			.select(getSelectedCuisines)
			.pipe(
				tap(cuisines => {
					if (cuisines[0].name !== 'Tipo de Culinária') {
						this.selectedCuisineGlobal.set(cuisines[0])
					} else {
						this.selectedCuisineGlobal.set(undefined)
					}
				}),
				takeUntilDestroyed()
			)
			.subscribe()
		this.store
			.select(getNewRestaurant)
			.pipe(
				tap(date => {
					this.newRestaurantGlobal.set(Boolean(date))
				}),
				takeUntilDestroyed()
			)
			.subscribe()
	}

	onInputSearchChange() {
		this.searchNameChanged.emit(this.searchName)
	}

	onInputAddressChange() {
		this.searchAddressChanged.next(this.searchCity)
	}

	setCurrentNeighborhoodAndAddress(neighborhood: string | undefined, address: Address | null) {
		this.store.dispatch(AddressActions.setCurrentNeighborhood({ neighborhood }))
		if (address) this.store.dispatch(AddressActions.setCurrentAddress({ address }))
		this.store.dispatch(VenueActions.applyFilter())
		this.closeModalAddress()
	}

	ngOnInit(): void {
		this.store.dispatch(CuisineActions.getCuisines())
	}

	toggleCuisine(cuisine: Cuisine) {
		if (this.selectedCuisineLocal === cuisine) {
			this.selectedCuisineLocal = undefined
			return
		}
		this.selectedCuisineLocal = cuisine
	}

	applyFilter() {
		if (this.selectedCuisineLocal) {
			this.store.dispatch(CuisineActions.setCurrentCuisine({ cuisines: [this.selectedCuisineLocal] }))
		} else {
			this.store.dispatch(CuisineActions.cleanCuisines())
		}
		if (this.newRestaurantLocal()) {
			this.store.dispatch(VenueActions.setNewRestaurant({ newRestaurant: this.newRestaurantLocal() }))
		} else {
			this.store.dispatch(VenueActions.cleanNewRestaurant())
		}
		this.store.dispatch(VenueActions.applyFilter())
		this.closeModalCuisine()
	}

	cleanFilter() {
		this.selectedCuisineLocal = undefined
		this.store.dispatch(VenueActions.cleanFilter())
		this.store.dispatch(VenueActions.applyFilter())
	}

	cleanFilterCuisines() {
		this.selectedCuisineLocal = undefined
		this.store.dispatch(CuisineActions.cleanCuisines())
		this.store.dispatch(VenueActions.applyFilter())
		this.closeModalCuisine()
	}

	cleanNewRestaurant() {
		this.newRestaurantLocal.set(false)
		this.store.dispatch(VenueActions.cleanNewRestaurant())
		this.store.dispatch(VenueActions.applyFilter())
		this.closeModalCuisine()
	}

	cleanFilterAdress() {
		this.store.dispatch(AddressActions.cleanAddress())
		this.store.dispatch(VenueActions.applyFilter())
	}

	tryLoadingAddressAgain() {
		this.store.dispatch(AddressActions.loadAddresses())
	}

	openModalAddress() {
		this.store.dispatch(AddressActions.getAddresses())
		if (this.isMobile) {
			this.modalBottomAddress.openModal()
		} else {
			this.modalCenterAddress.openModal()
		}
	}

	closeModalAddress() {
		if (this.isMobile) {
			this.modalBottomAddress.closeModal()
		} else {
			this.modalCenterAddress.closeModal()
		}
	}

	openModalCuisine() {
		// sync local states
		this.store
			.select(getNewRestaurant)
			.pipe(
				tap(newRestaurant => {
					this.newRestaurantLocal.set(Boolean(newRestaurant))
				}),
				take(1)
			)
			.subscribe()

		this.store
			.select(getSelectedCuisines)
			.pipe(
				tap(cuisines => {
					if (cuisines[0].name !== 'Tipo de Culinária') {
						this.selectedCuisineLocal = cuisines[0]
					} else {
						this.selectedCuisineLocal = undefined
					}
				}),
				take(1)
			)
			.subscribe()

		if (this.isMobile) {
			this.modalBottomCuisine.openModal()
		} else {
			this.modalCenterCuisine.openModal()
		}
	}

	closeModalCuisine() {
		if (this.isMobile) {
			this.modalBottomCuisine.closeModal()
		} else {
			this.modalCenterCuisine.closeModal()
		}
	}

	openNeighborhood(i: number) {
		if (this.openIndex === i) {
			this.openIndex = null
			this.viewNeighborhood = false
		} else {
			this.openIndex = i
			this.viewNeighborhood = true
		}
	}
}
