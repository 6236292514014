import { HttpInterceptorFn } from '@angular/common/http'
import { inject } from '@angular/core'
import { AuthService } from '../auth/auth.service'
import { PartnerToken } from '@monorepo-channels/shared/util-helpers'

export const clientPartnerInterceptor: HttpInterceptorFn = (req, next) => {
	const authService = inject(AuthService)
	const partnerId = inject(PartnerToken)
	if (req.url.startsWith('https://viacep')) {
		return next(req)
	}
	const clientId = authService.getClientId()
	req = req.clone({ setHeaders: { partnerId: partnerId, ...(clientId ? { clientId } : {}) } })
	return next(req)
}
