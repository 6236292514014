import { Component, HostListener, inject, Type, ViewChild } from '@angular/core'
import { CommonModule } from '@angular/common'

import {
	ClientActions,
	getClient,
	getErrorRegisterCard,
	getHasPaymentReady,
	getOrders,
	getOrdersToPay,
	isLoggedIn,
	OrderHistory,
	OrderHistoryActions,
} from '@monorepo-channels/channels/domain'

import { Store } from '@ngrx/store'
import { Router } from '@angular/router'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { from, map, of, tap } from 'rxjs'
import { isMobile } from '@monorepo-channels/shared/util-helpers'
import {
	ButtonUiComponent,
	FooterUiComponent,
	ModalBBService,
	ModalBottomUiComponent,
	ModalCenterComponent,
} from '@monorepo-channels/components/ui-bb'
import { WalletBodyComponent } from '../pages/wallet/wallet-body/wallet-body.component'
import { OrdersHistoryBodyComponent } from '../pages/orders-history/order-history-body/orders-history-body.component'
import { NewCardBodyComponent } from '../pages/wallet/new-card/new-card-body/new-card-body.component'
import { Actions, ofType } from '@ngrx/effects'
import { PaymentBodyComponent } from '../pages/payment/payment-body/payment-body.page'
import { CompleteRegistrationBodyComponent } from '../pages/complete-registration/complete-registration-body/complete-registration-body.component'
import { OrderDetailsBodyComponent } from '../pages/orders-history/order-details/order-history-body/order-details-body.component'
import { UserSVGComponent } from '@monorepo-channels/components/ui-common'
import { SuggestARestaurantBodyComponent } from '../pages/menu/suggest-a-restaurant/suggest--a-restaurant-body/suggest-a-restaurant-body.component'

export type ChooseComponentHeaderMenu = '/como-funciona' | '/faq' | '/terms' | '/contacts' | '/'

@Component({
	selector: 'bb-header',
	standalone: true,
	imports: [
		CommonModule,
		ModalCenterComponent,
		WalletBodyComponent,
		FooterUiComponent,
		ButtonUiComponent,
		OrdersHistoryBodyComponent,
		ModalBottomUiComponent,
		NewCardBodyComponent,
		PaymentBodyComponent,
		CompleteRegistrationBodyComponent,
		OrderDetailsBodyComponent,
		UserSVGComponent,
		SuggestARestaurantBodyComponent,
	],
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
	private router = inject(Router)
	private store = inject(Store)
	client$ = this.store.select(getClient)
	modalBBService = inject(ModalBBService)
	registerCardError$ = this.store.select(getErrorRegisterCard)
	selectedOrder: OrderHistory | null = null

	private actions$ = inject(Actions)

	isMenuOpen = false
	hasPaymentReady$ = this.store.select(getHasPaymentReady)
	isLoggedIn!: boolean
	isMobile = isMobile()

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.isMobile = isMobile()
	}

	lazyComponents: Record<ChooseComponentHeaderMenu, () => Promise<Type<unknown>>> = {
		'/como-funciona': () =>
			import('../pages/menu/how-it-work/how-it-work-body/how-it-work-body.component').then(
				m => m.HowItWorkComponent
			),
		'/faq': () => import('../pages/menu/faq/faq-body/faq-body.component').then(m => m.FaqBodyComponent),
		'/terms': () =>
			import('../pages/menu/terms-of-use/terms-of-use-body/terms-of-use-body.component').then(
				m => m.TermsOfUseBodyComponent
			),
		'/contacts': () =>
			import('../pages/menu/contacts/contacts-body/contacts-body.component').then(
				m => m.ContactsBodyComponent
			),
		'/': () => import('../pages/venues-list/venues-list.page').then(m => m.VenueListPageComponent),
	}

	private loadedControlComponents = new Map<string, Type<unknown>>()
	chooseComponent: keyof typeof this.lazyComponents = '/como-funciona'

	@ViewChild('modalCenterMenu')
	modalCenterMenu!: ModalCenterComponent

	@ViewChild('modalCenterWallet')
	modalCenterWallet!: ModalCenterComponent

	@ViewChild('modalCenterOrderHistory')
	modalCenterOrderHistory!: ModalCenterComponent

	@ViewChild('modalCenterCancelOrder')
	modalCenterCancelOrder!: ModalCenterComponent

	@ViewChild('modalCenterWalletOptions')
	modalCenterWalletOptions!: ModalCenterComponent

	orders$ = this.store.select(getOrders)
	@ViewChild('modalBottomWalletOptions')
	modalBottomWalletOptions!: ModalBottomUiComponent

	@ViewChild('modalCenterSuggestRestaurant')
	modalCenterSuggestRestaurant!: ModalCenterComponent

	@ViewChild('modalCenterSuggestRestaurantSuccess')
	modalCenterSuggestRestaurantSuccess!: ModalCenterComponent

	@ViewChild('modalCenterGenericError')
	modalCenterGenericError!: ModalCenterComponent

	@ViewChild('modalCenterNewCard')
	modalCenterNewCard!: ModalCenterComponent

	@ViewChild('modalRegisterCardFailure')
	modalRegisterCardFailure!: ModalBottomUiComponent

	@ViewChild('modalCenterPayment')
	modalCenterPayment!: ModalCenterComponent

	@ViewChild('modalCenterCompleteRegistration')
	modalCenterCompleteRegistration!: ModalCenterComponent

	@ViewChild('modalCenterOrder')
	modalCenterOrder!: ModalCenterComponent

	@ViewChild('modalCenterNoCard')
	modalCenterNoCard!: ModalCenterComponent

	@ViewChild('modalBottomLogin')
	modalBottomLogin!: ModalBottomUiComponent

	@ViewChild('modalCenterLogin')
	modalCenterLogin!: ModalCenterComponent

	modalWallet = {
		title: '',
		text: '',
		buttonText: '',
		click: () => {},
	}

	ordersCount$ = this.store.select(getOrdersToPay).pipe(map(orders => orders!.length))

	openModal = {
		wallet: false,
		registerCard: false,
		orderHistory: false,
		payment: false,
		completeRegistration: false,
		suggestRestaurant: false,
		order: false,
		noCard: false,
	}

	constructor() {
		this.store
			.select(isLoggedIn)
			.pipe(takeUntilDestroyed())
			.subscribe(isLoggedIn => (this.isLoggedIn = isLoggedIn))

		this.modalBBService.openModal$.pipe(takeUntilDestroyed()).subscribe(route => {
			this.openModalDynamicMenu(route)
		})

		this.modalBBService.openModalOthers$.pipe(takeUntilDestroyed()).subscribe(route => {
			if (route === 'register-card') {
				this.openModalCenterNewCard()
			}
			if (route === 'payment') {
				this.openModalCenterPayment()
			}
			if (route === 'complete-seu-cadastro') {
				this.openModalCenterCompleteRegistration()
			}
		})

		this.actions$.pipe(ofType(ClientActions.registerCardFailure), takeUntilDestroyed()).subscribe(() => {
			this.modalRegisterCardFailure.openModal()
		})

		this.actions$.pipe(ofType(ClientActions.registerCardSuccess), takeUntilDestroyed()).subscribe(() => {
			this.closeModalCenterNewCard()
			this.openModalCenterWallet()
		})
	}

	checkRoute(
		route: ChooseComponentHeaderMenu | '/carteira' | '/reservas-e-pagamento' | '/indique-um-restaurante'
	) {
		this.closeMenu()
		if (this.isMobile || route === '/') {
			this.router.navigate([route])
		} else if (route === '/carteira') {
			this.openModalCenterWallet()
		} else if (route === '/reservas-e-pagamento') {
			this.openModalCenterOrderHistory()
		} else if (route === '/indique-um-restaurante') {
			this.openModalCenterSuggestRestaurant()
		} else {
			this.openModalDynamicMenu(route)
		}
	}

	openLoginLivelo() {
		this.store.dispatch(ClientActions.loginClientLiveloGetAuthorizationCode())
	}

	handleOpenModalCenterSuggestRestaurant(result: string) {
		this.closeModalCenterSuggestRestaurant()
		if (result === 'success') {
			this.modalCenterSuggestRestaurantSuccess.openModal()
		} else {
			this.modalCenterGenericError.openModal()
		}
	}

	openModalCenterSuggestRestaurant() {
		this.modalCenterSuggestRestaurant.openModal()
		this.openModal.suggestRestaurant = true
	}

	closeModalCenterSuggestRestaurant() {
		this.modalCenterSuggestRestaurant.closeModal()
		this.openModal.suggestRestaurant = false
	}

	openModalLogin() {
		if (this.isMobile) {
			this.modalBottomLogin.openModal()
		} else {
			this.modalCenterLogin.openModal()
		}
	}

	openModalCenterPayment() {
		this.modalCenterPayment.openModal()
		this.openModal.payment = true
	}

	closeModalCenterPayment() {
		this.modalCenterPayment.closeModal()
		this.openModal.payment = false
	}

	openModalCenterNewCard() {
		this.modalCenterNewCard.openModal()
		this.openModal.registerCard = true
	}

	closeModalCenterNewCard() {
		this.modalCenterNewCard.closeModal()
		this.openModal.registerCard = false
	}

	openModalCenterOrderHistory() {
		this.modalCenterOrderHistory.openModal()
		this.openModal.orderHistory = true
	}

	closeOrderHistory() {
		this.modalCenterOrderHistory.closeModal()
		this.openModal.orderHistory = false
	}

	openModalCenterWallet() {
		this.modalCenterWallet.openModal()
		this.openModal.wallet = true
	}

	closeModalCenterWallet() {
		this.modalCenterWallet.closeModal()
		this.openModal.wallet = false
	}

	openModalCenterCompleteRegistration() {
		this.modalCenterCompleteRegistration.openModal()
		this.openModal.completeRegistration = true
	}

	closeModalCenterCompleteRegistration() {
		this.modalCenterCompleteRegistration.closeModal()
		this.openModal.completeRegistration = false
	}
	closeOrderDesk() {
		this.modalCenterOrder.closeModal()
		this.openModalCenterOrderHistory()
	}

	openModalCenterDefaultCard(lastFourDigits: string) {
		this.modalWallet = {
			title: 'Tornar cartão padrão',
			text: `Tem certeza que deseja tornar o cartão de final ${lastFourDigits} como seu cartão padrão?`,
			buttonText: 'TORNAR PADRÃO',
			click: () => {
				this.store.dispatch(ClientActions.updateMainCard({ lastFourDigits }))
				if (!this.isMobile) {
					this.modalCenterWalletOptions.closeModal()
					this.openModalCenterWallet()
				} else {
					this.modalBottomWalletOptions.closeModal()
				}
			},
		}
		if (!this.isMobile) {
			this.closeModalCenterWallet()
			this.modalCenterWalletOptions.openModal()
		} else {
			this.modalBottomWalletOptions.openModal()
		}
	}

	openModalCenterDeleteCard(lastFourDigits: string) {
		this.modalWallet = {
			title: 'Deletar cartão',
			text: `Tem certeza que deseja remover o cartão de final ${lastFourDigits} da sua carteira?`,
			buttonText: 'DELETAR',
			click: () => {
				this.store.dispatch(ClientActions.removeCard({ lastFourDigits }))
				if (!this.isMobile) {
					this.modalCenterWalletOptions.closeModal()
					this.openModalCenterWallet()
				} else {
					this.modalBottomWalletOptions.closeModal()
				}
			},
		}
		if (!this.isMobile) {
			this.closeModalCenterWallet()
			this.modalCenterWalletOptions.openModal()
		} else {
			this.modalBottomWalletOptions.openModal()
		}
	}

	openMenu() {
		this.isMenuOpen = true
	}

	closeMenu() {
		this.isMenuOpen = false
	}

	resolve(route: keyof typeof this.lazyComponents) {
		if (route === '/') return
		const loadedComponent = this.loadedControlComponents.get(route)
		if (loadedComponent) return of(loadedComponent)
		return from(this.lazyComponents[route]()).pipe(
			tap(component => this.loadedControlComponents.set(route, component))
		)
	}

	openModalDynamicMenu(route: keyof typeof this.lazyComponents) {
		if (route === '/') return
		this.modalCenterMenu.openModal()
		this.chooseComponent = route
	}

	openModalCancelDesk(order: OrderHistory) {
		this.modalCenterOrderHistory.closeModal()
		this.modalCenterCancelOrder.openModal()
		this.selectedOrder = order
	}
	cancelOrder(order: OrderHistory | null) {
		if (!order) return
		this.store.dispatch(OrderHistoryActions.cancelOrder({ orderId: order.id }))
		this.modalCenterCancelOrder.closeModal()
		this.modalCenterOrderHistory.openModal()
	}

	closeCancelDesk() {
		this.modalCenterCancelOrder.closeModal()
		this.modalCenterOrderHistory.openModal()
	}

	openModalOrderDesk(order: OrderHistory) {
		this.selectedOrder = order
		this.modalCenterOrderHistory.closeModal()
		this.modalCenterOrder.openModal()
		this.openModal.order = true
	}

	openModalCancelDetailsDesk() {
		this.modalCenterOrder.closeModal()
		this.modalCenterCancelOrder.openModal()
	}

	openModalNoCardDetails() {
		this.modalCenterOrder.closeModal()
		this.modalCenterNoCard.openModal()
	}

	openModalRegisterCard() {
		this.modalCenterOrderHistory.closeModal()
		this.modalCenterNoCard.openModal()
	}

	registerCard() {
		this.modalCenterNoCard.closeModal()
		this.modalCenterNewCard.openModal()
		this.openModal.registerCard = true
	}

	openModalCenterPaymentHistory(orderId: string) {
		this.modalCenterOrderHistory.closeModal()
		this.store.dispatch(OrderHistoryActions.setSelectedOrderId({ orderId }))
		this.modalBBService.openModalOthers('payment')
	}

	openModalPaymentDetails(orderId: string) {
		this.modalCenterOrder.closeModal()
		this.store.dispatch(OrderHistoryActions.setSelectedOrderId({ orderId }))
		this.modalBBService.openModalOthers('payment')
	}

	suggestNewRestaurant() {
		const email = 'canais@tagme.com.br'
		const subject = 'Sugestão novo restaurante (BB Gastronomia)'
		window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}`
	}
}
