import { CommonModule } from '@angular/common'
import { Component, EventEmitter, HostListener, inject, Output } from '@angular/core'
import { getClient, getHasPaymentReady, getOrdersToPay } from '@monorepo-channels/channels/domain'
import { ButtonUiComponent } from '@monorepo-channels/components/ui-bb'
import { isMobile } from '@monorepo-channels/shared/util-helpers'
import { Store } from '@ngrx/store'
import { combineLatest, map } from 'rxjs'

@Component({
	standalone: true,
	selector: 'feature-bb-alert-home',
	imports: [CommonModule, ButtonUiComponent],
	templateUrl: 'alert-home.component.html',
	styleUrl: 'alert-home.component.scss',
})
export class AlertHomeFeatureComponent {
	private store = inject(Store)

	@Output() payClicked = new EventEmitter<string>()
	@Output() registerCardClicked = new EventEmitter<void>()
	vm$ = combineLatest([
		this.store.select(getOrdersToPay),
		this.store.select(getClient),
		this.store.select(getHasPaymentReady),
	]).pipe(map(([orders, client, hasPaymentReady]) => ({ orders, client, hasPaymentReady })))

	isMobile = isMobile()

	@HostListener('window:resize')
	onResize() {
		this.isMobile = isMobile()
	}

	pay(orderId: string) {
		this.payClicked.emit(orderId)
	}

	registerCard() {
		this.registerCardClicked.emit()
	}
}
