import { Component } from '@angular/core'
import { HeaderComponent } from '../../header/header.component'
import { PaymentBodyComponent } from './payment-body/payment-body.page'

@Component({
	standalone: true,
	selector: 'bb-payment-page',
	imports: [HeaderComponent, PaymentBodyComponent],
	template: `
		<bb-header></bb-header>
		<bb-payment-body />
	`,
})
export class PaymentPageComponent {
	// ngOnInit(): void {
	// 	const orderId = this.route.snapshot.paramMap.get('orderId')
	// 	if (orderId) {
	// 		this.store.dispatch(OrderHistoryActions.setSelectedOrderId({ orderId }))
	// 	}
	// }
}
