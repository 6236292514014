import { Component, Input } from '@angular/core'
import { CommonModule } from '@angular/common'

@Component({
	selector: 'ui-bb-box-info',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './box-info.component.html',
	styleUrls: ['./box-info.component.scss'],
})
export class BoxInfoUiComponent {
	@Input() info!: unknown
	@Input() title!: string
}
