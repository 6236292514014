<div class="initalMode">
	<div class="initalMode__inside">
		<p class="initalMode__inside__title">Mesa para</p>
		<p class="initalMode__inside__text" *ngIf="digit === 1">{{ digit }} pessoa</p>
		<p class="initalMode__inside__text" *ngIf="digit > 1">{{ digit }} pessoas</p>
	</div>
	<div class="number">
		<div class="number__change" (click)="decrease()">
			<i class="tagmeicon tagmeicon-menos-micro"></i>
		</div>
		<div class="number__change" (click)="increase()">
			<i class="tagmeicon tagmeicon-mais-micro" style="font-size: 24px"></i>
		</div>
	</div>
</div>
