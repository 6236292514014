import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'

@Component({
	standalone: true,
	selector: 'bb-faq-body',
	imports: [CommonModule],
	templateUrl: './faq-body.component.html',
	styleUrls: ['./faq-body.component.scss'],
})
export class FaqBodyComponent {
	shows = [false, false, false]

	showTxt(index: number) {
		if (this.shows[index] === true) {
			this.shows[index] = false
		} else {
			this.shows = this.shows.map(() => false)
			this.shows[index] = true
		}
	}
}
