import { AbstractControl } from '@angular/forms'

export function validateCPF(control: AbstractControl): { [key: string]: boolean } | null {
	const cpf = control.value.replace(/[^0-9]/g, '')
	if (cpf.length !== 11) return { invalidCpf: true }

	let sum = 0
	let rest

	if (cpf === '00000000000') return { invalidCpf: true }

	for (let i = 1; i <= 9; i++) {
		sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i)
	}

	rest = (sum * 10) % 11
	if (rest === 10 || rest === 11) rest = 0
	if (rest !== parseInt(cpf.substring(9, 10))) return { invalidCpf: true }

	sum = 0
	for (let i = 1; i <= 10; i++) {
		sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i)
	}

	rest = (sum * 10) % 11
	if (rest === 10 || rest === 11) rest = 0
	if (rest !== parseInt(cpf.substring(10, 11))) return { invalidCpf: true }

	return null
}
