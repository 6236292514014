import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core'
import { CommonModule } from '@angular/common'

import { Store } from '@ngrx/store'
import {
	OrderHistory,
	OrderHistoryActions,
	getClient,
	getOrders,
	getStatusCancelOrder,
	loadingOrderHistory,
} from '@monorepo-channels/channels/domain'
import { DivisorUiComponent } from '@monorepo-channels/components/ui-common'
import { ActivatedRoute, Router } from '@angular/router'
import { Actions, ofType } from '@ngrx/effects'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { delay, map, of, startWith, switchMap, take } from 'rxjs'
import { isMobile } from '@monorepo-channels/shared/util-helpers'
import { OrderHistoryService } from './order-history.service'
import { ButtonUiComponent, LoadingPageComponent } from '@monorepo-channels/components/ui-bb'

@Component({
	standalone: true,
	imports: [CommonModule, ButtonUiComponent, LoadingPageComponent, DivisorUiComponent],
	selector: 'bb-orders-history-body',
	templateUrl: './orders-history-body.component.html',
	styleUrls: ['./orders-history-body.component.scss'],
})
export class OrdersHistoryBodyComponent implements OnInit {
	private route = inject(ActivatedRoute)
	private router = inject(Router)
	private store = inject(Store)
	private actions$ = inject(Actions)
	private orderHistoryService = inject(OrderHistoryService)
	orderAction$ = this.orderHistoryService.orderAction$
	loadingHistory$ = this.store.select(loadingOrderHistory)
	orders$ = this.store.select(getOrders)
	client$ = this.store.select(getClient)
	cancelOrder$ = this.store.select(getStatusCancelOrder)
	isMobile = isMobile()

	@Output() openModalCancelEmitter = new EventEmitter<OrderHistory>()
	@Output() openModalCancelEmitterDesk = new EventEmitter<OrderHistory>()
	@Output() openModalOrderEmitterDesk = new EventEmitter<OrderHistory>()
	@Output() openModalRegisterCardEmitter = new EventEmitter<OrderHistory>()
	@Output() openGoToPaymentEmitter = new EventEmitter<string>()

	showCancel$ = this.actions$.pipe(
		ofType(OrderHistoryActions.cancelOrderSuccess),
		takeUntilDestroyed(),
		switchMap(() =>
			of(true).pipe(
				delay(5000),
				map(() => false),
				startWith(true)
			)
		)
	)

	constructor() {
		this.actions$
			.pipe(ofType(OrderHistoryActions.cancelOrderSuccess), takeUntilDestroyed())
			.subscribe(() => {
				this.orderHistoryService.setOrderAction('cancel', true)
			})
	}

	getStatus(order: OrderHistory) {
		if (order.wasPaid) {
			return { color: 'var(--content-positive)', text: 'Pagamento realizado' }
		}
		if (order.paymentBlocked) {
			return { color: 'var(--content-dark)', text: 'Pagamento não identificado' }
		}
		if (!order.hasPaymentConfig) {
			return { color: 'var(--content-dark)', text: 'Impossibilidade de pagamento' }
		}
		if (order.canPay) {
			return { color: 'var(--content-info)', text: 'Aguardando Pagamento' }
		}
		return { color: 'var(--content-info)', text: '' }
	}

	ngOnInit(): void {
		this.store.dispatch(OrderHistoryActions.loadOrders())
	}

	cancelOrder(order: OrderHistory | null) {
		if (!order) return
		this.store.dispatch(OrderHistoryActions.cancelOrder({ orderId: order.id }))
		//this.cancelOrderModal.closeModal()
	}

	registerCard() {
		this.router.navigate(['/carteira/cadastrar-cartao'])
	}

	goToPayment(order: OrderHistory) {
		this.client$.pipe(take(1)).subscribe(client => {
			if (!client?.selectedCategory?.isRealCard) {
				this.openModalRegisterCardEmitter.emit(order)
				//this.modalRegisterCard.openModal()
			} else {
				if (this.isMobile) {
					this.router.navigate(['/reservas-e-pagamento/pagamento', order?.id])
				} else {
					this.openGoToPaymentEmitter.emit(order.id)
				}
			}
		})
	}

	goOrderDetails(order: OrderHistory) {
		this.store.dispatch(OrderHistoryActions.setSelectedOrder({ order }))
		if (this.isMobile) {
			this.router.navigate(['reserva', order.id], { relativeTo: this.route })
		} else {
			this.openModalOrderEmitterDesk.emit(order)
		}
	}

	openModalCancel(order: OrderHistory, event: Event) {
		event.stopPropagation()

		if (this.isMobile) {
			const orderToCancel = order
			this.openModalCancelEmitter.emit(orderToCancel)
		} else {
			this.openModalCancelEmitterDesk.emit(order)
		}
	}
}
