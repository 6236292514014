<div class="loading-overlay">
	<div class="lds-spinnerAnd"></div>

	<!--<div class="lds-spinnerIos" *ngIf="device === 'ios'">
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
	</div>-->

	<div class="box">
		<p class="box__text"><ng-content></ng-content></p>
	</div>
</div>
