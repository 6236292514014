import { Component } from '@angular/core'

@Component({
	standalone: true,
	selector: 'ui-common-bb-calendar-icon',
	template: `
		<svg width="44" height="44" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g opacity="0.16">
				<path
					d="M10.0498 8.55005C9.25414 8.55005 8.49109 8.86613 7.92848 9.42872C7.36588 9.99134 7.0498 10.7544 7.0498 11.55V40.0501C7.0498 40.8457 7.36588 41.6088 7.92848 42.1713C8.49109 42.7338 9.25414 43.05 10.0498 43.05H43.0498C43.8454 43.05 44.6086 42.7338 45.1711 42.1713C45.7336 41.6088 46.0498 40.8457 46.0498 40.0501V11.55C46.0498 10.7544 45.7336 9.99134 45.1711 9.42872C44.6086 8.86613 43.8454 8.55005 43.0498 8.55005H37.0498"
					fill="#767676"
				/>
				<path
					d="M10.0498 8.55005C9.25414 8.55005 8.49109 8.86613 7.92848 9.42872C7.36588 9.99134 7.0498 10.7544 7.0498 11.55V40.0501C7.0498 40.8457 7.36588 41.6088 7.92848 42.1713C8.49109 42.7338 9.25414 43.05 10.0498 43.05H43.0498C43.8454 43.05 44.6086 42.7338 45.1711 42.1713C45.7336 41.6088 46.0498 40.8457 46.0498 40.0501V11.55C46.0498 10.7544 45.7336 9.99134 45.1711 9.42872C44.6086 8.86613 43.8454 8.55005 43.0498 8.55005H37.0498"
					stroke="#767676"
					stroke-width="2"
					stroke-linecap="square"
					stroke-linejoin="round"
				/>
				<path
					d="M7.0498 19.05H46.0498"
					stroke="#767676"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M16 3V12"
					stroke="#767676"
					stroke-width="2"
					stroke-linecap="square"
					stroke-linejoin="round"
				/>
				<path
					d="M37.0498 4.05005V13.05"
					stroke="#767676"
					stroke-width="2"
					stroke-linecap="square"
					stroke-linejoin="round"
				/>
				<path
					d="M11.7271 8.55005H36.068"
					stroke="#767676"
					stroke-width="2"
					stroke-linecap="square"
					stroke-linejoin="round"
				/>
			</g>
			<path
				d="M4.15385 11.9999C3.31738 11.9999 2.5152 12.3297 1.92374 12.9168C1.33228 13.5038 1 14.3001 1 15.1303V44.8694C1 45.6996 1.33228 46.496 1.92374 47.083C2.5152 47.6699 3.31738 47.9999 4.15385 47.9999H38.8462C39.6826 47.9999 40.4849 47.6699 41.0762 47.083C41.6676 46.496 42 45.6996 42 44.8694V15.1303C42 14.3001 41.6676 13.5038 41.0762 12.9168C40.4849 12.3297 39.6826 11.9999 38.8462 11.9999H32.5385"
				stroke="#585B65"
				stroke-width="2"
				stroke-linecap="square"
				stroke-linejoin="round"
			/>
			<path
				d="M1.1001 22.8142H41.9572"
				stroke="#585B65"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M10.5288 7.09998V16.5285"
				stroke="#585B65"
				stroke-width="2"
				stroke-linecap="square"
				stroke-linejoin="round"
			/>
			<path
				d="M32.5288 7.09998V16.5285"
				stroke="#585B65"
				stroke-width="2"
				stroke-linecap="square"
				stroke-linejoin="round"
			/>
			<path
				d="M6 11.9999L29 11.9999"
				stroke="#585B65"
				stroke-width="2"
				stroke-linecap="square"
				stroke-linejoin="round"
			/>
			<rect x="28" width="20" height="20" rx="10" fill="#4969E6" />
			<path d="M33 9.99996L36.3333 13.3333L43 6.66663" fill="#4969E6" />
			<path
				d="M33 9.99996L36.3333 13.3333L43 6.66663"
				stroke="white"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	`,
	styles: `svg {
        position: relative;
    }`,
})
export class CalendarIconSVGComponent {}
