import { Component, HostBinding, Input } from '@angular/core'
import { CommonModule } from '@angular/common'

@Component({
	selector: 'ui-common-divisor',
	standalone: true,
	imports: [CommonModule],
	template: `<hr class="divisor" />`,
	styles: [
		`
			.divisor {
				margin-top: var(--top);
				margin-bottom: var(--bottom);
				border: 1px solid var(--content-divider);
				border-bottom: transparent;
			}
		`,
	],
})
export class DivisorUiComponent {
	@Input() top = '0'
	@Input() bottom = '0'

	@HostBinding('style')
	get addProps() {
		return {
			'--top': this.top,
			'--bottom': this.bottom,
		}
	}
}
