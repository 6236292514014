@if (vm$ | async; as vm) {
	<ng-container *ngIf="vm.errorReservation as error">
		<ng-container *ngIf="error.includes('permitido fazer') && showAlert">
			<ui-bb-alert-top type="secondary" icon="fa fa-info-filled">
				Não é possível realizar mais de uma reserva por dia no mesmo restaurante.
			</ui-bb-alert-top>
		</ng-container>
	</ng-container>

	<div
		class="page px-16"
		[style]="isMobile ? 'padding-top:0' : 'padding-top:16px'"
		*ngIf="vm.statusConfirmReservation !== 'success'"
	>
		<h1 class="title">Reserva de mesa</h1>
		<h2 class="subTitle" *ngIf="vm.venueDetail">{{ vm.venueDetail.name }}</h2>
		<ui-bb-box-message template="reservationInfo" />
	</div>

	<div
		*ngIf="vm.isLoadingConfirmReservation; else notLoading"
		[style]="isMobile ? 'height: calc(86vh - 253px)' : 'height: 38vh'"
	>
		<ui-bb-loading-page>Realizando reserva...</ui-bb-loading-page>
	</div>

	<ng-template #notLoading>
		<div
			[ngClass]="{
				modalReservation: !isMobile,
				modalReservationSuccess: vm.statusConfirmReservation === 'success'
			}"
		>
			<div [ngClass]="{ modalReservationInside: !isMobile }">
				<div class="page px-16">
					<div
						*ngIf="vm.statusAvailabilities === 'pending'"
						[style]="isMobile ? 'height: calc(86vh - 253px)' : 'height: 38vh'"
					>
						<ui-bb-loading-page>Carregando a disponibilidade</ui-bb-loading-page>
					</div>

					<ng-container
						*ngIf="
							vm.statusAvailabilities === 'success' && vm.statusConfirmReservation !== 'success'
						"
					>
						<div class="info">
							<ui-bb-calendar
								*ngIf="vm.availabilitiesDates as availabilities"
								[availabilitiesDates]="availabilities"
								(daySelected)="setReservationDay($event.dateString)"
								[selectedDate]="vm.selectedDate"
							></ui-bb-calendar>
						</div>

						<ng-container *ngIf="vm.sections as sections">
							<div class="info" style="margin-top: 16px">
								<ui-bb-select-environment
									[sections]="sections"
									[selectedItem]="vm.selectedSection"
									(valueChange)="setSection($event)"
								>
								</ui-bb-select-environment>
							</div>
						</ng-container>

						<ng-container *ngIf="vm.maxMinPartysize as maxMinPartysize">
							<div class="info" style="margin-top: 16px">
								<ui-bb-counter
									[digit]="vm.confirmReservation.partySize ?? 1"
									[maxDigit]="maxMinPartysize.max"
									[minDigit]="maxMinPartysize.min"
									(counter)="setPartysize($event)"
								>
								</ui-bb-counter>
							</div>
						</ng-container>

						<ng-container *ngIf="vm.reservationTimes as reservationTimes">
							<div class="info">
								<h4 class="info__subTitle">
									Exibindo os horários disponíveis entre
									{{ reservationTimes[0].reservationTime }} e
									{{ reservationTimes[reservationTimes.length - 1].reservationTime }}
								</h4>
								<div class="boxBtn">
									<ui-bb-button
										[type]="
											vm.selectedReservationTime === reservationTime.reservationTime
												? 'quaternary'
												: 'tertiary'
										"
										style="width: 160px; box-sizing: border-box"
										*ngFor="let reservationTime of reservationTimes"
										(clickButton)="setReservationTime(reservationTime.reservationTime)"
									>
										{{ reservationTime.reservationTime }}
									</ui-bb-button>
								</div>
							</div>
						</ng-container>
					</ng-container>
					<ng-container *ngIf="vm.statusConfirmReservation === 'success'">
						<ui-bb-order-success
							*ngIf="
								vm.venueDetail &&
								vm.confirmReservation.partySize &&
								vm.confirmReservation.reservationTime &&
								vm.confirmReservation.reservationDay &&
								vm.confirmReservation.sectionLabel
							"
							type="reservation"
							[partySize]="vm.confirmReservation.partySize"
							[reservationTime]="vm.confirmReservation.reservationTime"
							[sectionLabel]="vm.confirmReservation.sectionLabel"
							[orderDate]="vm.confirmReservation.reservationDay"
							[venue]="vm.venueDetail"
						/>
					</ng-container>
				</div>

				<div *ngIf="isMobile" style="height: 100px"></div>
				<div class="boxTerms temp-mobile-w" *ngIf="!vm.client?.agreements?.eula" style="left: 0">
					<ui-bb-terms-and-conditions
						(valueChange)="acceptTerms($event)"
					></ui-bb-terms-and-conditions>
				</div>
			</div>

			<div>
				<ng-container
					*ngIf="
						vm.statusConfirmReservation !== 'pending' && vm.statusConfirmReservation !== 'success'
					"
				>
					<ui-bb-footer [position]="isMobile ? 'fixed' : 'static'">
						<ui-bb-button
							type="secondary"
							[disabled]="!vm.selectedReservationTime || !eula"
							style="width: 344px"
							(clickButton)="openReservation()"
							footer
						>
							Confirmar reserva
						</ui-bb-button>
					</ui-bb-footer>
				</ng-container>

				<ng-container *ngIf="vm.statusConfirmReservation === 'success'">
					<ui-bb-footer [position]="isMobile ? 'fixed' : 'static'">
						<!--<ui-bb-button
							*ngIf="vm.client?.selectedCategory?.isRealCard"
							type="secondary-ghost"
							style="width: 100%"
							(clickButton)="router.navigate(['/reservas-e-pagamento'])"
						>
							VER MINHAS RESERVAS 
						</ui-bb-button>-->
						<div class="page px-16" style="display: flex; gap: 19px">
							<ui-bb-button
								*ngIf="vm.client?.selectedCategory?.isRealCard"
								type="secondary-ghost"
								style="width: 100%"
								(clickButton)="router.navigate(['/reservas-e-pagamento'])"
							>
								VER MINHAS RESERVAS
							</ui-bb-button>
							<ng-container *ngIf="!vm.client?.selectedCategory?.isRealCard">
								<ui-bb-button
									type="secondary"
									style="width: 100%"
									(clickButton)="router.navigate(['/carteira/cadastrar-cartao'])"
								>
									CADASTRAR CARTÃO
								</ui-bb-button>
								<ui-bb-button
									type="secondary-ghost"
									style="width: 100%"
									(clickButton)="router.navigate([''])"
								>
									MAIS TARDE
								</ui-bb-button>
							</ng-container>
						</div>
					</ui-bb-footer>
				</ng-container>
			</div>
		</div>
	</ng-template>
}
