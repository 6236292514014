import { Component, EventEmitter, Input, Output, booleanAttribute } from '@angular/core'
import { CommonModule } from '@angular/common'

@Component({
	selector: 'ui-bb-button',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss'],
})
export class ButtonUiComponent {
	@Input({ required: true }) type:
		| 'primary'
		| 'secondary'
		| 'secondary-ghost'
		| 'tertiary'
		| 'quaternary'
		| 'danger' = 'primary'
	@Input({ transform: booleanAttribute }) disabled = false
	@Input({ transform: booleanAttribute }) styleDisabled = false
	@Input({ transform: booleanAttribute }) rounded = false
	@Input({ transform: booleanAttribute }) selected = false
	@Output() clickButton = new EventEmitter<void>()

	click(event: Event) {
		event.stopPropagation()
		this.clickButton.emit()
	}
}
