<ui-bb-alert-top type="secondary" icon="fa fa-success-filled" *ngIf="mainCardSuccess$ | async">
	Cartão padrão alterado com sucesso.
</ui-bb-alert-top>
<ui-bb-alert-top type="danger" icon="fa fa-close-round" *ngIf="maincardFailure$ | async">
	Não foi possível alterar o cartão padrão.
</ui-bb-alert-top>
<ui-bb-alert-top type="secondary" icon="fa fa-success-filled" *ngIf="removeCardSuccess$ | async">
	Cartão excluído com sucesso.
</ui-bb-alert-top>
<ui-bb-alert-top type="danger" icon="fa fa-close-round" *ngIf="removeCardFailure$ | async">
	Não foi possível excluir o cartão.
</ui-bb-alert-top>
@if (client$ | async; as client) {
	<article class="page px-16">
		<ui-bb-title type="secondary" [styles]="{ paddingTop: '12px' }">Carteira</ui-bb-title>
	</article>
	<ui-bb-box-message template="accumulatePoints" *ngIf="client.cards.length === 0" />
	<article class="page px-16">
		<ul class="card-list">
			<li *ngFor="let card of client.cards">
				<ul class="icon-container">
					<li class="icon-item" (click)="clickModalDefault(card.lastFourDigits)">
						<i class="fa fa-star-void"></i>
						<span class="icon-text"> Padrão </span>
					</li>
					<li class="icon-item" (click)="clickModalDelete(card.lastFourDigits)">
						<i class="fa fa-trash"></i>
						<span class="icon-text"> Excluir </span>
					</li>
				</ul>
				<div class="card-list__moving" #cardElement (click)="cardElement.classList.toggle('click')">
					<ui-common-bb-background-card />
					<i class="fa fa-combined-shape"></i>
					<p class="lastFourDigits">{{ card.lastFourDigits }}</p>
					<img
						class="brand"
						src="/assets/images/{{ card.imageBrand?.src }}"
						[alt]="card.imageBrand?.alt"
					/>
					<p class="default" *ngIf="card.mainCard">Padrão</p>
				</div>
			</li>
		</ul>

		<div class="no-cards" *ngIf="client.cards.length === 0">
			<ui-common-bb-wallet-svg />
			<p class="no-cards__text">NENHUM CARTÃO ADICIONADO</p>
		</div>

		<div style="height: 16px"></div>
		<p class="new-card" (click)="registerCard()">+ ADICIONAR CARTÃO</p>
	</article>
}
