import { OperationHour } from '../venues/venues-details.interface'

export class OperationHours {
	/* eslint-disable  @typescript-eslint/no-explicit-any */
	dayTranslation: any = {
		'1': 'Segunda',
		'2': 'Terça',
		'3': 'Quarta',
		'4': 'Quinta',
		'5': 'Sexta',
		'6': 'Sábado',
		'7': 'Domingo',
	}
	value: { day: string; times: string }[]
	constructor(prop: OperationHour[], separator: '|' | '-') {
		/* eslint-disable  @typescript-eslint/no-explicit-any */
		const result: any = {}
		for (const item of prop.filter(o => o.day)) {
			const day = this.dayTranslation[item.day]
			const timeRange = `${item.start} às ${item.end}`
			if (result[day]) {
				result[day].push(timeRange)
			} else {
				result[day] = [timeRange]
			}
		}
		this.value = Object.keys(result).map(day => ({
			day,
			times: result[day].join(` ${separator} `),
		}))
	}
}
