import { Component } from '@angular/core'

import { ContactsBodyComponent } from './contacts-body/contacts-body.component'
import { HeaderComponent } from '../../../header/header.component'

@Component({
	standalone: true,
	selector: 'bb-contacts',
	imports: [HeaderComponent, ContactsBodyComponent],
	template: `
		<bb-header></bb-header>
		<bb-contacts-body></bb-contacts-body>
	`,
})
export class ContactsComponent {}
