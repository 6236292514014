import { Client } from '../../models/client/client'
import { Status } from '../helper.state'

export const featureClientName = 'client'

export interface ClientState {
	client: Client | null
	loginStatus: Status
	registerCardStatus: Status
	mainCardStatus: Status
	removeCardStatus: Status
	errorLoginStatus: string | null
	errorRegisterCard: string | null
	errorMainCard: string | null
	errorRemoveCard: string | null
	statusUpdateClientBackend: Status
	errorUpdateClientBackend: string | null
	isLoggedIn: boolean
	redirectUrl: string | null
}

export const initialState: ClientState = {
	client: null,
	isLoggedIn: false,
	loginStatus: 'idle',
	errorLoginStatus: null,
	redirectUrl: null,
	registerCardStatus: 'idle',
	errorRegisterCard: null,
	mainCardStatus: 'idle',
	removeCardStatus: 'idle',
	errorMainCard: null,
	errorRemoveCard: null,
	errorUpdateClientBackend: null,
	statusUpdateClientBackend: 'idle',
}
