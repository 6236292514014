import { Injectable, inject } from '@angular/core'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { AppState } from '../helper.state'
import { Store } from '@ngrx/store'
import { OrderHistoryActions } from './order-history.actions'
import { catchError, filter, map, of, switchMap } from 'rxjs'
import { getClient } from '../client/client.selectors'
import { OrderGateway } from '../../gateways/order.gateway'
import { ReservationActions } from '../reservation/reservation.actions'
import { CheckinActions } from '../checkin/checkin.actions'
import { PaymentActions } from '../payment/payment.actions'
import { PartnersId, PartnerToken } from '@monorepo-channels/shared/util-helpers'

@Injectable()
export class OrderHistoryEffects {
	private actions$ = inject(Actions)
	private orderGateway = inject(OrderGateway)
	private store = inject(Store<AppState>)
	private partnerId = inject(PartnerToken)

	loadOrders$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				OrderHistoryActions.loadOrders,
				ReservationActions.confirmReservationSuccess,
				OrderHistoryActions.cancelOrderSuccess
			),
			concatLatestFrom(() => this.store.select(getClient)),
			switchMap(([, client]) =>
				this.orderGateway.getHistory({ clientId: client!.id }).pipe(
					map(orders => OrderHistoryActions.loadOrdersSuccess({ orders })),
					catchError(error => {
						return of(OrderHistoryActions.loadOrdersFailure({ error: error?.error?.message }))
					})
				)
			)
		)
	})

	loadOrdersWhenPaymentIsReady$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(OrderHistoryActions.getOrdersPaymentReadySuccess),
			filter(action => action.hasPaymentReady),
			map(() => OrderHistoryActions.loadOrders())
		)
	})

	cancelOrder$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(OrderHistoryActions.cancelOrder),
			switchMap(action =>
				this.orderGateway.cancelOrder(action.orderId).pipe(
					map(() => OrderHistoryActions.cancelOrderSuccess({ orderId: action.orderId })),
					catchError(error =>
						of(OrderHistoryActions.cancelOrderFailure({ error: error?.error?.message }))
					)
				)
			)
		)
	})

	// selected order after cancel on ProgramaMenu
	sequenceEffect$ = createEffect(() =>
		this.actions$.pipe(
			filter(() => this.partnerId === PartnersId.BRADESCO),
			ofType(OrderHistoryActions.cancelOrderSuccess),
			switchMap(action =>
				this.actions$.pipe(
					ofType(OrderHistoryActions.loadOrdersSuccess),
					map(() => OrderHistoryActions.setSelectedOrderId({ orderId: action.orderId }))
				)
			)
		)
	)

	getOrdersReady$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				OrderHistoryActions.getOrdersPaymentReady,
				CheckinActions.checkInConfirmSuccess,
				PaymentActions.createPaymentSuccess
			),
			concatLatestFrom(() => [this.store.select(getClient)]),
			// Filtrar para garantir que o cliente existe e hasPayment falso antes de continuar
			filter(([, client]) => !!client),
			filter(() => this.partnerId !== PartnersId.BRADESCO),
			switchMap(([, client]) =>
				this.orderGateway.getOrdersPaymentReady(client!.id).pipe(
					map(res =>
						OrderHistoryActions.getOrdersPaymentReadySuccess({
							hasPaymentReady: res.hasPaymentReady,
						})
					),
					catchError(error => {
						return of(
							OrderHistoryActions.getOrdersPaymentReadyFailure({ error: error?.error?.message })
						)
					})
				)
			)
		)
	})
}
