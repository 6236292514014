import { Component, inject } from '@angular/core'
import { CommonModule, Location } from '@angular/common'

@Component({
	standalone: true,
	imports: [CommonModule],
	selector: 'bb-terms-of-use-body',
	templateUrl: './terms-of-use-body.component.html',
	styleUrls: ['./terms-of-use-body.component.scss'],
})
export class TermsOfUseBodyComponent {
	public location = inject(Location)
}
