<div class="page px-16">
	<p style="font-size: 24px; font-weight: 700; margin-top: 14px">Contatos</p>

	<ui-common-divisor top="16px" bottom="16px" />

	<!-- BB Gastronomia -->
	<h2 class="bbTitle">BB Gastronomia</h2>
	<p class="description">Informações ou reclamações sobre o funcionamento do BB Gastronomia</p>
	<h2 class="title"><strong>Telefones:</strong> 08h às 22h todos os dias</h2>
	<p class="txt">
		<mat-icon svgIcon="bb-icons:call"></mat-icon>
		<a href="tel:+551130048858" class="txt-link">(11) 3004-8858</a> -
		<span class="txt--500">Capitais e regiões metropolitanas</span>
	</p>
	<p class="txt">
		<mat-icon svgIcon="bb-icons:call"></mat-icon>
		<a href="tel:08007578858" class="txt-link">0800-757-8858</a> -
		<span class="txt--500">Demais regiões</span>
	</p>
	<p class="txt">
		<mat-icon svgIcon="bb-icons:whatsapp"></mat-icon>
		<a
			class="txt-link"
			target="_blank"
			href="https://api.whatsapp.com/send/?phone=551130048858&text&type=phone_number&app_absent=0"
		>
			(11) 3004-8858</a
		>
		- <span class="txt--500">Whatsapp</span>
	</p>
	<ui-common-divisor top="16px" bottom="16px" />

	<!-- Livelo -->
	<h2 class="bbTitle" style="color: var(--primitive-pink)">Livelo</h2>
	<p class="description" style="color: var(--primitive-pink)">
		Problemas com seus pontos Livelo? Fale conosco:
	</p>
	<h2 class="title"><strong>Chat:</strong> Assistente virtual 24h</h2>
	<p class="txt">
		<mat-icon svgIcon="bb-icons:chat-square"></mat-icon>
		<a
			class="txt-link"
			target="_blank"
			href="https://www.livelo.com.br/"
			style="color: var(--primitive-pink)"
			>livelo.com.br</a
		>
	</p>

	<ui-common-divisor top="16px" bottom="16px" />

	<!-- Banco do Brasil -->
	<h2 class="bbTitle">Banco do Brasil</h2>
	<p class="description">Informações sobre serviços e produtos do BB:</p>
	<h2 class="title"><strong>Telefones:</strong> Todos os dias, 24h por dia</h2>
	<p class="txt">
		<mat-icon svgIcon="bb-icons:call"></mat-icon>
		<a href="tel:+5540040001" class="txt-link">4004-0001</a> -
		<span class="txt--500">Capitais e regiões metropolitanas</span>
	</p>
	<p class="txt">
		<mat-icon svgIcon="bb-icons:call"></mat-icon>
		<a href="tel:+558007290021" class="txt-link">0800-729-0021</a> -
		<span class="txt--500">Demais regiões</span>
	</p>
	<p class="txt">
		<mat-icon svgIcon="bb-icons:call"></mat-icon>
		<a href="tel:+558007290722" class="txt-link">0800-729-0722</a> -
		<span class="txt--500">SAC</span>
	</p>
	<p class="txt">
		<mat-icon svgIcon="bb-icons:whatsapp"></mat-icon>
		<a
			class="txt-link"
			target="_blank"
			href="https://api.whatsapp.com/send/?phone=5540040001&text&type=phone_number&app_absent=0"
		>
			4004-0001</a
		>
		- <span class="txt--500">Whatsapp</span>
	</p>
</div>
