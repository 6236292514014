import { Component, Input } from '@angular/core'
import { CommonModule } from '@angular/common'

@Component({
	selector: 'ui-bb-alert-box',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './alert-box.component.html',
	styleUrls: ['./alert-box.component.scss'],
})
export class AlertBoxUiComponent {
	@Input() title: string | undefined
}
