import { Component, inject, OnInit } from '@angular/core'
import { ClientActions } from '@monorepo-channels/channels/domain'
import { Store } from '@ngrx/store'

@Component({ standalone: true, imports: [], template: `` })
export class LoginBBPageComponent implements OnInit {
	store = inject(Store)
	constructor() {}

	ngOnInit(): void {
		this.store.dispatch(ClientActions.loginClientLiveloGetAuthorizationCode())
	}
}
