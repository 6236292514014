import { Injectable } from '@angular/core'
import { BehaviorSubject, timer, take } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class OrderHistoryService {
	private orderHistoryActionSubject = new BehaviorSubject<{ type: 'cancel'; value: boolean }>({
		type: 'cancel',
		value: false,
	})
	public orderAction$ = this.orderHistoryActionSubject.asObservable()

	setOrderAction(type: 'cancel', value: boolean) {
		this.orderHistoryActionSubject.next({ type, value })
		this.resetAfterDelay(type)
	}

	private resetAfterDelay(type: 'cancel') {
		timer(5000)
			.pipe(take(1))
			.subscribe(() => {
				this.orderHistoryActionSubject.next({ type, value: false })
			})
	}
}
