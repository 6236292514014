import { capitalizeEachWord } from '@monorepo-channels/shared/util-helpers'
import { Category } from '../vo/category'
import { Agreements, ClientProps } from './client.interface'
interface ClientConfig {
	id: string
	firstName: string
	fullName: string
	email: string
	cpf: string
	phone: string | null
	token: string
	selectedCategory: Category | null
	cards: Category[]
	categories: Category[]
	agreements: Agreements
	favorites: string[]
	selectedCardDetails: Category | null
}

export class Client {
	private constructor(private config: ClientConfig) {}

	static create(props: ClientProps): Client {
		const cards = props.categories.filter(category => category?.gatewayId)
		const mainCard = cards.find(card => card.mainCard)
		const selectedCard = mainCard ?? cards[0] ?? props.categories[0]
		const selectedCategory = selectedCard ? new Category(selectedCard) : null
		const config: ClientConfig = {
			id: props._id,
			firstName: props.name,
			fullName: capitalizeEachWord(`${props.name} ${props.lastName}`),
			email: props.email,
			cpf: props.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'),
			phone: props?.phone ?? null,
			token: props?.access_token?.token,
			selectedCategory: selectedCategory,
			cards: props.categories.filter(category => category?.gatewayId).map(card => new Category(card)),
			categories: props.categories.map(card => new Category(card)),
			agreements: props.agreements,
			favorites: props.favorites ?? [],
			selectedCardDetails: null,
		}
		return new Client(config)
	}

	/*
		Why: This method is used to select a card when partner doesn't have difference between categoryId inside cards.
		Like BB Gastronomia, Esfera, Latam, Unicred.
	*/
	selectCard(gatewayId: string, lastFourDigits: string): Client {
		const selectedCard = this.config.cards.find(
			card => card.gatewayId === gatewayId && card.lastFourDigits === lastFourDigits
		)
		if (!selectedCard) return this
		return new Client({ ...this.config, selectedCategory: selectedCard })
	}

	/*
		Why: For Programa Menu. We'd like to select automatically the card which has the most benefits.
	*/
	selectTheCardThatHasTheMostBenefits() {
		if (this.config.categories.length === 0) return this
		const selectedCard = this.config.categories.reduce((prev, current) => {
			const prevBenefits = (prev.benefits as []).length
			const currentBenefits = (current.benefits as []).length
			return prevBenefits >= currentBenefits ? prev : current
		})
		return new Client({ ...this.config, selectedCategory: selectedCard })
	}

	/*
		Why: This method is used to select a card when partner has difference between categoryId inside cards.
		Like Programa Menu (Bradesco). Each card should have a different categoryId.
	*/
	selectCategory(categoryId: string): Client {
		const selectedCategory = this.config.categories.find(category => category.categoryId === categoryId)
		if (!selectedCategory) return this
		return new Client({ ...this.config, selectedCategory })
	}

	toggleFavorite(venueId: string): Client {
		const favorites = this.config.favorites.includes(venueId)
			? this.config.favorites.filter(fav => fav !== venueId)
			: [...this.config.favorites, venueId]
		return new Client({ ...this.config, favorites })
	}

	isVenueFavorite(venueId: string): boolean {
		return this.config.favorites.includes(venueId)
	}

	get id(): string {
		return this.config.id
	}
	get firstName(): string {
		return this.config.firstName
	}
	get fullName(): string {
		return this.config.fullName
	}
	get email(): string {
		return this.config.email
	}
	get cpf(): string {
		return this.config.cpf
	}
	get phone(): string | null {
		return this.config.phone
	}
	get token(): string {
		return this.config.token
	}
	get selectedCategory(): Category | null {
		return this.config.selectedCategory
	}
	get cards(): Category[] {
		return this.config.cards
	}
	get categories(): Category[] {
		return this.config.categories
	}
	get agreements(): Agreements {
		return this.config.agreements
	}
	get favorites(): string[] {
		return this.config.favorites
	}
	get selectedCardDetails(): Category | null {
		return this.config.selectedCardDetails
	}
}
