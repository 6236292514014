import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { OrderHistory } from '../../models/order-history/order-history'

export const OrderHistoryActions = createActionGroup({
	source: 'Order History Page',
	events: {
		'Load Orders': emptyProps(),
		'Load Orders Success': props<{ orders: OrderHistory[] }>(),
		'Load Orders Failure': props<{ error: string }>(),
		'Cancel Order': props<{ orderId: string }>(),
		'Cancel Order Success': props<{ orderId: string }>(),
		'Cancel Order Failure': props<{ error: string }>(),
		'Set Selected Order': props<{ order: OrderHistory }>(),
		'Set Selected OrderId': props<{ orderId: string }>(),
		'Get Orders Payment Ready': emptyProps(),
		'Get Orders Payment Ready Success': props<{ hasPaymentReady: boolean }>(),
		'Get Orders Payment Ready Failure': props<{ error: string }>(),
	},
})
