<article class="card" (click)="clickCard.emit()">
	<div class="card__img">
		<img [src]="venue.coverURL.value" width="343" height="150" />
	</div>
	<div class="card__info">
		<div class="card__info__venue">
			<h1 class="card__info__title">{{ venue.name }}</h1>
			<p class="card__info__address">
				{{ venue.address.value }}
			</p>
		</div>
		<div class="card__info__tags">
			<ui-bb-tag *ngFor="let cuisine of venue.cuisines.slice(0, 2)">{{ cuisine }}</ui-bb-tag>
		</div>
		<ui-bb-benefits [benefits]="venue.benefits" style="padding: 0" />

		<!--<button class="card__info__btn">Conhecer restaurante</button>-->
	</div>
</article>
