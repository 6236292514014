import { Address } from '../vo/address'
import { Benefits } from '../benefits/benefits'
import { ORDER_PAYMENT_STATUS, OrderFromHistoryProps } from './order-history.interface'
import { Observable, interval, map, startWith } from 'rxjs'
import { CoverURL } from '../vo/coverURL'
import { GoogleMapsLink, LivemenuLink } from '../vo/links'

interface OrderHistoryConfig {
	id: string
	intent: string
	originalIntent: string
	venue: {
		name: string
		address: Address
		id: string
		banners: CoverURL[]
		thumbs: CoverURL[]
		googleMaps: GoogleMapsLink
		liveMenu: LivemenuLink
	}
	date: { day: string; month: string; time: string; date: Date }
	reservationDateTime: number
	hasPaymentConfig: boolean
	wasPaid: boolean
	canPay: boolean
	paymentBlocked: boolean
	benefits?: Benefits[]
	voucher?: { value: string }
	gratuityPercent: number
	canCancel$: Observable<boolean>
	active: boolean
	points?: number
	payment?: {
		points: number
		lastFourDigits: string
		code: string
		intialValue: number
		gratuityValue: number
		totalValue: number
	}
	partySize?: number
	sectionLabel?: string
	removeGratuityValue?: boolean
}

export class OrderHistory {
	private constructor(private config: OrderHistoryConfig) {}

	static create(props: OrderFromHistoryProps): OrderHistory {
		const location = props.venue.address.location
		const config: OrderHistoryConfig = {
			id: props._id,
			intent: this.getIntent(props.intent),
			originalIntent: props.intent,
			active:
				(props.status === 'CHK_DONE' || props.status === 'VALIDATED') &&
				(props.paymentStatus === 'READY' ||
					props.paymentStatus === 'NOT_DONE_FAILED' ||
					props.paymentStatus == null),

			// maps mobile and desk
			venue: {
				name: props.venue.name,
				address: new Address(
					props.venue.address.city ?? '',
					props.venue.address.state ?? '',
					props.venue.address.neighborhood ?? '',
					props.venue.address.address ?? '',
					props.venue.address.number ?? ''
				),
				id: props.venueId,
				banners: props.venue.partner.images.banners.map(url => new CoverURL(url.coverUrl, undefined)),
				thumbs: props.venue.partner.images.thumb.map(url => new CoverURL(url.coverUrl, undefined)),
				googleMaps: new GoogleMapsLink(location[0], location[1]),
				liveMenu: new LivemenuLink(props.venueId, undefined),
			},
			date: this.getDayAndMonth(new Date(props.reservationDay), props.reservationTime),
			reservationDateTime: this.getReservationDateTime(props.reservationDay, props.reservationTime),
			hasPaymentConfig: props.venueHasPaymentConfig ?? false,
			wasPaid: this.checkWasPaid(props.paymentStatus),
			canPay: this.canPayFn(props.paymentStatus, props.venueHasPaymentConfig ?? false),
			paymentBlocked: this.paymentBlockedFn(props.paymentStatus, props.venueHasPaymentConfig ?? false),
			benefits: props.benefits?.map(benefit => new Benefits(benefit)),
			gratuityPercent: props.gratuityPercent ?? 10,
			canCancel$: interval(1000).pipe(
				startWith(0),
				map(() => Date.now()),
				map(NOW => NOW <= this.getReservationDateTime(props.reservationDay, props.reservationTime)),
				map(value => !this.checkWasPaid(props.paymentStatus) && value)
			),
			points: props.payment?.orderTotalPoints,
			payment: props.payment
				? {
						points: props.payment.orderTotalPoints,
						lastFourDigits: props.payment.lastFourDigits,
						code: props.payment.code,
						intialValue: props.payment.orderInitialValue,
						gratuityValue: props.payment.gratuityValue,
						totalValue: props.payment.orderTotalValue,
				  }
				: undefined,
			partySize: props.partySize,
			sectionLabel: props.sectionLabel,
			removeGratuityValue: props.removeGratuityValue,
		}
		return new OrderHistory(config)
	}

	private static getIntent(intent: string): string {
		if (intent === 'reservation') return 'Reserva de Mesa'
		if (intent === 'checkin') return 'CheckIn'
		if (intent === 'waitlist') return 'Fila de Espera'
		return 'Order sem intent'
	}

	private static checkWasPaid(paymentStatus: ORDER_PAYMENT_STATUS): boolean {
		return paymentStatus === 'DONE'
	}

	private static canPayFn(paymentStatus: ORDER_PAYMENT_STATUS, hasPaymentConfig: boolean): boolean {
		return hasPaymentConfig && (paymentStatus === 'READY' || paymentStatus === 'FAILED')
	}

	private static paymentBlockedFn(paymentStatus: ORDER_PAYMENT_STATUS, hasPaymentConfig: boolean): boolean {
		return !hasPaymentConfig || paymentStatus === 'NOT_DONE' || paymentStatus === 'NOT_DONE_FAILED'
	}

	private static getDayAndMonth(date: Date, time: string) {
		const day = String(date.getUTCDate()).padStart(2, '0')
		const month = date.toLocaleString('pt-br', { month: 'short' }).toUpperCase().slice(0, -1)
		return { day, month, date, time }
	}

	private static getReservationDateTime(reservationDay: string, reservationTime: string): number {
		if (!reservationDay) return Infinity
		const [day] = reservationDay.split('T')
		return Date.parse(`${day}T${reservationTime}`)
	}

	get id(): string {
		return this.config.id
	}
	get intent(): string {
		return this.config.intent
	}
	get active(): boolean {
		return this.config.active
	}
	get originalIntent() {
		return this.config.originalIntent
	}
	get venue() {
		return this.config.venue
	}
	get date(): { day: string; month: string; time: string; date: Date } {
		return this.config.date
	}
	get reservationDateTime(): number {
		return this.config.reservationDateTime
	}
	get hasPaymentConfig(): boolean {
		return this.config.hasPaymentConfig
	}
	get wasPaid(): boolean {
		return this.config.wasPaid
	}
	get canPay(): boolean {
		return this.config.canPay
	}
	get paymentBlocked(): boolean {
		return this.config.paymentBlocked
	}
	get benefits(): Benefits[] | undefined {
		return this.config.benefits
	}
	get voucher(): { value: string } | undefined {
		return this.config.voucher
	}
	get gratuityPercent(): number {
		return this.config.gratuityPercent
	}
	get canCancel$(): Observable<boolean> {
		return this.config.canCancel$
	}
	get points(): number | undefined {
		return this.config.points
	}
	get payment():
		| {
				points: number
				lastFourDigits: string
				code: string
				intialValue: number
				gratuityValue: number
				totalValue: number
		  }
		| undefined {
		return this.config.payment
	}
	get partySize(): number | undefined {
		return this.config.partySize
	}
	get sectionLabel(): string | undefined {
		return this.config.sectionLabel
	}
	get removeGratuityValue(): boolean | undefined {
		return this.config.removeGratuityValue
	}
}
