import { Component, Input } from '@angular/core'
import { CommonModule } from '@angular/common'

@Component({
	selector: 'ui-bb-box-icon',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './box-icon.component.html',
	styleUrls: ['./box-icon.component.scss'],
})
export class BoxIconUiComponent {
	@Input({ required: true }) type!: 'primary' | 'secondary'
}
