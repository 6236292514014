import { Component } from '@angular/core'

@Component({
	standalone: true,
	selector: 'ui-common-bb-wallet-svg',
	template: `
		<svg width="44" height="34" viewBox="0 0 44 34" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="4" width="40" height="29" rx="4" fill="#E9E9E9" />
			<rect x="1" y="5" width="38" height="28" rx="3" stroke="#585B65" stroke-width="2" />
			<line x1="1" y1="12" x2="38" y2="12" stroke="#585B65" stroke-width="2" />
			<line x1="5" y1="19" x2="14" y2="19" stroke="#585B65" stroke-width="2" />
		</svg>
	`,
})
export class WalletSVGComponent {}
