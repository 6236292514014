import { Component, inject, Input } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Benefits } from '@monorepo-channels/channels/domain'
import { CampaignService } from '@monorepo-channels/shared/util-helpers'

@Component({
	selector: 'ui-bb-benefits',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './benefits.component.html',
	styleUrls: ['./benefits.component.scss'],
})
export class BenefitsUiComponent {
	@Input({ required: true }) benefits: Benefits[] | undefined = []
	campaign$ = inject(CampaignService).campaign$
}
