import { Component, EventEmitter, Input, Output } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'

@Component({
	selector: 'ui-bb-radio',
	standalone: true,
	imports: [CommonModule, FormsModule],
	templateUrl: './radio.component.html',
	styleUrls: ['./radio.component.scss'],
})
export class RadioUiComponent {
	@Output() valueChange = new EventEmitter()
	@Input({ required: true }) value = ''
	@Input() textItem = ''

	valueChanged() {
		this.valueChange.emit(this.textItem)
	}
}
