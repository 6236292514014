<div *ngIf="banner === 'home'" class="banner" (click)="infoButtonClicked.emit()" #bannerEl>
	<!--<div class="box-info container-desktop">
		<div class="info">
			<img
				src="https://tagmepub.blob.core.windows.net/pubimg/Channels/BB/LogoLivelo.png"
				class="info__img"
			/>
			<p class="info__title">Ganhe pontos</p>
			<p class="info__subTitle">
				+ benefícios exclusivos<br />
				no BB Gastronomia
			</p>
			<button class="info__btn" (click)="infoButtonClicked.emit()">COMO FUNCIONA</button>
		</div>
	</div>-->
</div>

<div *ngIf="banner === 'works'" class="works">
	<div class="box-info-works">
		<p class="box-info-works__text">Ganhe pontos nos seus restaurantes preferidos</p>
	</div>
</div>
