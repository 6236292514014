export const environment = {
	production: false,
	env: 'development',
	channelsAPIURL: 'https://channelsapi-dev.tagme.com.br',
	livelo: {
		auth: 'https://acesso-uat.livelo.com.br',
		clientId: 'tagme-bbgastronomia',
		redirectUri: 'https://bb-gastronomia-dev.tagme.com.br',
	},
	giftAPIURL: 'https://giftcardtagme-dev.azurewebsites.net',
	pubImageURL: 'https://tagmepub.azureedge.net/pubimg',
	googleMapsAPIToken: 'AIzaSyA1p9RdPX2xq2MKmgI2_jCxHqbDs6hGs0E',
	liveMenu: {
		url: 'https://live-menu-staging.tagme.com.br/menu',
	},
}
