<bb-header></bb-header>
<ng-container *ngIf="(orderAction$ | async) as orderAction">
	<ui-bb-alert-top
		*ngIf="orderAction.type==='cancel' && orderAction.value"
		type="secondary"
		icon="fa fa-success-filled"
	>
		Sua reserva foi cancelada.
	</ui-bb-alert-top>
</ng-container>

<bb-orders-history-body
	(openModalCancelEmitter)="openModalCancel()"
	(openModalRegisterCardEmitter)=" openModalRegisterCard()"
/>

<ui-bb-modal-bottom
	title="Cadastre o seu cartão"
	#modalRegisterCard
	style="color: var(--primitives-grey-500)"
	height="250px"
>
	<p style="line-height: 20px; margin-top: 24px">
		Olá! Para pagar e ganhar os pontos Livelo, será necessário cadastrar um cartão de crédito Ourocard.
	</p>
	<ui-bb-footer position="static" footer style="padding: 0 16px">
		<ui-bb-button
			type="secondary"
			style="width: 100%; font-size: 12px; margin: 0 16px"
			(clickButton)="registerCard()"
		>
			CONTINUAR
		</ui-bb-button>
	</ui-bb-footer>
</ui-bb-modal-bottom>

<ng-container *ngFor="let order of orders$ | async; let last = last">
	<ui-bb-modal-bottom title="Cancelar reserva" height="auto" #cancelOrderModal>
		<div class="bodyModal">
			<p class="bodyModal__text">Tem certeza que deseja cancelar sua reserva no restaurante?</p>
			<div class="boxVenue">
				<p class="boxVenue__name">{{ order.venue.name }}</p>
				<p class="boxVenue__address">{{ order.venue.address.value }}</p>
			</div>

			<div class="info" style="margin: 4px 0 4px">
				<p class="info__title">Data</p>
				<p class="info__text">{{ order.date.date | date }}</p>
			</div>

			<div class="info" style="padding: 0">
				<p class="info__title">Horário</p>
				<p class="info__text">{{ order.date.time }}</p>
			</div>
		</div>

		<ui-bb-footer position="static">
			<ui-bb-button type="secondary" style="width: 163px" (clickButton)="cancelOrderModal.closeModal()">
				SAIR
			</ui-bb-button>
			<ui-bb-button type="primary" style="width: 163px" (clickButton)="cancelOrder(order)">
				SIM, CANCELAR
			</ui-bb-button>
		</ui-bb-footer>
	</ui-bb-modal-bottom>
</ng-container>
