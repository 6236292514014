import { Injectable, inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, delay, of, switchMap, throwError } from 'rxjs'
import { BillingAddress } from './client.gateway'
import { environment } from '@monorepo-channels/shared/util-environments'
import { PaymentSuccessResponse } from '../state/payment/payment'

@Injectable({ providedIn: 'root' })
export class PaymentsGateway {
	baseURL = `${environment.channelsAPIURL}/api`
	private http = inject(HttpClient)

	createPayment(paymentDto: CreatePaymentDto) {
		// Uncomment the scenario you want to test
		// return this.failureCreatePayment()
		// return this.successCreatePayment()
		return this.http.post<PaymentSuccessResponse>(this.baseURL + '/v2/payments', paymentDto)
	}

	private successCreatePayment(): Observable<PaymentSuccessResponse> {
		return of({
			code: '12323',
			paidAt: '2023-03-03T10:00:00',
			orderTotalValue: 300_00,
			points: 23232,
		}).pipe(delay(1000))
	}

	private failureCreatePayment() {
		return of(['1']).pipe(
			delay(2000),
			switchMap(() => throwError(() => ({ error: { message: 'hello erro' } })))
		)
	}

	getOrdersPaymentReady(clientId: string): Observable<unknown> {
		return this.http.get(`${this.baseURL}/v1/orders/payment/ready/${clientId}`)
	}
}

export interface FilterHistoryQueryDto {
	clientId?: string
	partnerId?: string
	cpf?: string
	intent?: string
	startDate?: Date
	endDate?: Date
	skip?: number
	limit?: number
}

export interface CreatePaymentDto {
	tableOrder: string
	clientId: string
	venueId: string
	gatewayId: string
	billingAddress?: BillingAddress
	value: number // Em centavos, mínimo 1000
	cpf?: string
	dynamicCVV: string
	orderId: string
}
