import { Component, EventEmitter, OnInit, Output, ViewChild, inject } from '@angular/core'
import { CommonModule } from '@angular/common'

import { BoxSuccessUiComponent, RadioUiComponent } from '@monorepo-channels/components/ui-unicred'
import {
	BackgroundCardSVGComponent,
	DivisorUiComponent,
	EditSVGComponent,
	InfoSVGComponent,
	LiveloPointsSVGComponent,
} from '@monorepo-channels/components/ui-common'
import { MatTooltipModule } from '@angular/material/tooltip'

import {
	TitleUiComponent,
	AlertTopUiComponent,
	FooterUiComponent,
	ButtonUiComponent,
	TicketUiComponent,
	ModalBottomUiComponent,
	ModalUiComponent,
	BoxIconUiComponent,
	BoxInfoUiComponent,
	GenericErrorUiComponent,
	LoadingPageComponent,
	TooltipUiComponent,
	BenefitsUiComponent,
	ModalBottomFailureComponent,
	ModalCenterComponent,
} from '@monorepo-channels/components/ui-bb'
import { ActivatedRoute, Router, RouterLink } from '@angular/router'
import {
	ClientActions,
	OrderHistoryActions,
	PaymentActions,
	getClient,
	getPaymentOrder,
	getSelectOrder,
	getStatusPayment,
} from '@monorepo-channels/channels/domain'
import { Store } from '@ngrx/store'
import { combineLatest, map, switchMap, tap } from 'rxjs'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms'
import { Actions, ofType } from '@ngrx/effects'
import { NgxCurrencyDirective } from 'ngx-currency'
import {
	CampaignService,
	ClickableTooltipDirective,
	isMobile,
	round2decimals,
} from '@monorepo-channels/shared/util-helpers'
import { MatSliderModule } from '@angular/material/slider'

@Component({
	standalone: true,
	selector: 'bb-payment-body',
	imports: [
		CommonModule,
		TitleUiComponent,
		AlertTopUiComponent,
		BoxSuccessUiComponent,
		FooterUiComponent,
		ButtonUiComponent,
		TicketUiComponent,
		ModalUiComponent,
		RadioUiComponent,
		BoxIconUiComponent,
		FooterUiComponent,
		ButtonUiComponent,
		BoxInfoUiComponent,
		ModalBottomUiComponent,
		FormsModule,
		DivisorUiComponent,
		GenericErrorUiComponent,
		LoadingPageComponent,
		TooltipUiComponent,
		BenefitsUiComponent,
		MatTooltipModule,
		ButtonUiComponent,
		NgxCurrencyDirective,
		ReactiveFormsModule,
		TooltipUiComponent,
		EditSVGComponent,
		InfoSVGComponent,
		ClickableTooltipDirective,
		MatSliderModule,
		BackgroundCardSVGComponent,
		RouterLink,
		LiveloPointsSVGComponent,
		ModalBottomFailureComponent,
		ModalCenterComponent,
	],
	templateUrl: './payment-body.page.html',
	styleUrls: ['./payment-body.page.scss'],
})
export class PaymentBodyComponent implements OnInit {
	paymentStage = 'value'
	// paymentStage = 'payment-success'
	private router = inject(Router)
	private route = inject(ActivatedRoute)
	private store = inject(Store)
	private actions$ = inject(Actions)
	// totalValue = 0

	totalValue = new FormControl(0, { nonNullable: true })
	tip = new FormControl(10, { nonNullable: true })
	dynamicCVV = new FormControl('', { nonNullable: true, validators: [Validators.required] })

	// selectedTip: number = this.tips[0]
	selectedTip = 0
	gratuityValue = 0
	value = 0
	orderPoints = 1
	suggestedTip = 10
	points = 0
	removeGratuityValue = false

	campaign$ = inject(CampaignService).campaign$

	vm$ = combineLatest([
		this.store.select(getClient),
		this.store.select(getSelectOrder),
		this.store.select(getPaymentOrder),
		this.store.select(getStatusPayment),
	]).pipe(
		map(([client, order, paymentOrder, statusPayment]) => ({
			client,
			order,
			paymentOrder,
			statusPayment,
		}))
	)

	@Output() clickUnderstood = new EventEmitter<void>()

	isMobile = isMobile()
	@ViewChild('modalFailurePayment')
	modalFailurePayment!: ModalBottomFailureComponent

	@ViewChild('modalFailurePaymentDesktop')
	modalFailurePaymentDesktop!: ModalCenterComponent

	@ViewChild('modalChangeCard')
	changeCardModal!: ModalBottomUiComponent

	@ViewChild('modalTip')
	modalTip!: ModalBottomUiComponent

	@ViewChild('modalChangeCardModalCenter')
	changeCardModalDesktop!: ModalCenterComponent

	@ViewChild('modalCenterTip')
	modalTipDesktop!: ModalCenterComponent

	changeCard(gatewayId: string | undefined, lastFourDigits: string | undefined) {
		if (!gatewayId || !lastFourDigits) return
		this.store.dispatch(ClientActions.changeCategory({ gatewayId, lastFourDigits }))
	}

	constructor() {
		this.store.dispatch(PaymentActions.resetPaymentStatus())
		this.actions$
			.pipe(ofType(PaymentActions.createPaymentFailure))
			.pipe(takeUntilDestroyed())
			.subscribe({
				next: () => {
					if (this.isMobile) {
						this.modalFailurePayment.openModal()
						this.dynamicCVV.reset()
					} else {
						this.modalFailurePaymentDesktop.openModal()
					}
				},
			})

		this.actions$
			.pipe(ofType(PaymentActions.createPaymentSuccess))
			.pipe(takeUntilDestroyed())
			.subscribe({ next: () => (this.paymentStage = 'payment-success') })

		this.store
			.select(getSelectOrder)
			.pipe(
				takeUntilDestroyed(),
				tap(order => {
					const pointsBenefit = order?.benefits.find(benefit => benefit.type === 'points')
					this.suggestedTip = order?.gratuityPercent ?? 10
					this.tip.setValue(this.suggestedTip)
					this.orderPoints = pointsBenefit ? Number(pointsBenefit.value) : 1
					this.selectedTip = this.suggestedTip
					this.removeGratuityValue = order?.removeGratuityValue ?? false
				}),
				switchMap(() => this.campaign$),
				tap(campaign => {
					if (campaign) {
						this.orderPoints = campaign.value
					}
				})
			)
			.subscribe()

		this.totalValue.valueChanges.pipe(takeUntilDestroyed()).subscribe({
			next: () => {
				this.updateValues()
			},
		})
	}

	ngOnInit(): void {
		const orderId = this.route.snapshot.paramMap.get('orderId')
		if (orderId) {
			this.store.dispatch(OrderHistoryActions.setSelectedOrderId({ orderId }))
		}
	}

	private updateValues() {
		this.gratuityValue = this.getGratuityValue(this.selectedTip)
		this.value = round2decimals(this.totalValue.value - this.gratuityValue)
		this.points = this.orderPoints ? Math.floor(this.value) * +this.orderPoints : Math.floor(this.value)
		const paymentOrder = {
			totalValue: this.totalValue.value,
			value: this.value,
			gratuityValue: this.gratuityValue,
			points: this.points,
		}
		this.store.dispatch(PaymentActions.setPaymentOrder({ paymentOrder }))
	}

	selectTip(tip: number): void {
		this.selectedTip = tip
		this.gratuityValue = this.getGratuityValue(tip)
		this.updateValues()
	}

	private getGratuityValue(tipPercentage: number) {
		if (this.removeGratuityValue) return 0
		const accValue = this.totalValue.value / (1 + tipPercentage / 100)
		const tipValue = round2decimals(this.totalValue.value - accValue)
		return tipValue
	}

	nextStage() {
		if (this.paymentStage === 'payment') {
			this.pay()
			return
		}
		const stages = ['value', 'payment']
		const currentIndex = stages.indexOf(this.paymentStage)
		if (currentIndex !== -1 && currentIndex < stages.length - 1) {
			this.paymentStage = stages[currentIndex + 1]
		}
	}

	changeCardClick() {
		if (this.isMobile) {
			this.changeCardModal.openModal()
		} else {
			this.changeCardModalDesktop.openModal()
		}
	}

	tipClick() {
		if (this.isMobile) {
			this.modalTip.openModal()
		} else {
			this.modalTipDesktop.openModal()
		}
	}

	pay() {
		this.store.dispatch(PaymentActions.createPayment({ dynamicCVV: this.dynamicCVV.value }))
	}

	goBack() {
		this.paymentStage = 'value'
		this.store.dispatch(
			PaymentActions.setPaymentOrder({
				paymentOrder: {
					totalValue: 0,
					value: 0,
					gratuityValue: 0,
					points: 0,
				},
			})
		)
	}

	resetPayment() {
		this.paymentStage = 'payment'
		this.store.dispatch(PaymentActions.resetPaymentStatus())
		this.dynamicCVV.setValue('')
	}

	navigateToRegisterCard() {
		this.router.navigate(['carteira/cadastrar-cartao'])
	}

	applyTip(modalTip: ModalBottomUiComponent) {
		this.selectedTip = this.tip.value
		this.gratuityValue = this.getGratuityValue(this.selectedTip)
		this.updateValues()
		this.isMobile ? modalTip.closeModal() : this.modalTipDesktop.closeModal()
	}

	selectCard(gatewayId: string | undefined, lastFourDigits: string | undefined) {
		if (!gatewayId || !lastFourDigits) return
		this.store.dispatch(ClientActions.changeCategory({ gatewayId, lastFourDigits }))
	}

	goToHome() {
		this.isMobile ? this.router.navigate(['/']) : this.clickUnderstood.emit()
	}

	disabledForStage() {
		if (this.paymentStage === 'value') {
			return this.totalValue.value < 10
		}
		return this.dynamicCVV.invalid
	}
}
