@if (vm$ | async; as vm) {
	@if (vm.client && !vm.client.selectedCategory?.isRealCard) {
		<div class="background temp-mobile-w">
			<div class="alert-home page">
				<i class="fa fa-wallet" style="margin-right: 16px; font-size: 23px"></i>
				<div class="alert-home__message">
					<span style="font-weight: 500">Acumule pontos Livelo</span>
					<span style="font-size: 14px">
						Cadastre seu cartão de crédito Ourocard, pague a conta pelo BB Gastronomia em
						"Reservas e pagamento" no Menu e aproveite
					</span>
				</div>
				<ui-bb-button
					type="primary"
					style="margin-left: auto; min-width: 100px; font-family: 'BB Gastronomia Titulos'"
					(clickButton)="registerCard()"
				>
					CADASTRAR
				</ui-bb-button>
			</div>
		</div>
	} @else if (vm.hasPaymentReady && vm.orders && vm.orders.length > 0) {
		<p class="title px-16" [ngClass]="{ page: isMobile, 'page-desktop': !isMobile }">
			Reservas/Check-ins ativos
		</p>
		<div class="background temp-mobile-w">
			<div class="alert-home page" *ngFor="let order of vm.orders">
				<div class="alert-home__venue">
					<span>{{ order.venue.name }}</span>
					<span>{{ order.date.date | date: 'dd/MM/YYYY' }}</span>
				</div>
				<ui-bb-button
					type="primary"
					style="margin-left: auto; width: 101px"
					(clickButton)="pay(order.id)"
				>
					PAGAR CONTA
				</ui-bb-button>
			</div>
		</div>
	}
}
