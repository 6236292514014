import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'

export function binMatch(bins: string[]): ValidatorFn {
	return (control: AbstractControl<string | null>): ValidationErrors | null => {
		if (!control.value) return null
		if (control.value.length < 6) return null
		const bin = control.value.substring(0, 6)
		return bins.includes(bin) ? null : { binMatch: { binValid: bins.join() } }
	}
}

export const bins = [
	'471703',
	'498423',
	'498424',
	'498442',
	'467148',
	'498407',
	'498452',
	'498402',
	'444458',
	'467151',
	'498408',
	'467153',
	'498453',
	'467149',
	'467148',
	'498401',
	'498406',
	'467152',
	'444434',
	'444457',
	'444459',
	'485464',
	'444456',
	'423072',
	'423071',
	'423070',
	'423069',
	'650487',
	'655001',
	'655003',
	'651653',
	'650487',
	'650496',
	'650497',
	'650499',
	'650500',
	'650501',
	'651653',
	'655001',
	'655003',
	'651676',
	'401563',
	'407830',
	'432728',
	'432729',
	'376491',
	'376493',
	'400136',
	'400162',
	'400163',
	'400176',
	'400178',
	'400182',
	'400185',
	'400199',
	'400444',
	'401178',
	'401179',
	'403236',
	'403791',
	'403792',
	'403793',
	'403794',
	'403795',
	'404002',
	'406467',
	'407800',
	'407801',
	'407802',
	'409869',
	'414451',
	'414452',
	'430543',
	'431274',
	'433873',
	'438935',
	'441172',
	'441174',
	'448549',
	'448556',
	'457594',
	'457595',
	'457631',
	'457632',
	'457633',
	'457634',
	'461205',
	'467150',
	'467481',
	'469570',
	'469571',
	'471701',
	'471702',
	'471704',
	'477115',
	'477116',
	'484147',
	'484148',
	'484189',
	'484199',
	'485960',
	'486323',
	'489410',
	'489411',
	'498409',
	'498425',
	'498430',
	'498431',
	'498433',
	'498446',
	'498447',
	'498458',
	'498496',
	'499904',
	'503337',
	'506721',
	'506728',
	'506729',
	'506730',
	'506733',
	'506744',
	'506745',
	'506747',
	'506748',
	'506753',
	'506775',
	'506776',
	'509067',
	'509068',
	'509069',
	'512112',
	'512119',
	'514009',
	'514094',
	'514095',
	'514895',
	'516064',
	'516066',
	'518759',
	'518760',
	'518763',
	'518764',
	'518765',
	'521501',
	'522978',
	'525439',
	'526811',
	'538818',
	'540064',
	'540084',
	'540101',
	'540500',
	'540535',
	'542661',
	'542842',
	'544066',
	'544545',
	'544586',
	'544597',
	'544908',
	'545053',
	'546451',
	'546452',
	'546454',
	'546456',
	'546479',
	'546499',
	'546719',
	'546990',
	'546991',
	'547604',
	'547606',
	'547749',
	'547874',
	'548572',
	'548573',
	'548595',
	'548794',
	'548822',
	'548824',
	'548965',
	'549833',
	'552063',
	'552289',
	'552584',
	'552694',
	'552911',
	'553103',
	'553104',
	'554906',
	'554927',
	'555724',
	'558779',
	'650407',
	'650570',
	'650571',
	'650906',
	'650946',
	'650948',
]
