import { Component, inject } from '@angular/core'
import { CommonModule } from '@angular/common'

import { BannerUiComponent, FooterUiComponent, ButtonUiComponent } from '@monorepo-channels/components/ui-bb'
import { Router } from '@angular/router'
import { isMobile } from '@monorepo-channels/shared/util-helpers'

@Component({
	standalone: true,
	selector: 'bb-how-it-work',
	imports: [CommonModule, BannerUiComponent, FooterUiComponent, ButtonUiComponent],
	templateUrl: './how-it-work-body.component.html',
	styleUrls: ['./how-it-work-body.component.scss'],
})
export class HowItWorkComponent {
	router = inject(Router)
	isMobile = isMobile()
}
