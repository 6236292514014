<div class="boxSelect" (click)="open()">
	<p class="boxSelect__title">{{ title }}</p>
	<div class="boxSelect__selected">
		<input
			type="text"
			class="boxSelect__selected__info"
			[value]="selectedOption"
			readonly
			[class]="inputClass"
		/>
		<i
			class="fa fa-seta-acao boxSelect__selected__icon"
			[ngClass]="{ isRotate: isOpen }"
			(click)="open()"
		></i>
	</div>
</div>
<div class="boxOptions" *ngIf="isOpen">
	<div *ngFor="let option of options" class="boxOption" (click)="selectOption(option); open()">
		<p class="boxOption__option">{{ option.name }}</p>
	</div>
</div>
