import { Cuisine } from '../../gateways/venues.gateway'

export const featureCuisineName = 'cuisine'

export interface CuisinesState {
	cuisines: Cuisine[] | null
	selectedCuisine: Cuisine
	error: string
}

export const DEFAULT_CUISINE_TEXT = 'Tipo de Culinária'

export const initialStateCusisine: CuisinesState = {
	cuisines: null,
	selectedCuisine: { _id: '', name: DEFAULT_CUISINE_TEXT },
	error: '',
}
