import { Component, HostBinding, Input } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ModalUiComponent } from '../modal.component'
import { FooterUiComponent } from '../../footer/footer.component'
import { ButtonUiComponent } from '../../button/button.component'
import { DivisorUiComponent } from '@monorepo-channels/components/ui-common'

@Component({
	selector: 'ui-bb-modal-bottom',
	standalone: true,
	imports: [CommonModule, ModalUiComponent, FooterUiComponent, ButtonUiComponent, DivisorUiComponent],
	templateUrl: 'modal-bottom.components.html',
	styleUrls: ['modal-bottom.components.scss'],
})
export class ModalBottomUiComponent {
	@Input() title?: string
	@Input() height = '70vh'
	@Input() overflow: 'auto' | 'hidden' = 'hidden'
	public isOpen = false

	@HostBinding('style')
	get styles() {
		return { '--modal-bottom-min-height': this.height, '--modal-bottom-overflow': this.overflow }
	}

	openModal() {
		this.isOpen = true
	}

	closeModal() {
		this.isOpen = false
	}
}
