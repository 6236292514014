import { createReducer, on } from '@ngrx/store'
import { ClientActions } from './client.actions'
import { ClientState, initialState } from './client.state'

export const clientReducer = createReducer<ClientState>(
	initialState,
	on(ClientActions.loadClient, ClientActions.loadClientUnicredSSO, (state): ClientState => {
		return {
			...state,
			loginStatus: 'pending',
		}
	}),
	on(ClientActions.loadClientSuccess, (state, action): ClientState => {
		return {
			...state,
			client: action.client,
			isLoggedIn: true,
			loginStatus: 'success',
		}
	}),
	on(ClientActions.loadClientFail, (state, action): ClientState => {
		return {
			...state,
			client: null,
			errorLoginStatus: action.error,
			isLoggedIn: false,
			loginStatus: 'failure',
		}
	}),
	on(ClientActions.setRedirectUrl, (state, action): ClientState => {
		return {
			...state,
			redirectUrl: action.url,
		}
	}),
	on(ClientActions.addNewCard, (state): ClientState => {
		return {
			...state,
			registerCardStatus: 'pending',
		}
	}),
	on(ClientActions.registerCardFailure, (state, action): ClientState => {
		return {
			...state,
			errorRegisterCard: action.error,
			registerCardStatus: 'failure',
		}
	}),
	on(ClientActions.registerCardSuccess, (state): ClientState => {
		return {
			...state,
			registerCardStatus: 'success',
		}
	}),
	on(ClientActions.resetCardStatus, (state): ClientState => {
		return {
			...state,
			registerCardStatus: 'idle',
		}
	}),
	on(ClientActions.updateMainCard, (state): ClientState => {
		return {
			...state,
			mainCardStatus: 'pending',
		}
	}),
	on(ClientActions.updateMainCardFailure, (state, action): ClientState => {
		return {
			...state,
			errorMainCard: action.error,
			mainCardStatus: 'failure',
		}
	}),
	on(ClientActions.updateMainCardSuccess, (state): ClientState => {
		return {
			...state,
			mainCardStatus: 'success',
		}
	}),

	on(ClientActions.removeCard, (state): ClientState => {
		return {
			...state,
			removeCardStatus: 'pending',
		}
	}),
	on(ClientActions.removeCardFailure, (state, action): ClientState => {
		return {
			...state,
			errorRemoveCard: action.error,
			removeCardStatus: 'failure',
		}
	}),
	on(ClientActions.removeCardSuccess, (state): ClientState => {
		return {
			...state,
			removeCardStatus: 'success',
		}
	}),
	on(ClientActions.logoutClient, (state): ClientState => {
		return {
			...state,
			isLoggedIn: false,
			client: null,
		}
	}),
	on(ClientActions.changeCategory, (state, action): ClientState => {
		return {
			...state,
			client: state.client?.setCard(action.gatewayId, action.lastFourDigits) ?? null,
		}
	}),
	on(ClientActions.updateClientBackend, (state): ClientState => {
		return {
			...state,
			statusUpdateClientBackend: 'pending',
		}
	}),

	on(ClientActions.updateClientBackendSuccess, (state): ClientState => {
		return {
			...state,
			statusUpdateClientBackend: 'success',
			errorUpdateClientBackend: null,
		}
	}),
	on(ClientActions.updateClientBackendFailure, (state, action): ClientState => {
		return {
			...state,
			statusUpdateClientBackend: 'failure',
			errorUpdateClientBackend: action.error,
		}
	}),
	on(ClientActions.resetClient, (): ClientState => {
		return {
			...initialState,
		}
	}),
	on(ClientActions.selectedCardByGatewayId, (state, action): ClientState => {
		if (action.gatewayId && state.client) {
			return {
				...state,
				client: state.client.setCard(action.gatewayId, action.lastFourDigits),
			}
		}
		return {
			...state,
		}
	})
)
