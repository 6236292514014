<div class="box-message temp-mobile-w" *ngIf="templates.makeItEasierPayment">
	<i class="fa fa-wallet"></i>
	<p class="text">
		Facilite o pagamento da conta no restaurante, cadastre seu cartão Ourocard e acumule pontos
		<strong>Livelo</strong>
	</p>
</div>

<div class="box-message temp-mobile-w" *ngIf="templates.accumulatePoints">
	<i class="fa fa-wallet"></i>
	<div>
		<p class="title">Acumule pontos Livelo</p>
		<p class="text">
			Cadastre seu cartão de crédito Ourocard, realize o pagamento da conta no restaurante e acumule
			pontos Livelo
		</p>
	</div>
</div>
