import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'formatDateHrs', standalone: true })
export class FormatDateHrs implements PipeTransform {
	transform(date: Date) {
		return `${date.toLocaleString('pt-BR', {
			day: 'numeric',
			month: 'long',
			hour: 'numeric',
			minute: 'numeric',
			hour12: false,
		})} hrs`
	}
}
