<div class="overlay" (click)="closeMenu()" [ngStyle]="{ display: isMenuOpen ? 'block' : 'none' }"></div>

<div class="header temp-mobile-w">
	<div class="optionsMenu" *ngIf="isMenuOpen">
		<ul class="optionsMenu__line">
			<li class="menuItem" (click)="checkRoute('/')">
				<p class="menuItem__text">Home</p>
				<i class="fa fa-chevron-up menuItem__icon"></i>
			</li>
			<li class="menuItem" (click)="checkRoute('/carteira')" *ngIf="isLoggedIn">
				<p class="menuItem__text">Carteira</p>
				<i class="fa fa-chevron-up menuItem__icon"></i>
			</li>
			<li class="menuItem" (click)="checkRoute('/reservas-e-pagamento')" *ngIf="isLoggedIn">
				<p class="menuItem__text">Reservas e pagamento</p>
				<div *ngIf="hasPaymentReady$ | async" class="number">1</div>
				<i class="fa fa-chevron-up menuItem__icon"></i>
			</li>
			<li class="menuItem" (click)="checkRoute('/como-funciona')">
				<p class="menuItem__text">Como funciona</p>
				<i class="fa fa-chevron-up menuItem__icon"></i>
			</li>
			<li class="menuItem" (click)="checkRoute('/faq')">
				<p class="menuItem__text">FAQ</p>
				<i class="fa fa-chevron-up menuItem__icon"></i>
			</li>
			<li class="menuItem" (click)="checkRoute('/terms')">
				<p class="menuItem__text">Termos e condições</p>
				<i class="fa fa-chevron-up menuItem__icon"></i>
			</li>
			<li class="menuItem" (click)="checkRoute('/contacts')">
				<p class="menuItem__text">Contatos</p>
				<i class="fa fa-chevron-up menuItem__icon"></i>
			</li>
		</ul>
	</div>
	<div class="menuAlert" (click)="openMenu()">
		<div *ngIf="hasPaymentReady$ | async" class="menuAlert__number number">1</div>

		<i class="fa fa-bars header__icon"></i>
	</div>
	<!--<i class="fa fa-logo logo" (click)="checkRoute('/')"></i>
	<div class="divider"></div>-->
	<p class="header__text" (click)="checkRoute('/')">BB Gastronomia</p>
	@if (client$ | async; as client) {
		<span *ngIf="client$ | async as client" style="margin-left: auto; font-size: 12px">
			Olá, {{ client.firstName }}.
		</span>
	} @else {
		<div
			style="margin-left: auto; cursor: pointer; font-size: 14px; display: flex; align-items: center"
			(click)="openModalLogin()"
		>
			<ui-common-bb-user-svg style="margin-right: 8px" />
			<span> Entrar </span>
		</div>
	}
</div>
<ng-template #modalLogin>
	<div style="display: flex; flex-direction: column; height: 100%">
		<article style="flex-grow: 1">
			<p style="margin-top: 16px">
				Para realizar o seu login, será necessário confirmar seu login pela Livelo. <br />
				<br />Não se preocupe, você voltará para a página do restaurante logo depois disso.
			</p>
		</article>
		<ui-bb-button
			type="secondary"
			style="width: 100%; font-size: 12px; padding: 12px"
			(clickButton)="openLoginLivelo()"
		>
			CONTINUAR
		</ui-bb-button>
		<div style="height: 24px" *ngIf="isMobile"></div>
	</div>
</ng-template>

<ng-container *ngIf="isMobile">
	<ui-bb-modal-bottom
		title="Fazer login"
		#modalBottomLogin
		style="color: var(--primitives-grey-500)"
		height="400px"
	>
		<ng-container *ngTemplateOutlet="modalLogin"></ng-container>
	</ui-bb-modal-bottom>
</ng-container>

<ng-container *ngIf="!isMobile">
	<!-- modal Login-->
	<ui-bb-modal-center title="Fazer login" style="color: var(--primitives-grey-500)" #modalCenterLogin>
		<div style="padding-inline: 12px">
			<ng-container *ngTemplateOutlet="modalLogin"></ng-container>
		</div>
	</ui-bb-modal-center>

	<!--#modalCenterMenu-->
	<ui-bb-modal-center
		#modalCenterMenu
		(clickOutside)="modalCenterMenu.closeModal()"
		style="z-index: 10; position: relative"
	>
		<div style="height: var(--height-desktop); overflow-y: auto; overflow-x: hidden; padding-top: 24px">
			<ng-container [ngComponentOutlet]="resolve(chooseComponent) | async"></ng-container>
		</div>
	</ui-bb-modal-center>

	<!--#modalCenterWallet-->
	<ui-bb-modal-center
		#modalCenterWallet
		style="z-index: 10; position: relative"
		(clickOutside)="closeModalCenterWallet()"
	>
		<div style="padding-bottom: 12px">
			<bb-wallet-body
				*ngIf="openModal.wallet"
				(mainClicked)="openModalCenterDefaultCard($event)"
				(deleteClicked)="openModalCenterDeleteCard($event)"
				(registerCardClicked)="openModalCenterNewCard(); modalCenterWallet.closeModal()"
			/>
		</div>
	</ui-bb-modal-center>

	<!--#modalCenterWalletOptions-->
	<ui-bb-modal-center
		[title]="modalWallet.title"
		[close]="false"
		style="color: var(--primitives-grey-500)"
		#modalCenterWalletOptions
		style="z-index: 10; position: relative"
		(clickOutside)="modalCenterWalletOptions.closeModal(); openModalCenterWallet()"
	>
		<div style="padding-inline: 12px">
			<p style="margin-top: 20px; font-size: 16px">{{ modalWallet.text }}</p>
			<ui-bb-footer position="static" footer style="padding: 0 16px">
				<ui-bb-button
					type="secondary"
					style="width: 100%; font-size: 12px"
					(clickButton)="modalWallet.click()"
				>
					{{ modalWallet.buttonText }}
				</ui-bb-button>
			</ui-bb-footer>
		</div>
	</ui-bb-modal-center>

	<!--#modalCenterPayment-->
	<ui-bb-modal-center
		style="z-index: 10; position: relative"
		#modalCenterPayment
		(clickOutside)="closeModalCenterPayment()"
	>
		<div style="height: 3px"></div>
		<bb-payment-body *ngIf="openModal.payment" (clickUnderstood)="closeModalCenterPayment()" />
	</ui-bb-modal-center>

	<!--#modalCenterNewCard-->
	<ui-bb-modal-center
		#modalCenterNewCard
		style="z-index: 10; position: relative"
		(clickOutside)="closeModalCenterNewCard()"
	>
		<div style="height: 70vh; overflow-y: auto; overflow-x: hidden">
			<bb-new-card-body *ngIf="openModal.registerCard" />
		</div>
	</ui-bb-modal-center>

	<!--#modalRegisterCardFailure-->
	<ui-bb-modal-center
		title="Não foi possível cadastrar o cartão"
		height="370px"
		#modalRegisterCardFailure
		style="z-index: 10; position: relative"
	>
		<div *ngIf="registerCardError$ | async as error" style="padding: 12px">
			@if (error?.includes('Bin')) {
				Identificamos um problema com o BIN fornecido, e o cadastro do seu cartão não pôde ser
				concluído. Lamentamos o transtorno. Sugerimos que consulte os BIN válidos e tente novamente.
			} @else if (error?.includes('titular')) {
				{{ error }}
			} @else {
				Houve um erro e seu cartão não foi cadastrado corretamente. Revise os dados do seu cartão e
				tente novamente ou se o problema persistir, entre em contato com a operadora do seu cartão.
			}
		</div>
	</ui-bb-modal-center>

	<!--#modalCenterCompleteRegistration-->
	<ui-bb-modal-center #modalCenterCompleteRegistration style="z-index: 10; position: relative">
		<bb-complete-registration-body
			*ngIf="openModal.completeRegistration"
			(close)="closeModalCenterCompleteRegistration()"
		></bb-complete-registration-body>
	</ui-bb-modal-center>

	<!--#modalCenterOrderHistory-->
	<ui-bb-modal-center
		#modalCenterOrderHistory
		style="z-index: 10; position: relative"
		(clickOutside)="closeOrderHistory()"
		><div style="max-height: 600px; overflow-y: auto; overflow-x: hidden">
			<bb-orders-history-body
				*ngIf="openModal.orderHistory"
				(openModalCancelEmitterDesk)="openModalCancelDesk($event)"
				(openModalOrderEmitterDesk)="openModalOrderDesk($event)"
				(openModalRegisterCardEmitter)="openModalRegisterCard()"
				(openGoToPaymentEmitter)="openModalCenterPaymentHistory($event)"
			/>
		</div>
	</ui-bb-modal-center>

	<!--modalCenterCancelOrder-->
	<ui-bb-modal-center
		style="z-index: 10; position: relative"
		[close]="false"
		title="Cancelar reserva"
		height="auto"
		#modalCenterCancelOrder
		(clickOutside)="modalCenterCancelOrder.closeModal()"
	>
		<div class="bodyModal">
			<p class="bodyModal__text">Tem certeza que deseja cancelar sua reserva no restaurante?</p>
			<div class="boxVenue">
				<p class="boxVenue__name">{{ selectedOrder?.venue?.name }}</p>
				<p class="boxVenue__address">{{ selectedOrder?.venue?.address?.value }}</p>
			</div>

			<div class="info" style="margin: 4px 0 4px">
				<p class="info__title">Data</p>
				<p class="info__text">{{ selectedOrder?.date?.date | date }}</p>
			</div>

			<div class="info" style="padding: 0">
				<p class="info__title">Horário</p>
				<p class="info__text">{{ selectedOrder?.date?.time }}</p>
			</div>
		</div>

		<ui-bb-footer position="static">
			<ui-bb-button type="secondary" style="width: 163px" (clickButton)="closeCancelDesk()">
				SAIR
			</ui-bb-button>
			<ui-bb-button type="primary" style="width: 163px" (clickButton)="cancelOrder(selectedOrder)">
				SIM, CANCELAR
			</ui-bb-button>
		</ui-bb-footer>
	</ui-bb-modal-center>

	<!--modalCenterOrder-->
	<ui-bb-modal-center
		style="z-index: 10; position: relative"
		[close]="false"
		height="auto"
		#modalCenterOrder
		(clickOutside)="closeOrderDesk()"
		><div style="padding-top: 20px">
			<bb-order-details-body
				*ngIf="openModal.order"
				(openModalDetailsToHistoryEmitter)="closeOrderDesk()"
				(openModalCancelDetailsEmitter)="openModalCancelDetailsDesk()"
				(openModalNoCardDetailsEmitter)="openModalNoCardDetails()"
				(openModalPaymentDetailsEmitter)="openModalPaymentDetails($event)"
			></bb-order-details-body>
		</div>
		<!--<ui-bb-footer position="static">
		<ui-bb-button type="secondary" style="width: 163px" (clickButton)="closeOrderDesk()">
			SAIR
		</ui-bb-button>
		<ui-bb-button type="primary" style="width: 163px" (clickButton)="cancelOrder(order)">
			SIM, CANCELAR
		</ui-bb-button>
	</ui-bb-footer>-->
	</ui-bb-modal-center>

	<!--modalCenterNoCard-->
	<ui-bb-modal-center
		title="Cadastre o seu cartão"
		style="z-index: 20; position: relative"
		[close]="false"
		height="auto"
		#modalCenterNoCard
		(clickOutside)="modalCenterNoCard.closeModal()"
		style="color: var(--primitives-grey-500)"
	>
		<p style="line-height: 20px; margin: 0 16px">
			Olá! Para pagar e ganhar os pontos Livelo, será necessário cadastrar um cartão de crédito
			Ourocard.
		</p>
		<ui-bb-footer position="static">
			<ui-bb-button
				type="secondary"
				style="width: 100%; font-size: 12px; margin: 0 16px"
				(clickButton)="registerCard()"
			>
				CONTINUAR
			</ui-bb-button>
		</ui-bb-footer>
	</ui-bb-modal-center>
</ng-container>
