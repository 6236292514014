import { Component, HostListener, inject, ViewChild } from '@angular/core'
import { CommonModule, Location } from '@angular/common'
import { CepService, isMobile, removeAccents } from '@monorepo-channels/shared/util-helpers'
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms'

import {
	TitleUiComponent,
	AlertBoxUiComponent,
	FooterUiComponent,
	ButtonUiComponent,
	SelectUiComponent,
	GenericErrorUiComponent,
	LoadingPageComponent,
	TooltipUiComponent,
	ConfirmSliderUiComponent,
	BoxMessageComponent,
	ModalBottomFailureComponent,
} from '@monorepo-channels/components/ui-bb'

import { DivisorUiComponent } from '@monorepo-channels/components/ui-common'
import { combineLatest, filter, map, switchMap } from 'rxjs'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask'
import { Store } from '@ngrx/store'
import {
	BillingAddress,
	ClientActions,
	EncryptCardDataDto,
	getErrorRegisterCard,
	getRegisterCardStatus,
} from '@monorepo-channels/channels/domain'
import { Actions, ofType } from '@ngrx/effects'
import { binMatch, bins } from './bin.validator'

@Component({
	standalone: true,
	imports: [
		CommonModule,
		AlertBoxUiComponent,
		FooterUiComponent,
		ButtonUiComponent,
		TitleUiComponent,
		DivisorUiComponent,
		SelectUiComponent,
		ReactiveFormsModule,
		NgxMaskDirective,
		NgxMaskPipe,
		GenericErrorUiComponent,
		LoadingPageComponent,
		TooltipUiComponent,
		ConfirmSliderUiComponent,
		BoxMessageComponent,
		ModalBottomFailureComponent,
	],
	selector: 'bb-new-card-body',
	templateUrl: './new-card-body.component.html',
	styleUrls: ['./new-card-body.component.scss'],
})
export class NewCardBodyComponent {
	states = [
		{ name: 'Acre', id: 'AC' },
		{ name: 'Alagoas', id: 'AL' },
		{ name: 'Amapá', id: 'AP' },
		{ name: 'Amazonas', id: 'AM' },
		{ name: 'Bahia', id: 'BA' },
		{ name: 'Ceará', id: 'CE' },
		{ name: 'Distrito Federal ', id: 'DF' },
		{ name: 'Espírito Santo ', id: 'ES' },
		{ name: 'Goiás ', id: 'GO' },
		{ name: 'Maranhão ', id: 'MA' },
		{ name: 'Mato Grosso ', id: 'MT' },
		{ name: 'Mato Grosso do Sul ', id: 'MS' },
		{ name: 'Minas Gerais ', id: 'MG' },
		{ name: 'Pará ', id: 'PA' },
		{ name: 'Paraíba ', id: 'PB' },
		{ name: 'Paraná ', id: 'PR' },
		{ name: 'Pernambuco ', id: 'PE' },
		{ name: 'Piauí ', id: 'PI' },
		{ name: 'Rio de Janeiro ', id: 'RJ' },
		{ name: 'Rio Grande do Norte ', id: 'RN' },
		{ name: 'Rio Grande do Sul ', id: 'RS' },
		{ name: 'Rondônia ', id: 'RO' },
		{ name: 'Roraima ', id: 'RR' },
		{ name: 'Santa Catarina ', id: 'SC' },
		{ name: 'São Paulo ', id: 'SP' },
		{ name: 'Sergipe ', id: 'SE' },
		{ name: 'Tocantins ', id: 'TO' },
	]

	selectedState = 'Estado'

	private actions$ = inject(Actions)
	private store = inject(Store)
	private cepService = inject(CepService)
	private fb = inject(FormBuilder)
	private location = inject(Location)

	vm$ = combineLatest([
		this.store.select(getRegisterCardStatus),
		this.store.select(getErrorRegisterCard),
	]).pipe(map(([status, error]) => ({ status, error })))

	@ViewChild('modalRegisterCardFailure')
	registerCardFailure!: ModalBottomFailureComponent

	creditCardForm = this.fb.nonNullable.group({
		number: ['', [Validators.required, binMatch(bins)]],
		name: ['', Validators.required],
		validity: ['', Validators.required],
		cvv: ['', Validators.required],
		cpf: ['', Validators.required],
		billingAddress: this.fb.nonNullable.group({
			zipCode: ['', Validators.required],
			state: ['', Validators.required],
			city: ['', Validators.required],
			address: ['', Validators.required],
		}),
		mainCard: [false],
	})

	isMobile = isMobile()
	@HostListener('window:resize', ['$event'])
	onResize() {
		this.isMobile = isMobile()
	}

	constructor() {
		this.store.dispatch(ClientActions.resetCardStatus())
		this.creditCardForm.controls.billingAddress.controls.zipCode?.valueChanges
			.pipe(
				filter(zipCode => zipCode.toString().length >= 8),
				switchMap(zipCode => this.cepService.findCep(zipCode)),
				takeUntilDestroyed()
			)
			.subscribe(({ state, city, address, error }) => {
				if (error) return
				this.selectedState = this.states.find(item => item.id === state)?.name ?? 'Estado'
				this.creditCardForm.controls.billingAddress.patchValue({
					state,
					city,
					address,
				})
			})

		this.actions$.pipe(ofType(ClientActions.registerCardSuccess), takeUntilDestroyed()).subscribe(() => {
			if (this.isMobile) {
				this.location.back()
			}
		})

		// this.mockInputCard()
	}

	handleSubmit() {
		if (this.creditCardForm.invalid) return
		const billingAddress = this.creditCardForm.controls.billingAddress.value as BillingAddress
		this.store.dispatch(
			ClientActions.addNewCard({
				encryptData: this.encryptData,
				billingAddress: { ...billingAddress, country: 'BR' },
				cpf: this.creditCardForm.controls.cpf.value,
				mainCard: this.creditCardForm.controls.mainCard.value as boolean,
			})
		)
	}

	private get encryptData(): EncryptCardDataDto {
		const [expMonth, expYear] = this.creditCardForm.controls.validity.value!.split('/')
		const encryptCard = {
			number: this.creditCardForm.controls.number.value as string,
			holder: removeAccents(this.creditCardForm.controls.name.value as string),
			cvv: this.creditCardForm.controls.cvv!.value as string,
			expMonth: +expMonth,
			expYear: +`20${expYear}`,
		}
		return encryptCard
	}

	toggleMainCard(value: boolean) {
		this.creditCardForm.controls.mainCard.setValue(value)
	}

	setState(event: { name: string; id: string }) {
		this.creditCardForm.controls.billingAddress.controls.state.setValue(event.id)
	}

	resetCardStatus() {
		this.store.dispatch(ClientActions.resetCardStatus())
	}

	private mockInputCard() {
		this.creditCardForm.patchValue({
			number: '47170312345614532',
			name: 'John Doe',
			validity: '12/23',
			cvv: '123',
			cpf: '12345678901',
			billingAddress: {
				zipCode: '27930360',
			},
		})
	}
}
