import { Directive, inject, HostListener } from '@angular/core'
import { MatTooltip } from '@angular/material/tooltip'

@Directive({
	selector: '[matTooltip]',
	standalone: true,
})
export class ClickableTooltipDirective {
	private matTooltip = inject(MatTooltip)

	@HostListener('click')
	onClick() {
		this.matTooltip.toggle()
	}
}
