import { Component, ViewChild, inject } from '@angular/core'
import { CommonModule, Location } from '@angular/common'

import { Store } from '@ngrx/store'
import {
	ReservationActions,
	getErrorAvailability,
	getErrorConfirmation,
} from '@monorepo-channels/channels/domain'
import { Router } from '@angular/router'

import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { ModalBottomFailureComponent } from '@monorepo-channels/components/ui-bb'
import { Actions, ofType } from '@ngrx/effects'
import { ReservationComponent } from '@monorepo-channels/feature-bb'
import { HeaderComponent } from '../../header/header.component'

@Component({
	standalone: true,
	imports: [CommonModule, HeaderComponent, ModalBottomFailureComponent, ReservationComponent],
	templateUrl: './reservation.page.html',
	styleUrls: ['./reservation.page.scss'],
})
export class ReservationPageComponent {
	private store = inject(Store)
	public router = inject(Router)
	private actions$ = inject(Actions)
	showAlert = false

	errorConfirmReservation$ = this.store.select(getErrorConfirmation)
	errorAvailability$ = this.store.select(getErrorAvailability)
	location = inject(Location)

	@ViewChild('modalReservationFailure')
	modalReservationFailure!: ModalBottomFailureComponent

	@ViewChild('modalAvailabilitiesFailure')
	modalAvailabilitiesFailure!: ModalBottomFailureComponent

	constructor() {
		this.actions$
			.pipe(ofType(ReservationActions.confirmReservationFail), takeUntilDestroyed())
			.subscribe({
				next: () => {
					this.modalReservationFailure.openModal()
				},
			})
		this.actions$
			.pipe(ofType(ReservationActions.loadAvailabilitiesFail), takeUntilDestroyed())
			.subscribe({
				next: () => {
					this.modalAvailabilitiesFailure.openModal()
				},
			})
	}
}
