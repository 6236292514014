<div class="box-message temp-mobile-w" *ngIf="templates.makeItEasierPayment">
	<i class="fa fa-wallet"></i>
	<p class="text">
		Facilite o pagamento da conta no restaurante, cadastre seu cartão Ourocard e acumule pontos
		<strong>Livelo</strong>
	</p>
</div>

<div class="box-message temp-mobile-w" *ngIf="templates.accumulatePoints">
	<i class="fa fa-wallet"></i>
	<div>
		<p class="title">Acumule pontos Livelo</p>
		<p class="text">
			Cadastre seu cartão de crédito Ourocard, realize o pagamento da conta no restaurante e acumule
			pontos Livelo
		</p>
	</div>
</div>

<div class="box-message temp-mobile-w" *ngIf="templates.checkinUnavailable">
	<i class="fa fa-info-outline"></i>
	<p class="text">
		O Check-in não está habilitado no momento. Verifique o horário de funcionamento do restaurante ou faça
		uma reserva.
	</p>
</div>

<div class="box-message temp-mobile-w" *ngIf="templates.reservationInfo">
	<i class="fa fa-info-outline"></i>
	<p class="text">
		A reserva de mesa é realizada com antecedência para você garantir os benefícios do BB Gastronomia e
		uma mesa no dia de sua preferência, além de evitar lista de espera.
	</p>
</div>
