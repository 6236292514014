<div
	class="alertTop temp-mobile-w"
	[ngClass]="{
		primary: type === 'primary',
		secondary: type === 'secondary',
		danger: type === 'danger'
	}"
>
	<div class="alertTop__box">
		<i class="{{ icon }} alertTop__box__icon"></i>
		<p><ng-content></ng-content></p>
	</div>
</div>
