import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	inject,
	Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterLink } from '@angular/router'
import { CampaignResponse, CampaignService, isMobile } from '@monorepo-channels/shared/util-helpers'
import { tap } from 'rxjs'

@Component({
	selector: 'ui-bb-banner',
	standalone: true,
	imports: [CommonModule, RouterLink],
	templateUrl: './banner.component.html',
	styleUrls: ['./banner.component.scss'],
})
export class BannerUiComponent implements OnInit, AfterViewInit {
	@Input({ required: true }) banner!: string
	@Output() infoButtonClicked = new EventEmitter<void>()
	isMobile = isMobile()
	campaign$ = inject(CampaignService).campaign$
	campaign: CampaignResponse | null = null

	@ViewChild('bannerEl') bannerElement!: ElementRef

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.isMobile = isMobile()
		if (this.bannerElement) {
			this.updateBackgroundImage()
		}
	}

	ngOnInit(): void {
		this.campaign$
			.pipe(
				tap(campaign => {
					this.campaign = campaign
					this.updateBackgroundImage()
				})
			)
			.subscribe()
	}

	ngAfterViewInit() {
		this.updateBackgroundImage()
	}

	updateBackgroundImage() {
		const mobileUrl = this.campaign?.banner?.mobile
			? this.campaign?.banner?.mobile.replace(/\s/g, '%20')
			: null
		const desktopUrl = this.campaign?.banner?.desktop
			? this.campaign?.banner?.desktop.replace(/\s/g, '%20')
			: null

		const backgroundImageUrl = this.isMobile
			? mobileUrl || `'https://tagmepub.blob.core.windows.net/pubimg/Channels/BB/bannerMobile.png'`
			: desktopUrl || `'https://tagmepub.blob.core.windows.net/pubimg/Channels/BB/bannerDesk.png'`

		if (this.bannerElement)
			this.bannerElement.nativeElement.style.backgroundImage = `url(${backgroundImageUrl})`
	}
}
