import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { AvailabilityReservation } from './reservation'

export const ReservationActions = createActionGroup({
	source: 'ReservationPage',
	events: {
		'Load Availabilities': props<{ venueId: string }>(),
		'Load Availabilities Success': props<{ availabilities: AvailabilityReservation[] }>(),
		'Load Availabilities Fail': props<{ error: string }>(),
		'Set Selected Date': props<{ selectedDate: string }>(),
		'Set Selected Section': props<{ selectedSectionLabel: string }>(),
		'Set Schedule from partysize': props<{ selectedPartySize: string }>(),
		'Set ReservationTime': props<{ selectedReservationTime: string }>(),
		'Set OrderId after Loading Availabilities': props<{ orderId: string; venueId: string }>(),
		'Confirm Reservation': emptyProps(),
		'Confirm Reservation Success': emptyProps(),
		'Confirm Reservation Fail': props<{ error: string }>(),
		'Reset Reservation State': emptyProps(),
		'Reset Initial State Success': emptyProps(),
	},
})
