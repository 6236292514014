import { Injectable, inject } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { switchMap, filter, map, mergeMap, catchError, of } from 'rxjs'
import { VenuesGateway } from '../../gateways/venues.gateway'
import { AppState } from '../helper.state'
import { getCuisines } from './cuisine.selectors'
import { CuisineActions } from './cuisine.actions'

@Injectable()
export class CuisinesEffects {
	private actions$ = inject(Actions)
	private venuesGateway = inject(VenuesGateway)
	private store = inject(Store<AppState>)

	getCuisines$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(CuisineActions.getCuisines),
			switchMap(() => this.store.select(getCuisines)),
			// Only load cuisines if the array has at least one cuisine
			filter(cuisines => !cuisines?.length),
			map(() => CuisineActions.loadCuisines())
		)
	})

	loadCuisines$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(CuisineActions.loadCuisines),
			mergeMap(() =>
				this.venuesGateway.getCuisines().pipe(
					map(cuisines => CuisineActions.loadCuisinesSuccess({ cuisines })),
					catchError(error => of(CuisineActions.loadCuisinesFailure({ error })))
				)
			)
		)
	})
}
