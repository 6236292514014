import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'
import { PlatformModule } from '@angular/cdk/platform'

@Component({
	selector: 'ui-bb-loading-page',
	standalone: true,
	imports: [CommonModule, PlatformModule],
	templateUrl: './loading-page.component.html',
	styleUrls: ['./loading-page.component.scss'],
})
export class LoadingPageComponent {
	// device = inject(Platform).IOS ? 'ios' : 'android'
	device = 'android'
}
