import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { BenefitsDto, FilterVenuesDto, FindOneVenueDto } from '../../gateways/venues.gateway'
import { VenueFilter } from '../../models/venues/venues-filter'
import { VenueDetail } from '../../models/venues/venues-details'

export const VenueActions = createActionGroup({
	source: 'Venue',
	events: {
		'Clean Filter': emptyProps(),
		'Apply Filter': emptyProps(),
		'Get Venues': emptyProps(),
		'Load Venues': props<{ filterVenuesDto: FilterVenuesDto }>(),
		'Load Venues Success': props<{ venues: VenueFilter[] }>(),
		'Load Venues Fail': props<{ error: string }>(),
		'Filter Venues By Name': props<{ filterVenuesDto: FilterVenuesDto }>(),
		'Filter Venues By Name V2': props<{ name: string }>(),
		'Filter Venues By Name Success': props<{ venuesFilter: VenueFilter[] }>(),
		'Filter Venues By Name Fail': props<{ error: string }>(),
		'Filter Venues By Favorites': emptyProps(),
		'Filter Venues By Favorites Success': props<{ favoritesVenues: VenueFilter[] }>(),
		'Filter Venues By Favorites Failure': props<{ error: string }>(),
		'Clean Filter Venues By Name': emptyProps(),
		'Get One Venue': props<{
			venueId: string
			findOneVenueDto?: FindOneVenueDto
		}>(),
		'Load One Venue': props<{
			venueId: string
			findOneVenueDto?: FindOneVenueDto
		}>(),
		'Reset SelectedVenue': emptyProps(),
		'Load One Venue Success': props<{ venue: VenueDetail }>(),
		'Load One Venue Fail': props<{ error: string }>(),
		'Load More Venue': emptyProps(),
		'Load More Venue Success': props<{ venues: VenueFilter[] }>(),
		'Load More Venue Fail': props<{ error: string }>(),
		'Set Location': props<{ latitude: number; longitude: number; maxDistance: number }>(),

		// Benefits
		'Set Current Include Benefits': props<{ includeBenefits: BenefitsDto[] }>(),
		'Clean Include Benefits': emptyProps(),

		// Exlcude Benefits
		'Set Current Exclude Benefits': props<{ excludeBenefits: BenefitsDto[] }>(),
		'Clean Exclude Benefits': emptyProps(),

		// Categories
		'Set Current Categories': props<{ categories: string[] }>(),
		'Clean Categories': emptyProps(),

		// MichelinGuide
		'Set MichelinGuide': props<{ michelinGuide: boolean }>(),
		'Clean MichelinGuide': emptyProps(),

		// New Restaurant
		'Set NewRestaurant': props<{ newRestaurant: boolean }>(),
		'Clean NewRestaurant': emptyProps(),
	},
})
