import { inject } from '@angular/core'
import { CanActivateFn, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { isLoggedIn } from '../state/client/client.selectors'
import { map } from 'rxjs'
import { RedirectToken } from '../tokens/tokens'

export const authGuard: CanActivateFn = () => {
	const store = inject(Store)
	const router = inject(Router)
	const redirectUrl = inject(RedirectToken)
	return store.select(isLoggedIn).pipe(
		map(value => {
			if (!value) {
				return router.createUrlTree([redirectUrl])
			}
			return true
		})
	)
}
