<div class="boxArrow" *ngIf="type === 'signal'">
	<i class="fa fa-seta-acao boxArrow__icon"></i>
</div>

<i class="fa fa-feedback-error icon" *ngIf="type === 'error'"></i>
<i class="fa fa-no-signal icon iconSignal" *ngIf="type === 'signal'"></i>

<h1 class="title" *ngIf="type === 'error'">{{ title }}</h1>
<h1 class="title" *ngIf="type === 'signal'">Parece que você está sem internet</h1>
<p class="text">
	<ng-content></ng-content>
</p>

<p class="text textSignal" *ngIf="type === 'signal'">
	Não foi possível carregar as informações. Por favor, verifique sua conexão e tente novamente.
</p>
