import { Address } from '../vo/address'
import { CoverURL } from '../vo/coverURL'
import { VenuesFilterProperties } from './venues-filter.interface'
import { Benefits } from '../benefits/benefits'
import { isNewEntity } from '@monorepo-channels/shared/util-helpers'
import { MichelinGuide } from './venues-details'

export class VenueFilter {
	id: string
	name: string
	cuisines: string[]
	address: Address
	coverURL: CoverURL
	isNotEmpty: boolean
	benefits!: Benefits[]
	distance?: number
	category?: string
	michelinGuide?: MichelinGuide
	isNew: boolean
	constructor(props: VenuesFilterProperties | null) {
		// isNotEmpty
		this.isNotEmpty = Boolean(props)

		this.id = props?._id ?? ''
		this.name = props?.name ?? ''
		this.cuisines = props?.cousines.map(cuisine => cuisine.name) ?? ['']

		if (props) {
			this.benefits = props.benefits.map(benefit => new Benefits(benefit))
		}

		// address
		this.address = new Address(
			props?.address.city ?? '',
			props?.address.state ?? '',
			props?.address.neighborhood ?? '',
			props?.address.address ?? '',
			props?.address.number ?? ''
		)

		// coverURL
		this.coverURL = new CoverURL(
			props?.partner?.images?.thumb[0]?.coverUrl,
			props?.partner?.images?.thumb[0]?.optimizedCoverUrl
		)

		this.distance = props?.calcDistance ? props.calcDistance / 100 : undefined

		this.category = props?.partner.types.filter(
			type => !['celebrations', 'guia michelin', 'momento bbi'].includes(type.type.toLowerCase())
		)[0]?.type

		this.michelinGuide = props?.michelinGuide
		this.isNew = isNewEntity(props?.createdAt ?? undefined)
	}
}
