<bb-header></bb-header>
<bb-new-card-body></bb-new-card-body>

<ui-bb-modal-bottom-failure
	title="Não foi possível cadastrar o cartão"
	height="370px"
	#modalRegisterCardFailure
>
	<ng-container *ngIf="registerCardError$ | async as error">
		@if (error?.includes('Bin')) {
			Identificamos um problema com o BIN fornecido, e o cadastro do seu cartão não pôde ser concluído.
			Lamentamos o transtorno. Sugerimos que consulte os BIN válidos e tente novamente.
		} @else if (error?.includes('titular')) {
			{{ error }}
		} @else {
			Tivemos uma instabilidade e o cadastro de seu cartão não foi finalizado. Por favor, tente
			novamente mais tarde.
		}
	</ng-container>
</ui-bb-modal-bottom-failure>
