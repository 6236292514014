import { inject, Injectable } from '@angular/core'
import { MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer } from '@angular/platform-browser'

@Injectable({ providedIn: 'root' })
export class IconsService {
	constructor() {
		const domSanitizer = inject(DomSanitizer)
		const matIconRegistry = inject(MatIconRegistry)
		matIconRegistry.addSvgIconSetInNamespace(
			'pm-icons',
			domSanitizer.bypassSecurityTrustResourceUrl('@icons/pm-icons.svg')
		)
		matIconRegistry.addSvgIconSetInNamespace(
			'bb-icons',
			domSanitizer.bypassSecurityTrustResourceUrl('@icons/bb-icons.svg')
		)
	}
}
