import { Component, HostBinding, Input } from '@angular/core'
import { CommonModule } from '@angular/common'

@Component({
	selector: 'ui-bb-footer',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class FooterUiComponent {
	@Input({ required: true }) position!: 'static' | 'fixed' | 'absolute'

	@HostBinding('style')
	get styles() {
		if (this.position === 'static') {
			return { '--box-position': 'static', '--box-mt': 'auto', '--bg-color': 'transparent' }
		}
		if (this.position === 'absolute') {
			return { '--box-position': 'absolute', '--box-bottom': 0, '--bg-color': 'transparent' }
		}
		return { '--box-position': 'fixed', '--box-bottom': 0, '--bg-color': 'var(--background-primary)' }
	}
}
