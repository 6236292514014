import { capitalizeEachWord } from '@monorepo-channels/shared/util-helpers'
import { Category } from '../vo/category'
import { Agreements, ClientProps } from './client.interface'
interface ClientConfig {
	id: string
	firstName: string
	fullName: string
	email: string
	cpf: string
	phone: string | null
	token: string
	selectedCategory: Category | null
	cards: Category[]
	categories: Category[]
	agreements: Agreements
	favorites: string[]
	selectedCardDetails: Category | null
	// Programa Menu \/
	voucher: {
		id: string
		value: string
	} | null
}

export class Client {
	private constructor(private config: ClientConfig) {}

	static create(props: ClientProps): Client {
		const cards = props.categories.filter(category => category?.gatewayId)
		const mainCard = cards.find(card => card.mainCard)
		const selectedCard = mainCard ?? cards[0] ?? props.categories[0]
		const selectedCategory = selectedCard ? new Category(selectedCard) : null
		const categories = props.categories.map(card => new Category(card))
		const config: ClientConfig = {
			id: props._id,
			firstName: props.name,
			fullName: capitalizeEachWord(`${props.name} ${props.lastName}`),
			email: props.email,
			cpf: props.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'),
			phone: props?.phone ?? null,
			token: props?.access_token?.token,
			selectedCategory: selectedCategory,
			cards: props.categories.filter(category => category?.gatewayId).map(card => new Category(card)),
			categories: categories,
			agreements: props.agreements,
			favorites: props.favorites ?? [],
			selectedCardDetails: null,
			voucher:
				props.vouchers && props.vouchers.length > 0
					? { id: props.vouchers[0]._id, value: props.vouchers[0].value }
					: null,
		}
		return new Client(config)
	}

	/*
		Why: This method is used to select a card when partner doesn't have difference between categoryId inside cards.
		Like BB Gastronomia, Esfera, Latam, Unicred.
	*/
	selectCard(gatewayId: string, lastFourDigits: string): Client {
		const selectedCard = this.config.cards.find(
			card => card.gatewayId === gatewayId && card.lastFourDigits === lastFourDigits
		)
		if (!selectedCard) return this
		return new Client({ ...this.config, selectedCategory: selectedCard })
	}

	selectCategoryByCardName(name: 'principal' | 'centurion' | 'prime') {
		if (name === 'principal') {
			const principal = ['6744e23cb99a686e7fa630a1', '676035726bb1739e40b19d2d']
			return this.config.categories.find(category => principal.includes(category.categoryId))
		}
		if (name === 'centurion') {
			const centurion = ['64d3f4f23ef0bd49cf0f3a82']
			return this.config.categories.find(category => centurion.includes(category.categoryId))
		}
		return
	}

	isPrincipalSelected() {
		const principal = ['6744e23cb99a686e7fa630a1', '676035726bb1739e40b19d2d']
		return principal.includes(this.config.selectedCategory?.categoryId || '')
	}

	/*
		Why: For Programa Menu. We'd like to select automatically the card which has the most benefits.
	*/
	selectTheCardThatHasTheMostBenefits() {
		if (this.config.categories.length === 0) {
			return new Client({ ...this.config }) // Mantém o cliente sem alterações se não houver categorias
		}

		const registeredCards = this.config.categories.filter(c => c.gatewayId)
		const categoriesToConsider = registeredCards.length > 0 ? registeredCards : this.config.categories

		const bestCard = this.selectBestCard(categoriesToConsider)
		return new Client({ ...this.config, selectedCategory: bestCard })
	}

	// Método auxiliar para selecionar o melhor cartão de uma lista
	private selectBestCard(categories: Category[]) {
		if (categories.length === 0) return null

		// Encontrar o máximo de benefícios
		const maxBenefits = Math.max(...categories.map(c => c.benefits?.length || 0))
		const candidates = categories.filter(c => (c.benefits?.length || 0) === maxBenefits)

		// Ordenar candidatos por prioridade: Principal > Centurion > Outros
		candidates.sort((a, b) => {
			const aPriority = this.getCategoryPriority(a)
			const bPriority = this.getCategoryPriority(b)
			return bPriority - aPriority // Ordena em ordem decrescente
		})

		return candidates[0]
	}

	private getCategoryPriority(category: Category): number {
		const principalIds = ['6744e23cb99a686e7fa630a1', '676035726bb1739e40b19d2d']
		const centurionId = '64d3f4f23ef0bd49cf0f3a82'

		if (principalIds.includes(category.categoryId)) {
			return 3
		} else if (category.categoryId === centurionId) {
			return 2
		} else {
			return 1
		}
	}

	/*
		Why: This method is used to select a card when partner has difference between categoryId inside cards.
		Like Programa Menu (Bradesco). Each card should have a different categoryId.
	*/
	selectCategory(categoryId: string): Client {
		const selectedCategory = this.config.categories.find(category => category.categoryId === categoryId)
		if (!selectedCategory) return this
		return new Client({ ...this.config, selectedCategory })
	}

	toggleFavorite(venueId: string): Client {
		const favorites = this.config.favorites.includes(venueId)
			? this.config.favorites.filter(fav => fav !== venueId)
			: [...this.config.favorites, venueId]
		return new Client({ ...this.config, favorites })
	}

	isVenueFavorite(venueId: string): boolean {
		return this.config.favorites.includes(venueId)
	}

	get id(): string {
		return this.config.id
	}
	get firstName(): string {
		return this.config.firstName
	}
	get fullName(): string {
		return this.config.fullName
	}
	get email(): string {
		return this.config.email
	}
	get cpf(): string {
		return this.config.cpf
	}
	get phone(): string | null {
		return this.config.phone
	}
	get token(): string {
		return this.config.token
	}
	get selectedCategory(): Category | null {
		return this.config.selectedCategory
	}
	get cards(): Category[] {
		return this.config.cards
	}
	get categories(): Category[] {
		return this.config.categories
	}
	get agreements(): Agreements {
		return this.config.agreements
	}
	get favorites(): string[] {
		return this.config.favorites
	}
	get selectedCardDetails(): Category | null {
		return this.config.selectedCardDetails
	}
	get voucher() {
		return this.config.voucher
	}
}
