import { Component, Input, ElementRef, HostListener, EventEmitter, Output, HostBinding } from '@angular/core'
import { CommonModule } from '@angular/common'

@Component({
	selector: 'ui-bb-select',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './select.component.html',
	styleUrls: ['./select.component.scss'],
})
export class SelectUiComponent {
	constructor(private eRef: ElementRef) {}

	@Input() title?: string
	@Input() options?: { name: string; id: string }[]
	@Input() selectedOption = 'Estado'
	@Input() disabled = false
	@Output() optionSelected = new EventEmitter<{ name: string; id: string }>()

	isOpen = false

	@HostBinding('style')
	get styles() {
		if (this.disabled) {
			return {
				'--select-opacity': 0.5,
			}
		}
		return {}
	}

	@HostListener('document:click', ['$event'])
	clickOut(event: MouseEvent) {
		if (!this.eRef.nativeElement.contains(event.target)) {
			this.isOpen = false
		}
	}

	open() {
		if (this.disabled) return
		this.isOpen = !this.isOpen
	}

	selectOption(option: { name: string; id: string }) {
		this.selectedOption = option.name
		this.optionSelected.emit(option)
		this.isOpen = false
	}

	get inputClass(): string {
		return this.selectedOption && this.selectedOption.length > 16 ? 'small' : 'large'
	}
}
