<ng-container *ngIf="vm$ | async as vm">
	<!--<div class="px-16 page" *ngIf="vm.order && vm.statusCancel !=='failure'">
	</div>-->
	<div style="height: calc(100vh - 152px); margin: 0" *ngIf="vm.statusCancel === 'pending'">
		<ui-bb-loading-page>Cancelando reserva</ui-bb-loading-page>
	</div>
	<div *ngIf="vm.statusCancel === 'failure'">
		<ui-bb-generic-error type="error"> </ui-bb-generic-error>
		<ui-bb-footer position="fixed">
			<ui-bb-button type="primary" style="width: 220px" [routerLink]="['/reservas-e-pagamento']">
				Tentar mais tarde
			</ui-bb-button>
		</ui-bb-footer>
	</div>

	<div class="page px-16" style="padding-bottom: 12px" *ngIf="vm.statusCancel !== 'pending'">
		<ng-container *ngIf="vm.order">
			<!--<ng-container *ngIf="vm.order.wasPaid">
				<h1 class="subTitle">
					Detalhes
					<span *ngIf="vm.order.intent === 'Reserva de Mesa'">da reserva</span>
					<span *ngIf="vm.order.intent === 'CheckIn'">do check-in</span>
				</h1>
				<h2>{{ vm.order.venue.name }}</h2>

				<ui-bb-box-info
					[info]="vm.order.payment!.totalValue / 100 | currency: 'BRL'"
					title="Valor total pago"
				/>
				<ui-common-divisor />
				<ui-bb-box-info [info]="vm.order.payment?.code" title="Código de pagamento" />
				<ui-common-divisor />
				<div>
					<h4 class="titleAlert">Você receberá</h4>
					<p class="infoAlert">{{ vm.order.payment?.points }} pontos</p>
				</div>
			</ng-container>-->

			<h1 class="subTitle">
				Detalhes
				<span *ngIf="vm.order.intent === 'Reserva de Mesa'">da reserva</span>
				<span *ngIf="vm.order.intent === 'CheckIn'">do check-in</span>
			</h1>

			<div class="headerInfo">
				<h2 class="venueTitle">{{ vm.order.venue.name }}</h2>
				<p *ngIf="vm.order.wasPaid" class="headerInfo__info">
					{{ vm.order.date.date | date: 'dd/MM/yyyy' }} Reserva às {{ vm.order.date.time }}
				</p>
			</div>
			<ui-common-divisor />

			<ng-container *ngIf="vm.order.wasPaid">
				<div class="info" style="margin-top: 12px">
					<p class="info__title">Valor da conta</p>
					<p class="info__text">{{ vm.order.payment!.intialValue / 100 | currency: 'BRL' }}</p>
				</div>
				<div class="info" style="margin-top: 12px">
					<p class="info__title">Taxa de serviço</p>
					<p class="info__text">{{ vm.order.payment!.gratuityValue / 100 | currency: 'BRL' }}</p>
				</div>
				<div class="info" style="margin-top: 12px">
					<p class="info__title">Valor Total</p>
					<p class="info__text">{{ vm.order.payment!.totalValue / 100 | currency: 'BRL' }}</p>
				</div>
				<div class="info" style="margin-top: 12px">
					<p class="info__title">Cartão de crédito</p>
					<div class="info__box">
						<i style="font-size: 4px" class="fa fa-combined-shape"></i>
						<p class="info__box__text">{{ vm.order.payment?.lastFourDigits }}</p>
					</div>
				</div>

				<div class="info" style="margin-top: 12px">
					<div class="info__tooltip">
						<p class="info__tooltip__title livelo">Pontos livelo</p>
						<ui-bb-tooltip>O total de pontos não inclui a Taxa de serviço.</ui-bb-tooltip>
					</div>
					<p class="info__text livelo">{{ vm.order.payment?.points }}</p>
				</div>
			</ng-container>

			<ng-container *ngIf="!vm.order.wasPaid">
				<div class="info" style="margin-top: 12px">
					<p class="info__title">Mesa para</p>
					<p class="info__text" *ngIf="vm.order.partySize === 1">{{ vm.order.partySize }} pessoa</p>

					<p class="info__text" *ngIf="vm.order.partySize !== 1">
						{{ vm.order.partySize }} pessoas
					</p>
				</div>

				<div class="info" style="margin-top: 12px">
					<p class="info__title">Data</p>
					<p class="info__text">{{ vm.order.reservationDateTime | date }}</p>
				</div>

				<div class="info" style="margin-top: 12px" *ngIf="vm.order.sectionLabel">
					<p class="info__title">Ambiente</p>
					<p class="info__text">{{ vm.order.sectionLabel }}</p>
				</div>
			</ng-container>

			<div class="info" style="margin-top: 12px">
				<p class="info__title">Horário</p>
				<p class="info__text">{{ vm.order.date.time }}</p>
			</div>

			<div class="info" style="margin-top: 12px">
				<p class="info__title">Endereço</p>
				<p class="info__text">{{ vm.order.venue.address.value }}</p>
			</div>

			<ng-container *ngIf="vm.order.wasPaid">
				<div class="info" style="margin: 12px 0">
					<p class="info__title">Cód. Pagamento</p>
					<p class="info__text">{{ vm.order.payment?.code }}</p>
				</div>
			</ng-container>
			<ng-container *ngIf="!vm.order.wasPaid">
				<ui-bb-benefits [benefits]="vm.order.benefits"></ui-bb-benefits>
			</ng-container>
		</ng-container>
	</div>

	<ng-container *ngIf="vm.statusCancel !== 'pending'">
		<ui-bb-footer [position]="isMobile ? 'fixed' : 'static'">
			<ui-bb-button
				type="primary"
				style="width: 163px"
				(clickButton)="goToPayment(vm.order)"
				*ngIf="vm.order?.canPay"
			>
				PAGAR CONTA
			</ui-bb-button>
			<ui-bb-button
				type="danger"
				*ngIf="vm.order?.canCancel$ | async"
				style="width: 163px"
				(clickButton)="openCancel()"
			>
				CANCELAR
			</ui-bb-button>

			<ui-bb-button
				*ngIf="vm.order?.canPay === false || (vm.order?.canCancel$ | async) === false"
				type="secondary-ghost"
				(clickButton)="returnDetails()"
				[ngStyle]="
					vm.order?.canPay === false && (vm.order?.canCancel$ | async) === false
						? { width: '343px' }
						: { width: '163px' }
				"
			>
				VOLTAR
			</ui-bb-button>
		</ui-bb-footer>
	</ng-container>

	<ui-bb-modal-bottom title="Cancelar reserva" height="auto" #cancelOrderModal>
		<div class="bodyModal">
			<p class="bodyModal__text">Tem certeza que deseja cancelar sua reserva no restaurante?</p>
			<div class="boxVenue">
				<p class="boxVenue__name">{{ vm.order?.venue?.name }}</p>
				<p class="boxVenue__address">{{ vm.order?.venue?.address?.value }}</p>
			</div>

			<div class="info" style="margin: 4px 0 4px">
				<p class="info__title">Data</p>
				<p class="info__text">{{ vm.order?.reservationDateTime | date }}</p>
			</div>

			<div class="info" style="padding: 0">
				<p class="info__title">Horário</p>
				<p class="info__text">{{ vm.order?.date?.time }}</p>
			</div>
		</div>

		<ui-bb-footer position="static">
			<ui-bb-button type="secondary" style="width: 163px" (clickButton)="cancelOrderModal.closeModal()">
				SAIR
			</ui-bb-button>
			<ui-bb-button type="primary" style="width: 163px" (clickButton)="cancelOrder(vm.order)">
				SIM, CANCELAR
			</ui-bb-button>
		</ui-bb-footer>
	</ui-bb-modal-bottom>

	<ui-bb-modal-bottom
		title="Cadastre o seu cartão"
		#modalRegisterCard
		style="color: var(--primitives-grey-500)"
		height="250px"
	>
		<p style="line-height: 20px; margin-top: 24px">
			Olá! Para pagar e ganhar os pontos Livelo, será necessário cadastrar um cartão de crédito
			Ourocard.
		</p>
		<ui-bb-footer position="static" footer style="padding: 0 16px">
			<ui-bb-button
				type="secondary"
				style="width: 100%; font-size: 12px; margin: 0 16px"
				(clickButton)="registerCard()"
			>
				CONTINUAR
			</ui-bb-button>
		</ui-bb-footer>
	</ui-bb-modal-bottom>
</ng-container>
