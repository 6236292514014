import { Component } from '@angular/core'

import { HeaderComponent } from '../../../header/header.component'
import { OrderDetailsBodyComponent } from './order-history-body/order-details-body.component'

@Component({
	standalone: true,
	selector: 'bb-order-details-page',
	imports: [HeaderComponent, OrderDetailsBodyComponent],
	templateUrl: './order-details.page.html',
})
export class OrderDetailsPageComponent {}
