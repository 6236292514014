import { Injectable } from '@angular/core'

@Injectable({ providedIn: 'root' })
export class DataLayerService {
	constructor() {}

	private pingHome(obj: unknown) {
		if (obj) (window as unknown as Window & { dataLayer: unknown[] }).dataLayer.push(obj)
	}

	//list of all our dataLayer methods

	logPageView(url: string) {
		const hit = {
			event: 'content-view',
			pageName: url,
		}
		this.pingHome(hit)
	}

	logEvent(logEvent: { event: string; [key: string]: unknown }) {
		this.pingHome(logEvent)
	}

	logCustomDimensionTest(value: unknown) {
		const hit = {
			event: 'custom-dimension',
			value: value,
		}
		this.pingHome(hit)
	}
}
