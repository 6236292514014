import { Injectable } from '@angular/core'
import { HttpParams } from '@angular/common/http'
import { environment } from '@monorepo-channels/shared/util-environments'
import { PKCEService } from '@monorepo-channels/shared/util-helpers'
import { v4 as uuidv4 } from 'uuid'

export const keys = {
	code_verifier: 'code_verifier',
	goBackUrl: 'go_back_url',
} as const

@Injectable({
	providedIn: 'root',
})
export class AuthLiveloService {
	constructor(private pkceService: PKCEService) {}

	async getAuthorizationCode() {
		const { codeVerifier, codeChallenge } = await this.pkceService.pkceChallenge()
		localStorage.setItem(keys.code_verifier, codeVerifier)
		localStorage.setItem(
			keys.goBackUrl,
			window.location.pathname.includes('login-bb') ? '' : window.location.pathname
		)
		const params = new HttpParams()
			.set('client_id', environment.livelo.clientId)
			.set('scope', 'openid')
			.set('response_type', 'code')
			.set('response_mode', 'fragment')
			.set('code_challenge_method', 'S256')
			.set('code_challenge', codeChallenge)
			.set('state', uuidv4())
			.set('nonce', uuidv4())
			.set('redirect_uri', environment.livelo.redirectUri)

		const authUrl = `${
			environment.livelo.auth
		}/auth/realms/LIV_PF/protocol/openid-connect/auth?${params.toString()}`
		window.location.href = authUrl
	}
}
