import { Component, Input, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'

import { VenueDetail } from '@monorepo-channels/channels/domain'

import {
	CalendarIconSVGComponent,
	CheckinIconSVGComponent,
	DivisorUiComponent,
} from '@monorepo-channels/components/ui-common'
import { BenefitsUiComponent } from '../benefits/benefits.component'
import { BoxInfoUiComponent } from '../box-info/box-info.component'
import { ButtonUiComponent } from '../button/button.component'
import { FooterUiComponent } from '../footer/footer.component'
import { TitleUiComponent } from '../title/title.component'
import { CapitalizeFirstPipe } from '@monorepo-channels/shared/util-helpers'

@Component({
	standalone: true,
	selector: 'ui-bb-order-success',
	imports: [
		CommonModule,
		TitleUiComponent,
		CalendarIconSVGComponent,
		BenefitsUiComponent,
		FooterUiComponent,
		ButtonUiComponent,
		BoxInfoUiComponent,
		DivisorUiComponent,
		CheckinIconSVGComponent,
		CapitalizeFirstPipe,
	],
	templateUrl: './order-success.page.html',
	styleUrls: ['./order-success.page.scss'],
})
export class OrderSuccessComponent implements OnInit {
	@Input({ required: true }) type!: 'reservation' | 'checkin'
	@Input({ required: true }) partySize!: number
	@Input({ required: true }) orderDate!: Date | string
	@Input({ required: false }) sectionLabel?: string //Check-In doen't have sectionLabel
	@Input({ required: true }) reservationTime!: string
	@Input({ required: true }) venue!: VenueDetail

	ngOnInit(): void {
		this.orderDate =
			typeof this.orderDate === 'string'
				? new Date(`${this.orderDate}T10:00:00Z`)
				: new Date(this.orderDate)
	}

	constructor() {
		// this.partySize = 3
		// this.orderDate = new Date()
		// this.reservationTime = '12:00'
		// this.venue = {
		// 	name: 'Venue Name',
		// 	address: { value: 'Rua Jacques Félix, 405, 405, Vila Nova Conceicao, São Paulo, SP' },
		// 	benefits: [{ value: 'Pontos', type: 'points' }],
		// } as VenueDetail
		// this.type = 'checkin'
	}
}
