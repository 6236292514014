import { HttpClient } from '@angular/common/http'
import { Observable, map } from 'rxjs'
import { Injectable, inject } from '@angular/core'
import { environment } from '@monorepo-channels/shared/util-environments'
import { VenueDetail } from '../models/venues/venues-details'
import { VenueDetailProps } from '../models/venues/venues-details.interface'
import { VenueFilter } from '../models/venues/venues-filter'
import { VenuesFilterProperties } from '../models/venues/venues-filter.interface'
import { PartnerValues, PartnerToken } from '@monorepo-channels/shared/util-helpers'

export const DEFAULT_LIMIT = 10

@Injectable({ providedIn: 'root' })
export class VenuesGateway {
	private baseURL = `${environment.channelsAPIURL}/api/v1/venues`
	private partnerId = inject(PartnerToken)
	private http = inject(HttpClient)
	getVenueId(venueId: string, findOneVenueDto?: FindOneVenueDto | undefined): Observable<VenueDetail> {
		const body: FindOneVenueDto = {
			partner: this.partnerId,
			...findOneVenueDto,
		}
		return this.http
			.post<VenueDetailProps>(`${this.baseURL}/${venueId}`, body)
			.pipe(map(venueData => new VenueDetail(venueData, this.partnerId)))
	}

	getCuisines(): Observable<Cuisine[]> {
		return this.http.get<Cuisine[]>(`${this.baseURL}/cousines`)
	}

	filter(filterVenuesDto?: FilterVenuesDto): Observable<VenueFilter[]> {
		const body: FilterVenuesDto = {
			venuesAlreadyInView: [],
			limit: DEFAULT_LIMIT,
			...filterVenuesDto,
		}
		return this.http
			.post<VenuesFilterProperties[]>(`${this.baseURL}/filter`, body)
			.pipe(map(venuesData => venuesData.map(venue => new VenueFilter(venue))))
	}
}

export interface Cuisine {
	_id: string
	name: string
}

export interface BenefitsDto {
	name: string
}

export interface FindOneVenueDto {
	partner?: PartnerValues
	categoryId?: string
	clientId?: string
}

export interface FilterVenuesDto {
	partner?: PartnerValues
	clientId?: string
	cousine?: string | Array<string | undefined>
	name?: string
	address?: {
		state?: string
		city?: string
		neighborhood?: string
		address?: string
		location?: { latitude: number; longitude: number; maxDistance: number }
	}
	type?: Array<string>
	categoryId?: string
	skip?: number
	limit?: number
	venuesAlreadyInView?: Array<string>
	favOnly?: boolean
	michelinGuide?: boolean
}
