import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http'
import { inject } from '@angular/core'
import { tap } from 'rxjs'
import { AuthService } from '../auth/auth.service'
import { Store } from '@ngrx/store'
import { ClientActions } from '../state/client/client.actions'

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
	const authService = inject(AuthService)

	const token = authService.getToken()
	const store = inject(Store)
	if (token) {
		req = req.clone({ setHeaders: { Authorization: `Bearer ${token}` } })
	}
	return next(req).pipe(
		tap({
			error: (err: unknown) => {
				if (err instanceof HttpErrorResponse) {
					if (err.status === 401 || err.status === 403) {
						store.dispatch(ClientActions.logoutClient())
					}
				}
			},
		})
	)
}
