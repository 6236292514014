import { Address } from '../vo/address'
import { Benefits } from '../benefits/benefits'
import { ORDER_PAYMENT_STATUS, OrderFromHistoryProps } from './order-history.interface'
import { Observable, interval, map, startWith } from 'rxjs'

export class OrderHistory {
	private constructor(
		readonly id: string,
		readonly intent: string,
		readonly venue: { name: string; address: Address; id: string },
		readonly date: { day: string; month: string; time: string; date: Date },
		readonly reservationDateTime: number,
		readonly hasPaymentConfig: boolean,
		readonly wasPaid: boolean,
		readonly canPay: boolean,
		readonly paymentBlocked: boolean,
		readonly benefits: Benefits[],
		readonly gratuityPercent: number,
		readonly canCancel$: Observable<boolean>,
		readonly points?: number | undefined,
		readonly payment?: {
			points: number
			lastFourDigits: string
			code: string
			intialValue: number
			gratuityValue: number
			totalValue: number
		},
		readonly partySize?: number,
		readonly sectionLabel?: string,
		readonly removeGratuityValue?: boolean
	) {}

	static create(props: OrderFromHistoryProps) {
		const id = props?._id
		// address
		const address = new Address(
			props?.venue.address.city ?? '',
			props?.venue.address.state ?? '',
			props?.venue.address.neighborhood ?? '',
			props?.venue.address.address ?? '',
			props?.venue.address.number ?? ''
		)
		const name = props?.venue.name
		const intent = this.getIntent(props?.intent)
		const date = this.getDayAndMonth(new Date(props?.reservationDay), props?.reservationTime)
		const reservationTime = this.getReservationDateTime(props?.reservationDay, props.reservationTime)
		const wasPaid = this.checkWasPaid(props?.paymentStatus)
		const hasPaymentConfig = props?.venueHasPaymentConfig ?? false
		const canPay = this.canPayFn(props?.paymentStatus, hasPaymentConfig)
		const paymentBlocked = this.paymentBlockedFn(props?.paymentStatus, hasPaymentConfig)
		const benefits = props.benefits.map(benefit => new Benefits(benefit))
		const gratuityPercent = props?.gratuityPercent ?? 10
		const partySize = props?.partySize
		const sectionLabel = props?.sectionLabel
		let payment
		if (props?.payment) {
			payment = {
				points: props.payment.orderTotalPoints,
				lastFourDigits: props.payment.lastFourDigits,
				code: props.payment.code,
				intialValue: props.payment.orderInitialValue,
				gratuityValue: props.payment.gratuityValue,
				totalValue: props.payment.orderTotalValue,
			}
		}
		const canCancel$ = interval(1000).pipe(
			startWith(0),
			map(() => Date.now()),
			map(NOW => NOW <= reservationTime),
			map(value => (wasPaid ? false : value))
		)
		return new OrderHistory(
			id,
			intent,
			{ name, address, id: props.venueId },
			date,
			reservationTime,
			hasPaymentConfig,
			wasPaid,
			canPay,
			paymentBlocked,
			benefits,
			gratuityPercent,
			canCancel$,
			props.payment?.orderTotalPoints,
			payment,
			partySize,
			sectionLabel,
			props.removeGratuityValue
		)
	}

	private static getIntent(intent: string) {
		if (intent === 'reservation') {
			return 'Reserva de Mesa'
		}
		if (intent === 'checkin') {
			return 'CheckIn'
		}
		if (intent === 'waitlist') {
			return 'Fila de Espera'
		}
		return 'Order sem intent'
	}

	private static checkWasPaid(paymentStatus: ORDER_PAYMENT_STATUS) {
		return paymentStatus === 'DONE'
	}

	private static canPayFn(paymentStatus: ORDER_PAYMENT_STATUS, hasPaymentConfig: boolean) {
		if (!hasPaymentConfig) {
			return false
		}
		return paymentStatus === 'READY' || paymentStatus === 'FAILED'
	}

	private static paymentBlockedFn(paymentStatus: ORDER_PAYMENT_STATUS, hasPaymentConfig: boolean) {
		if (!hasPaymentConfig) {
			return true
		}
		return paymentStatus === 'NOT_DONE' || paymentStatus === 'NOT_DONE_FAILED'
	}

	private static getDayAndMonth(date: Date, time: string) {
		const day = String(date.getUTCDate()).padStart(2, '0')
		const month = date.toLocaleString('pt-br', { month: 'short' }).toUpperCase().slice(0, -1)
		return { day, month, date, time }
	}

	private static getReservationDateTime(reservationDay: string, reservationTime: string): number {
		if (!reservationDay) {
			// reservationTime always in the future for return FALSE in canDay$
			return Infinity
		}
		const [day] = reservationDay.split('T')
		return Date.parse(`${day}T${reservationTime}`)
	}
}
