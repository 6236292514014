import { Status } from '../helper.state'
import { PaymentOrder } from './payment'

export const featurePaymentName = 'payment'

export interface PaymentState {
	paymentOrder: PaymentOrder | null
	statusPayment: Status
	errorPayment: string | null
}

export const initialStatePayment: PaymentState = {
	paymentOrder: null,
	statusPayment: 'idle',
	errorPayment: null,
}
