import { createFeatureSelector, createSelector } from '@ngrx/store'
import { ReservationState, featureReservationName } from './reservation.state'
import { Reservation } from '../../models/vo/reservation'

const getAvailabilityFeatureState = createFeatureSelector<ReservationState>(featureReservationName)

export const getStatusAvailabilities = createSelector(
	getAvailabilityFeatureState,
	state => state.statusAvailabilities
)

export const getStatusConfirmReservation = createSelector(
	getAvailabilityFeatureState,
	state => state.statusConfirmReservation
)

export const isLoadingAvailabililities = createSelector(
	getAvailabilityFeatureState,
	state => state.statusAvailabilities === 'pending'
)

export const isLoadingConfirmReservation = createSelector(
	getAvailabilityFeatureState,
	state => state.statusConfirmReservation === 'pending'
)
export const getAvailabilities = createSelector(getAvailabilityFeatureState, state => state.availabilities)
export const getAvailabilitiesDates = createSelector(getAvailabilityFeatureState, state => {
	return state.availabilities?.map(availability => {
		const [year, month, day] = availability.reservationDay.split('-')
		return new Date(Number(year), Number(month) - 1, Number(day))
	})
})
export const getSelectedDate = createSelector(getAvailabilityFeatureState, state => state.selectedDate)
export const getSections = createSelector(getAvailabilityFeatureState, state => state.sections)
export const getSelectedSection = createSelector(
	getAvailabilityFeatureState,
	state => state.selectedSectionLabel
)
export const getSchedules = createSelector(getAvailabilityFeatureState, state => state.schedules)
export const getSelectedPartySize = createSelector(
	getAvailabilityFeatureState,
	state => state.selectedPartySize
)
export const getReservationTimes = createSelector(
	getAvailabilityFeatureState,
	state => state.reservationTimes
)
export const getSelectedReservationTime = createSelector(
	getAvailabilityFeatureState,
	state => state.selectedReservationTime
)
export const getErrorAvailability = createSelector(
	getAvailabilityFeatureState,
	state => state.errorAvailability
)
export const getErrorConfirmation = createSelector(
	getAvailabilityFeatureState,
	state => state.errorConfirmation
)
export const getOrderIdAndVenueId = createSelector(getAvailabilityFeatureState, state => ({
	orderId: state.orderId,
	venueId: state.venueId,
}))

export const getConfirmReservation = createSelector(getAvailabilityFeatureState, state => {
	return new Reservation(
		state.selectedPartySize ? Number(state.selectedPartySize) : null,
		state.selectedSectionLabel,
		state.selectedDate,
		state.selectedReservationTime,
		state.selectedSectionId
	)
})

export const getMinMaxPartySize = createSelector(getAvailabilityFeatureState, state => {
	if (!state.schedules) return null
	const partySizes = state.schedules.map(schedule => schedule.partySize)
	return {
		max: Math.max(...partySizes),
		min: Math.min(...partySizes),
	}
})
