<ui-bb-modal-bottom [height]="height ?? '270px'" #modalBottom>
	<div style="display: flex; align-items: center">
		<ui-common-close-fill />
		<p class="title">{{ title }}</p>
	</div>
	<p class="content">
		<ng-content></ng-content>
	</p>
	<ui-bb-footer position="static" footer>
		<ui-bb-button
			footer
			type="secondary"
			style="width: 100%; padding: 0 16px"
			(clickButton)="clickButton()"
		>
			{{ buttonTxt ?? 'TENTAR NOVAMENTE' }}
		</ui-bb-button>
	</ui-bb-footer>
</ui-bb-modal-bottom>
