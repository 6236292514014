import { Component, EventEmitter, HostListener, Output, ViewChild, inject } from '@angular/core'
import { CommonModule } from '@angular/common'

import { Store } from '@ngrx/store'
import { ClientActions, getClient } from '@monorepo-channels/channels/domain'
import { ActivatedRoute, Router, RouterLink } from '@angular/router'
import {
	AlertBoxUiComponent,
	FooterUiComponent,
	ButtonUiComponent,
	TitleUiComponent,
	BoxIconUiComponent,
	AlertTopUiComponent,
	BoxMessageComponent,
	ModalBottomUiComponent,
	ModalCenterComponent,
} from '@monorepo-channels/components/ui-bb'

import { Actions, ofType } from '@ngrx/effects'
import { of, delay, map, startWith, switchMap } from 'rxjs'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { BackgroundCardSVGComponent, WalletSVGComponent } from '@monorepo-channels/components/ui-common'
import { isMobile } from '@monorepo-channels/shared/util-helpers'

function FiveSeconds() {
	return of(true).pipe(
		delay(5000),
		map(() => false),
		startWith(true)
	)
}

@Component({
	selector: 'bb-wallet-body',
	standalone: true,
	imports: [
		CommonModule,
		AlertBoxUiComponent,
		FooterUiComponent,
		ButtonUiComponent,
		TitleUiComponent,
		BoxIconUiComponent,
		AlertTopUiComponent,
		RouterLink,
		WalletSVGComponent,
		BoxMessageComponent,
		BackgroundCardSVGComponent,
		ModalBottomUiComponent,
		ModalCenterComponent,
	],
	templateUrl: './wallet-body.component.html',
	styleUrls: ['./wallet-body.component.scss'],
})
export class WalletBodyComponent {
	private store = inject(Store)
	router = inject(Router)
	route = inject(ActivatedRoute)
	client$ = this.store.select(getClient)
	modal = {
		title: '',
		text: '',
		buttonText: '',
		click: () => {},
	}

	@Output() mainClicked = new EventEmitter<string>()
	@Output() deleteClicked = new EventEmitter<string>()
	@Output() registerCardClicked = new EventEmitter<void>()

	isMobile = isMobile()
	@HostListener('window:resize', ['$event'])
	onResize() {
		this.isMobile = isMobile()
	}

	openModal = false

	@ViewChild(ModalBottomUiComponent)
	modalBottomComponent!: ModalBottomUiComponent

	private actions$ = inject(Actions)
	mainCardSuccess$ = this.actions$.pipe(
		ofType(ClientActions.updateMainCardSuccess),
		takeUntilDestroyed(),
		switchMap(FiveSeconds)
	)

	maincardFailure$ = this.actions$.pipe(
		ofType(ClientActions.updateMainCardFailure),
		takeUntilDestroyed(),
		switchMap(FiveSeconds)
	)

	removeCardSuccess$ = this.actions$.pipe(
		ofType(ClientActions.removeCardSuccess),
		takeUntilDestroyed(),
		switchMap(FiveSeconds)
	)

	removeCardFailure$ = this.actions$.pipe(
		ofType(ClientActions.removeCardFailure),
		takeUntilDestroyed(),
		switchMap(FiveSeconds)
	)

	clickModalDefault(lastFourDigits: string | undefined) {
		if (!lastFourDigits) return
		this.mainClicked.emit(lastFourDigits)
	}

	clickModalDelete(lastFourDigits: string | undefined) {
		if (!lastFourDigits) return
		this.deleteClicked.emit(lastFourDigits)
	}

	registerCard() {
		this.registerCardClicked.emit()
	}
}
