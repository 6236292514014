<div class="page px-16">
	<h1 class="bbTitle">Como funciona</h1>
</div>

<ui-bb-banner [banner]="'works'"></ui-bb-banner>

<div class="page px-16">
	<div class="boxInfo">
		<div class="info">
			<p class="info__number">1</p>
			<div class="info__text">
				<p class="info__text__title">Escolha o restaurante</p>
				<p class="info__text__body">que deseja perto de você</p>
			</div>
		</div>
		<div class="info">
			<p class="info__number">2</p>
			<div class="info__text">
				<p class="info__text__title">Selecione a data</p>
				<p class="info__text__body">e faça uma reserva ou check-in</p>
			</div>
		</div>
		<div class="info">
			<p class="info__number">3</p>
			<div class="info__text">
				<p class="info__text__title">Pague a conta pelo BB Gastronomia com seu Ourocard</p>
				<p class="info__text__body">em “Reservas e pagamento” no Menu e aproveite!</p>
			</div>
		</div>
		<div class="info">
			<p class="info__number">4</p>
			<div class="info__text">
				<p class="info__text__title">Ganhe benefícios</p>
				<p class="info__text__body">acumule pontos Livelo e ganhe benefícios nos restaurantes</p>
			</div>
		</div>
	</div>
</div>
<ui-bb-footer [position]="isMobile ? 'fixed' : 'static'" *ngIf="isMobile">
	<ui-bb-button type="secondary" style="width: 343px" (clickButton)="router.navigate(['/'])" footer>
		VER RESTAURANTES
	</ui-bb-button>
</ui-bb-footer>
