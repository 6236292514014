import { Address } from '../vo/address'
import { CoverURL } from '../vo/coverURL'
import { VenuesFilterProperties } from './venues-filter.interface'
import { Benefits } from '../benefits/benefits'
import { isNewEntity } from '@monorepo-channels/shared/util-helpers'
import { MichelinGuide } from './venues-details'
import { hasCelebrations, isOnlyCelebration } from './venue-service'

export class VenueFilter {
	id: string
	name: string
	cuisines: string[]
	address: Address
	coverURL: CoverURL
	isNotEmpty: boolean
	benefits!: Benefits[]
	distance?: number
	category?: string
	// only for Programa Menu
	isOnlyCelebration: boolean
	hasCelebrations: boolean
	michelinGuide?: MichelinGuide
	isNew: boolean
	constructor(props: VenuesFilterProperties | null) {
		// isNotEmpty
		this.isNotEmpty = Boolean(props)

		this.id = props?._id ?? ''
		this.name = props?.name ?? ''
		this.cuisines = props?.cousines.map(cuisine => cuisine.name) ?? ['']

		if (props) {
			this.benefits = props.benefits.map(benefit => new Benefits(benefit))
		}

		// address
		this.address = new Address(
			props?.address.city ?? '',
			props?.address.state ?? '',
			props?.address.neighborhood ?? '',
			props?.address.address ?? '',
			props?.address.number ?? ''
		)

		// coverURL
		this.coverURL = new CoverURL(
			props?.partner?.images?.thumb[0]?.coverUrl,
			props?.partner?.images?.thumb[0]?.optimizedCoverUrl
		)

		this.distance = props?.calcDistance ? props.calcDistance / 1000 : undefined

		// Filtra os tipos do parceiro, removendo aqueles que estão na lista proibida (case-insensitive),
		// e define this.category como o tipo do primeiro elemento restante, ou undefined se nenhum for válido.
		// A lista proibida inclui 'celebrations', 'guia michelin' e 'momento bbi'.
		// O encadeamento opcional previne erros caso props, partner, types ou o resultado do filtro sejam undefined.
		this.category = props?.partner.types.filter(
			type =>
				!['celebrations', 'guia michelin', 'momento bbi', 'aspiracional'].includes(
					type.type.toLowerCase()
				)
		)[0]?.type

		this.isOnlyCelebration = isOnlyCelebration(props?.partner.types, props?.benefits)
		this.hasCelebrations = hasCelebrations(props?.partner.types)

		this.michelinGuide = props?.michelinGuide
		this.isNew = isNewEntity(props?.createdAt ?? undefined)
	}
}
