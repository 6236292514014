import { createFeatureSelector, createSelector } from '@ngrx/store'
import { AddressState, featureAddressName } from './address.state'

const getAddressFeatureState = createFeatureSelector<AddressState>(featureAddressName)
export const getAddresses = createSelector(getAddressFeatureState, state => state.addresses)
export const getSelectedAddress = createSelector(getAddressFeatureState, state => state.selectedAddress)
export const getErrorAddress = createSelector(getAddressFeatureState, state => state.error)
export const getStatusAddress = createSelector(getAddressFeatureState, state => state.status)
export const getSelectedNeighborhood = createSelector(
	getAddressFeatureState,
	state => state.selectedNeighborhood
)
