<button
	class="btn"
	[ngClass]="{
		'btn--rounded': rounded,
		'btn--primary': type === 'primary',
		'btn--secondary': type === 'secondary',
		'btn--secondary-ghost': type === 'secondary-ghost',
		'btn--tertiary': type === 'tertiary',
		'btn--quaternary': type === 'quaternary',
		'btn--danger': type === 'danger',
		'btn--primary--disabled': type === 'primary' && (disabled || styleDisabled),
		'btn--primary--selected': type === 'primary' && selected,
		'btn--secondary--disabled': type === 'secondary' && (disabled || styleDisabled),
		'btn--tertiary-selected': type === 'tertiary' && selected
	}"
	(click)="click($event)"
	[disabled]="disabled"
>
	<ng-content></ng-content>
</button>
