import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'formatPartySize', standalone: true })
export class FormatPartySize implements PipeTransform {
	transform(partySize: number | null) {
		if (!partySize) {
			return ''
		} else {
			return partySize <= 1 ? `${partySize} pessoa` : `${partySize} pessoas`
		}
	}
}
