import { createReducer, on } from '@ngrx/store'
import { OrderHistoryState, initialStateOrderHistory } from './order-history.state'
import { OrderHistoryActions } from './order-history.actions'

export const orderHistoryReducer = createReducer<OrderHistoryState>(
	initialStateOrderHistory,
	on(OrderHistoryActions.setSelectedOrder, (state, action): OrderHistoryState => {
		return {
			...state,
			selectedOrder: action.order,
		}
	}),
	on(OrderHistoryActions.setSelectedOrderId, (state, action): OrderHistoryState => {
		const order = state.orders?.find(order => order.id === action.orderId) ?? null
		return {
			...state,
			selectedOrder: order,
		}
	}),
	on(OrderHistoryActions.loadOrders, (state): OrderHistoryState => {
		return {
			...state,
			statusOrderHistory: 'pending',
		}
	}),
	on(OrderHistoryActions.loadOrdersSuccess, (state, action): OrderHistoryState => {
		return {
			...state,
			orders: action.orders,
			ordersToPay: action.orders.filter(order => order.canPay),
			statusOrderHistory: 'success',
			errorOrderHistory: null,
		}
	}),
	on(OrderHistoryActions.loadOrdersFailure, (state, action): OrderHistoryState => {
		return {
			...state,
			orders: null,
			statusOrderHistory: 'failure',
			errorOrderHistory: action.error,
		}
	}),
	on(OrderHistoryActions.cancelOrder, (state): OrderHistoryState => {
		return {
			...state,
			statusCancelOrder: 'pending',
		}
	}),
	on(OrderHistoryActions.cancelOrderSuccess, (state): OrderHistoryState => {
		return {
			...state,
			statusCancelOrder: 'success',
		}
	}),
	on(OrderHistoryActions.cancelOrderFailure, (state, action): OrderHistoryState => {
		return {
			...state,
			errorCancelOrder: action.error,
			statusCancelOrder: 'failure',
		}
	}),
	on(OrderHistoryActions.getOrdersPaymentReadySuccess, (state, action): OrderHistoryState => {
		return {
			...state,
			hasPaymentReady: action.hasPaymentReady,
		}
	})
)
