import {
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	OnInit,
	Output,
	ViewChild,
	inject,
} from '@angular/core'
import { CommonModule } from '@angular/common'

import {
	FooterUiComponent,
	ButtonUiComponent,
	ModalBottomUiComponent,
	ModalUiComponent,
	GenericErrorUiComponent,
	BoxItemUiComponent,
	LoadingPageComponent,
	AlertErrorUiComponent,
	ModalCenterComponent,
} from '@monorepo-channels/components/ui-bb'
import {
	getCuisines,
	getAddresses,
	AddressActions,
	CuisineActions,
	Cuisine,
	VenueActions,
	getStatusAddress,
	Address,
	getSelectedAddress,
	getSelectedCuisine,
	getSelectedNeighborhood,
	getVenueName,
} from '@monorepo-channels/channels/domain'
import {
	Observable,
	Subject,
	debounceTime,
	distinctUntilChanged,
	filter,
	map,
	startWith,
	switchMap,
	take,
} from 'rxjs'
import { Store } from '@ngrx/store'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { FormsModule } from '@angular/forms'
import { DivisorUiComponent } from '@monorepo-channels/components/ui-common'
import { isMobile, removeAccents } from '@monorepo-channels/shared/util-helpers'

@Component({
	selector: 'feature-bb-filter',
	standalone: true,
	imports: [
		CommonModule,
		FormsModule,
		ModalUiComponent,
		FooterUiComponent,
		ButtonUiComponent,
		ModalBottomUiComponent,
		GenericErrorUiComponent,
		BoxItemUiComponent,
		DivisorUiComponent,
		LoadingPageComponent,
		AlertErrorUiComponent,
		ModalCenterComponent,
	],
	templateUrl: './filter.component.html',
	styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {
	private store = inject(Store)
	public cuisines: Array<Cuisine & { selected: boolean }> = []

	@Output()
	public searchNameChanged = new EventEmitter<string>()
	public searchName = ''
	public searchCity = ''
	addresses$ = this.store.select(getAddresses)

	selectedAddress$: Observable<Address | null> = this.store.select(getSelectedAddress)
	selectedNeighborhood$: Observable<string | undefined> = this.store.select(getSelectedNeighborhood)

	selectedCuisine$ = this.store.select(getSelectedCuisine)
	openIndex: number | null = null
	viewNeighborhood: boolean = false

	selectedCuisine: (Cuisine & { selected: boolean }) | undefined
	private searchAddressChanged = new Subject<string>()
	filteredAddresses$ = this.searchAddressChanged.pipe(
		startWith(''), // Garante que todos os endereços sejam exibidos inicialmente
		distinctUntilChanged(),
		switchMap((searchCity: string) => {
			if (!searchCity.trim()) {
				return this.addresses$ // sem filtro se a pesquisa estiver vazia
			} else {
				return this.addresses$.pipe(
					map(addresses =>
						addresses.filter(address =>
							removeAccents(address.city)
								.toLowerCase()
								.includes(removeAccents(searchCity).toLowerCase())
						)
					)
				)
			}
		})
	)

	@ViewChild('modalBottomCuisine')
	modalBottomCuisine!: ModalBottomUiComponent

	@ViewChild('modalBottomAddress')
	modalBottomAddress!: ModalBottomUiComponent

	@ViewChild('modalCenterCuisine')
	modalCenterCuisine!: ModalCenterComponent

	@ViewChild('modalCenterAddress')
	modalCenterAddress!: ModalCenterComponent

	statusAddress$ = this.store.select(getStatusAddress)
	isMobile = isMobile()

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.isMobile = isMobile()
	}

	statusOpen = false
	openInput() {
		this.statusOpen = true
		if (this.inputSearch) this.inputSearch.nativeElement.focus()
	}
	closeInput() {
		this.searchName = ''
		this.statusOpen = false
		this.onInputSearchChange()
	}

	@ViewChild('inputSearch')
	inputSearch: ElementRef | undefined

	constructor() {
		this.store
			.select(getCuisines)
			.pipe(takeUntilDestroyed(), filter(Boolean))
			.subscribe({
				next: cuisines => {
					this.cuisines = cuisines.map(cuisine => ({ ...cuisine, selected: false }))
				},
			})

		this.searchNameChanged
			.pipe(
				distinctUntilChanged(),
				takeUntilDestroyed(),
				filter(name => name.length === 0)
			)
			.subscribe(() => {
				this.store.dispatch(VenueActions.cleanFilterVenuesByName())
			})

		this.searchNameChanged
			.pipe(
				debounceTime(500),
				distinctUntilChanged(),
				takeUntilDestroyed(),
				filter(name => name.length >= 3)
			)
			.subscribe(name => {
				this.store.dispatch(VenueActions.filterVenuesByNameV2({ name: name }))
			})

		this.store
			.select(getVenueName)
			.pipe(take(1))
			.subscribe({
				next: name => {
					if (name) {
						this.searchName = name
						this.openInput()
					}
				},
			})
	}

	onInputSearchChange() {
		this.searchNameChanged.emit(this.searchName)
	}

	onInputAddressChange() {
		this.searchAddressChanged.next(this.searchCity)
	}

	setCurrentNeighborhoodAndAddress(neighborhood: string | undefined, address: Address | null) {
		this.store.dispatch(AddressActions.setCurrentNeighborhood({ neighborhood }))
		if (address) this.store.dispatch(AddressActions.setCurrentAddress({ address }))
		this.store.dispatch(VenueActions.applyFilter())
		this.closeModalAddress()
	}

	ngOnInit(): void {
		this.store.dispatch(CuisineActions.getCuisines())
	}

	setCuisine(id: string) {
		this.cuisines = this.cuisines.map(cuisine => {
			if (cuisine._id === id) return { ...cuisine, selected: !cuisine.selected }
			return { ...cuisine, selected: false }
		})
		const selectedCuisine = this.cuisines.find(cuisine => cuisine._id === id)
		if (selectedCuisine) {
			this.selectedCuisine = { ...selectedCuisine, selected: false }
		}
	}

	applyFilter() {
		if (!this.selectedCuisine) return
		this.selectedCuisine.selected = true
		this.store.dispatch(CuisineActions.setCurrentCuisine({ cuisine: this.selectedCuisine }))
		this.store.dispatch(VenueActions.applyFilter())
		this.closeModalCuisine()
	}

	cleanFilter() {
		this.cuisines = this.cuisines.map(cuisine => ({ ...cuisine, selected: false }))
		this.store.dispatch(VenueActions.cleanFilter())
		this.store.dispatch(VenueActions.applyFilter())
	}

	cleanFilterCuisines() {
		this.selectedCuisine = undefined
		this.cuisines = this.cuisines.map(cuisine => ({ ...cuisine, selected: false }))
		this.store.dispatch(CuisineActions.cleanCuisines())
		this.store.dispatch(VenueActions.applyFilter())
		this.closeModalCuisine()
	}

	cleanFilterAdress() {
		this.store.dispatch(AddressActions.cleanAddress())
		this.store.dispatch(VenueActions.applyFilter())
	}

	tryLoadingAddressAgain() {
		this.store.dispatch(AddressActions.loadAddresses())
	}

	openModalAddress() {
		this.store.dispatch(AddressActions.getAddresses())
		if (this.isMobile) {
			this.modalBottomAddress.openModal()
		} else {
			this.modalCenterAddress.openModal()
		}
	}
	closeModalAddress() {
		if (this.isMobile) {
			this.modalBottomAddress.closeModal()
		} else {
			this.modalCenterAddress.closeModal()
		}
	}

	openModalCuisine() {
		if (this.isMobile) {
			this.modalBottomCuisine.openModal()
		} else {
			this.modalCenterCuisine.openModal()
		}
	}
	closeModalCuisine() {
		if (this.isMobile) {
			this.modalBottomCuisine.closeModal()
		} else {
			this.modalCenterCuisine.closeModal()
		}
	}

	openNeighborhood(i: number) {
		if (this.openIndex === i) {
			this.openIndex = null
			this.viewNeighborhood = false
		} else {
			this.openIndex = i
			this.viewNeighborhood = true
		}
	}
}
