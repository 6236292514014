import { Component, Input } from '@angular/core'
import { CommonModule } from '@angular/common'

import { BoxIconUiComponent } from '../box-icon/box-icon.component'

@Component({
	selector: 'ui-bb-ticket',
	standalone: true,
	imports: [CommonModule, BoxIconUiComponent],
	templateUrl: './ticket.component.html',
	styleUrls: ['./ticket.component.scss'],
})
export class TicketUiComponent {
	@Input({ required: true }) subTitle!: string
	@Input({ required: true }) icon!: string
}
