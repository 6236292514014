import { Component, HostBinding, Input } from '@angular/core'
import { CommonModule } from '@angular/common'

@Component({
	selector: 'ui-common-alert-top-generic',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './alert-top-generic.component.html',
	styleUrls: ['./alert-top-generic.component.scss'],
})
export class AlertTopGenericUiComponent {
	@Input({ required: true }) type: 'primary' | 'secondary' | 'danger' = 'primary'
	@Input({ required: true }) icon?: string

	@Input() widthBox!: string

	@HostBinding('style')
	get addProps() {
		return {
			'--widthBox': this.widthBox,
		}
	}
}
