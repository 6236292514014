import { Component, Input } from '@angular/core'
import { CommonModule } from '@angular/common'

@Component({
	selector: 'ui-bb-alert-top',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './alert-top.component.html',
	styleUrls: ['./alert-top.component.scss'],
})
export class AlertTopUiComponent {
	@Input({ required: true }) type: 'primary' | 'secondary' | 'danger' = 'primary'
	@Input({ required: true }) icon?: string
}
