import { Injectable, inject } from '@angular/core'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { ClientActions } from './client.actions'
import { mergeMap, map, catchError, switchMap, tap } from 'rxjs/operators'
import { of } from 'rxjs'
import { Store } from '@ngrx/store'
import { ClientGateway } from '../../gateways/client.gateway'
import { AppState } from '../helper.state'
import { getClient } from './client.selectors'
import { AuthService } from '../../auth/auth.service'
import { OrderHistoryActions } from '../order-history/order-history.actions'
import { RedirectToken } from '../../tokens/tokens'
import { AuthLiveloService, keys } from '../../gateways/auth-livelo.gateway'
import { DataLayerService } from '@monorepo-channels/shared/util-helpers'

@Injectable()
export class ClientEffects {
	private actions$ = inject(Actions)
	private clientGateway = inject(ClientGateway)
	private authLiveloGateway = inject(AuthLiveloService)
	private store = inject(Store<AppState>)
	private authService = inject(AuthService)
	private redirectUrl = inject(RedirectToken)
	private dataLayer = inject(DataLayerService)

	loginClientLiveloGetAuthorizationCode = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(ClientActions.loginClientLiveloGetAuthorizationCode),
				map(() => this.authLiveloGateway.getAuthorizationCode())
			)
		},
		{ dispatch: false }
	)

	loginSSOLivelo$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(ClientActions.loginClientLiveloSSO),
			mergeMap(({ code }) => {
				const codeVerifier = localStorage.getItem(keys.code_verifier)
				return this.clientGateway.loginLiveloSSO(code, codeVerifier)
			}),
			map(client => {
				this.dataLayer.logEvent({ event: 'CH_login_success', clientId: client.id })
				return ClientActions.loadClientSuccess({ client })
			}),
			catchError(error => {
				// console.log('ERRO AQUI ::', error)
				return of(ClientActions.loadClientFail({ error: error.error?.message }))
			})
		)
	})

	loginClient$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(ClientActions.loadClient),
			mergeMap(action => {
				return this.clientGateway.loginClient(action.id).pipe(
					map(client => ClientActions.loadClientSuccess({ client })),
					catchError(error => {
						return of(ClientActions.loadClientFail({ error: error?.error?.message }))
					})
				)
			})
		)
	})

	// Login Homologation
	loginHomologation$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(ClientActions.loginHomologation),
			mergeMap(({ clientId }) => {
				return this.clientGateway.loginHomologation(clientId).pipe(
					map(client => ClientActions.loadClientSuccess({ client })),
					catchError(error => {
						return of(ClientActions.loadClientFail({ error: error.error?.message }))
					})
				)
			})
		)
	})

	// // Login SSO
	loginClienUnicredSSO$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(ClientActions.loadClientUnicredSSO),
			mergeMap(({ token }) => {
				return this.clientGateway.loginUnicredSSO(token).pipe(
					map(client => ClientActions.loadClientSuccess({ client })),
					catchError(error => {
						return of(ClientActions.loadClientFail({ error: error.error?.message }))
					})
				)
			})
		)
	})

	loginClientSuccess = createEffect(() => {
		return this.actions$.pipe(
			ofType(ClientActions.loadClientSuccess),
			tap(({ client }) => {
				this.authService.setClientId(client)
				if (client.token) {
					this.authService.setToken(client.token)
				}
			}),
			map(() => OrderHistoryActions.getOrdersPaymentReady())
		)
	})

	logoutClient = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(ClientActions.logoutClient),
				tap(() => {
					this.authService.logout()
					window.location.replace(this.redirectUrl)
				})
			)
		},
		{ dispatch: false }
	)

	registerCard$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(ClientActions.addNewCard),
			concatLatestFrom(() => this.store.select(getClient)),
			mergeMap(([action, client]) =>
				this.clientGateway
					.createCardToken({
						clientId: client!.id,
						...(action.cpf ? { cpf: action.cpf } : {}),
						categoryId: client!.selectedCategory!.categoryId,
						cardProperties: { ...action.encryptData, billingAddress: action.billingAddress },
						...(action.mainCard ? { mainCard: action.mainCard } : {}),
					})
					.pipe(
						map(res =>
							ClientActions.registerCardSuccess({
								gatewayId: res.gatewayId,
								lastFourDigits: res.lastFourDigits,
							})
						),
						catchError(error =>
							of(ClientActions.registerCardFailure({ error: error?.error?.message }))
						)
					)
			)
		)
	})

	updateClientFront$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				ClientActions.updateClient,
				ClientActions.registerCardSuccess,
				ClientActions.removeCardSuccess,
				ClientActions.updateMainCardSuccess,
				ClientActions.updateClientBackendSuccess
			),
			concatLatestFrom(() => this.store.select(getClient)),
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			mergeMap(([action, client]: any) =>
				this.clientGateway.loginClient(client!.id).pipe(
					switchMap(client => {
						return [
							ClientActions.loadClientSuccess({ client }),
							ClientActions.selectedCardByGatewayId({
								gatewayId: action?.gatewayId,
								lastFourDigits: action?.lastFourDigits,
							}),
						]
					}),
					catchError(error => of(ClientActions.loadClientFail({ error: error?.error?.message })))
				)
			)
		)
	})

	updateClientBackend$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(ClientActions.updateClientBackend),
			concatLatestFrom(() => this.store.select(getClient)),
			switchMap(([action, client]) => {
				return this.clientGateway.updateClientBackend(client!.id, action.client).pipe(
					map(() => ClientActions.updateClientBackendSuccess()),
					catchError(response =>
						of(ClientActions.updateClientBackendFailure({ error: response?.error?.message }))
					)
				)
			})
		)
	})

	removeCard$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(ClientActions.removeCard),
			concatLatestFrom(() => this.store.select(getClient)),
			mergeMap(([action, client]) =>
				this.clientGateway.removeCard(client!.id, action.lastFourDigits).pipe(
					map(() => ClientActions.removeCardSuccess()),
					catchError(error => of(ClientActions.removeCardFailure({ error: error?.error?.message })))
				)
			)
		)
	})

	updateMainCard$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(ClientActions.updateMainCard),
			concatLatestFrom(() => this.store.select(getClient)),
			mergeMap(([action, client]) =>
				this.clientGateway.updateMainCard(client!.id, action.lastFourDigits).pipe(
					map(() => ClientActions.updateMainCardSuccess()),
					catchError(error =>
						of(ClientActions.updateMainCardFailure({ error: error?.error?.message }))
					)
				)
			)
		)
	})
}
