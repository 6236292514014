import { createFeatureSelector, createSelector } from '@ngrx/store'
import { CheckInState, featureCheckinName } from './checkin.state'

const getCheckinFeatureState = createFeatureSelector<CheckInState>(featureCheckinName)

export const getCheckinOrderId = createSelector(getCheckinFeatureState, state => state.orderId)
export const getCheckinErrorAvailabilities = createSelector(
	getCheckinFeatureState,
	state => state.errorAvailabilities
)
export const getCheckinErrorConfirm = createSelector(getCheckinFeatureState, state => state.errorConfirm)
export const getCheckinAvailabilities = createSelector(getCheckinFeatureState, state => state.availabilities)
export const getCheckinStatusAvailabilities = createSelector(
	getCheckinFeatureState,
	state => state.statusAvailabilities
)

export const getCheckInDate = createSelector(getCheckinFeatureState, state => state.checkinDate)
export const getCheckinStatusConfirm = createSelector(getCheckinFeatureState, state => state.statusConfirm)
