import { Component, ViewChild, inject } from '@angular/core'
import { CommonModule } from '@angular/common'

import { ModalBottomFailureComponent } from '@monorepo-channels/components/ui-bb'

import { Store } from '@ngrx/store'
import { CheckinActions, getCheckinErrorConfirm } from '@monorepo-channels/channels/domain'

import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { Actions, ofType } from '@ngrx/effects'
import { CheckinComponent } from '@monorepo-channels/feature-bb'
import { Router } from '@angular/router'
import { HeaderComponent } from '../../header/header.component'
import { isMobile } from '@monorepo-channels/shared/util-helpers'

@Component({
	standalone: true,
	imports: [CommonModule, HeaderComponent, ModalBottomFailureComponent, CheckinComponent],
	templateUrl: './checkin.page.html',
	styleUrls: ['./checkin.page.scss'],
})
export class CheckinPageComponent {
	private store = inject(Store)
	private actions$ = inject(Actions)
	router = inject(Router)
	isMobile = isMobile()

	errorConfirm = this.store.select(getCheckinErrorConfirm)

	@ViewChild('modalCheckinFailure')
	modalCheckinFailure!: ModalBottomFailureComponent

	constructor() {
		this.actions$
			.pipe(
				ofType(CheckinActions.checkInConfirmFailure, CheckinActions.loadAvailabilitiesFail),
				takeUntilDestroyed()
			)
			.subscribe({
				next: () => {
					this.modalCheckinFailure.openModal()
				},
			})
	}
}
