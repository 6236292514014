<div class="overlay" (click)="openCalendar()" [ngStyle]="{ display: seeCalendar ? 'block' : 'none' }"></div>

<div style="position: relative">
	<div class="initalMode" (click)="openCalendar()">
		<div class="initalMode__inside">
			<p class="initalMode__inside__title">Data</p>
			<p class="initalMode__inside__text" *ngIf="_selectedDate">
				{{ _selectedDate | date: "EEEE, d 'de' MMMM" }}
			</p>
			<p class="initalMode__inside__text deselect" *ngIf="!_selectedDate">Selecione uma data</p>
		</div>
		<i class="fa fa-chevron-up" style="rotate: 180deg"></i>
	</div>

	<div class="calendar" *ngIf="seeCalendar">
		<div class="calendar__initial">
			<p class="calendar__initial__title">Data</p>
			<p class="calendar__initial__text" *ngIf="_selectedDate">
				{{ _selectedDate | date: "EEEE, d 'de' MMMM" }}
			</p>
			<p class="initalMode__inside__text deselect" *ngIf="!_selectedDate">Selecione uma data</p>
		</div>
		<div class="calendar-header">
			<div class="calendar-header__title">
				<button (click)="changeMonth(-1)">
					<i class="fa fa-chevron-up" style="rotate: -90deg"></i>
				</button>
				<p>{{ today | date: 'MMMM yyyy' | titlecase }}</p>
				<button (click)="changeMonth(+1)">
					<i class="fa fa-chevron-up" style="rotate: 90deg"></i>
				</button>
			</div>
		</div>
		<div class="calendar-body">
			<ng-container *ngFor="let day of days; let i = index">
				<span class="calendar-body__weekday" *ngIf="i < 7">
					{{ day | date: 'EEE' | uppercase | slice: 0 : 3 }}
				</span>
			</ng-container>
			<div
				*ngFor="let day of days"
				class="calendar-body__day"
				[class.calendar-body__not-month]="notMonth(day)"
				[class.calendar-body__selected-day]="isSelected(day)"
				[class.calendar-body__availability-date]="availabilityDate(day)"
				(click)="onDayClick(day)"
			>
				{{ day | date: 'd' }}
			</div>
		</div>
	</div>
</div>
