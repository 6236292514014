import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ModalUiComponent } from '../modal.component'
import { CommonModule } from '@angular/common'

@Component({
	standalone: true,
	imports: [CommonModule, ModalUiComponent],
	selector: 'ui-bb-modal-center',
	template: `
		<ui-bb-modal modal="modalCenter" [isOpen]="isOpen" (clickOutside)="clickOutside.emit()">
			<div class="container" *ngIf="title">
				<h1 class="title">{{ title }}</h1>
				<span *ngIf="close" class="icon" (click)="closeModal()">x</span>
			</div>
			<ng-content></ng-content>
		</ui-bb-modal>
	`,
	styles: `
    .container {
        display: flex;
        justify-content: space-between;
        padding: 32px 16px;

    }
	.icon {
		cursor: pointer;
	}
    .title {
        font-size: 20px;
        font-family: 'BB Gastronomia Titulos';
        font-weight: 500;
        color: var(--content-primary);
    }
    
    `,
})
export class ModalCenterComponent {
	@Input() title?: string
	@Output() clickOutside = new EventEmitter<void>()
	@Input() close = true
	public isOpen = false

	constructor() {}

	openModal() {
		this.isOpen = true
	}

	closeModal() {
		this.isOpen = false
	}
}
