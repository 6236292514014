import { Component, HostBinding, Input } from '@angular/core'
import { CommonModule } from '@angular/common'

@Component({
	selector: 'ui-bb-title',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './title.component.html',
	styleUrls: ['./title.component.scss'],
})
export class TitleUiComponent {
	@Input({ required: true }) type!: 'primary' | 'secondary'
	@Input() styles: { paddingTop: string } = { paddingTop: '32px' }

	@HostBinding('style')
	get style() {
		return { '--padding-top': this.styles.paddingTop }
	}
}
