import { Component, EventEmitter, Input, Output } from '@angular/core'
import { CommonModule } from '@angular/common'
import { BoxIconUiComponent } from '../box-icon/box-icon.component'

function getIcon(value: string) {
	return `fa fa-${value}`
}

@Component({
	selector: 'ui-bb-box-item',
	standalone: true,
	imports: [CommonModule, BoxIconUiComponent],
	templateUrl: './box-item.component.html',
	styleUrls: ['./box-item.component.scss'],
})
export class BoxItemUiComponent {
	@Input({ transform: getIcon }) icon: string | null = null
	@Input({ required: true }) text!: string
	@Input() subTitle: string | null = null
	@Output() clickItem = new EventEmitter<void>()
}
