import { createFeatureSelector, createSelector } from '@ngrx/store'
import { PaymentState, featurePaymentName } from './payment.state'

const getPaymentFeatureState = createFeatureSelector<PaymentState>(featurePaymentName)

export const getStatusPayment = createSelector(getPaymentFeatureState, state => state.statusPayment)

export const isLoadingPayment = createSelector(
	getPaymentFeatureState,
	state => state.statusPayment === 'pending'
)
export const getPaymentOrder = createSelector(getPaymentFeatureState, state => state.paymentOrder)
export const getErrorPayment = createSelector(getPaymentFeatureState, state => state.errorPayment)
