import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Output, inject } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { Router } from '@angular/router'

@Component({
	selector: 'ui-bb-terms-and-conditions',
	standalone: true,
	imports: [CommonModule, FormsModule],
	templateUrl: 'terms-and-conditions.component.html',
	styleUrl: 'terms-and-conditions.component.scss',
})
export class TermsAndConditionsComponent {
	isChecked = false
	@Output() valueChange = new EventEmitter<boolean>()
	private router = inject(Router)

	valueChanged(event: Event) {
		const target = event.target as HTMLInputElement
		this.valueChange.emit(target.checked)
	}

	goTerms() {
		this.router.navigate(['terms'])
	}
}
