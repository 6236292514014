<ui-bb-modal modal="modalBottom" [isOpen]="isOpen" (clickOutside)="closeModal()">
	<article class="modal">
		<div class="header" (touchmove)="closeModal()">
			<div class="header__close"></div>
		</div>
		<div class="body">
			<p class="body__title" *ngIf="title">{{ title }}</p>
			<ng-content></ng-content>
		</div>
		<ng-content select="[footer]"></ng-content>
	</article>
</ui-bb-modal>
