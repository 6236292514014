@if (vm$ | async; as vm) {
	@if (vm.status === 'pending') {
		<ui-bb-loading-page />
	} @else if (vm.status === 'idle' || vm.status === 'failure') {
		<form [formGroup]="creditCardForm" (ngSubmit)="handleSubmit()">
			<div class="page px-16" style="padding-bottom: 8px">
				<ui-bb-title type="secondary">Cadastrar Cartão Ourocard</ui-bb-title>
			</div>
			<ui-bb-box-message template="makeItEasierPayment" />
			<div class="page px-16">
				<div class="inputs">
					<ng-container *ngIf="creditCardForm.controls.number as number">
						<label class="boxi7kr3 input-error-relative">
							<div class="boxi7kr3__title">Número de cartão</div>
							<input
								class="boxi7kr3__input"
								[placeholder]="!number.dirty ? '00000 0000 0000 0000' : ''"
								formControlName="number"
								mask="0000 0000 0000 0000"
							/>
							<div
								class="input-error input-error--touched"
								*ngIf="number.touched && number.invalid"
							>
								Insira um número válido de 0-16 dígitos
							</div>
							<div
								class="input-error input-error--touched"
								style="right: 0"
								*ngIf="number.dirty && number.hasError('binMatch')"
							>
								Cartão Inválido.
							</div>
						</label>
						<ui-common-divisor
							bottom="8px"
							[ngStyle]="{
								'--content-divider':
									number.touched && number.invalid ? 'var(--background-negative)' : ''
							}"
						/>
					</ng-container>
					<ng-container *ngIf="creditCardForm.controls.name as name">
						<label class="boxi7kr3 input-error-relative">
							<div class="boxi7kr3__title">Nome do cartão</div>
							<input
								class="boxi7kr3__input"
								pattern="[^()/><\][\x22,;|.]+"
								[placeholder]="!name.dirty ? 'Insira seu nome' : ''"
								formControlName="name"
							/>
							<div
								class="input-error input-error--touched"
								*ngIf="name.touched && name.invalid"
							>
								Insira o nome igual do cartão.
							</div>
						</label>
						<ui-common-divisor
							bottom="8px"
							[ngStyle]="{
								'--content-divider':
									name.touched && name.invalid ? 'var(--background-negative)' : ''
							}"
						/>
					</ng-container>
					<ng-container *ngIf="creditCardForm.controls.cpf as cpf">
						<label class="boxi7kr3 input-error-relative">
							<div class="boxi7kr3__title">CPF titular do cartão</div>
							<input
								id="cpf"
								class="boxi7kr3__input"
								[placeholder]="!cpf.dirty ? '000.000.000-00' : ''"
								mask="000.000.000-00"
								formControlName="cpf"
							/>

							<div class="input-error input-error--touched" *ngIf="cpf.touched && cpf.invalid">
								Complete o seu CPF.
							</div>
						</label>
						<ui-common-divisor
							bottom="8px"
							[ngStyle]="{
								'--content-divider':
									cpf.touched && cpf.invalid ? 'var(--background-negative)' : ''
							}"
						/>
					</ng-container>

					<div style="display: flex; gap: 16px; width: 100%">
						<ng-container *ngIf="creditCardForm.controls.validity as validity">
							<label class="boxi7kr3 input-error-relative" style="width: 155.5px">
								<div class="boxi7kr3__title">Validade MM/AA</div>
								<input
									class="boxi7kr3__input"
									mask="00/00"
									[dropSpecialCharacters]="false"
									[placeholder]="!validity.dirty ? '00/00' : ''"
									formControlName="validity"
								/>
								<div
									class="input-error input-error--touched"
									*ngIf="validity.touched && validity.invalid"
								>
									Data inválida
								</div>
								<ui-common-divisor
									bottom="8px"
									[ngStyle]="{
										'--content-divider':
											validity.touched && validity.invalid
												? 'var(--background-negative)'
												: ''
									}"
								/>
							</label>
						</ng-container>
						<ng-container *ngIf="creditCardForm.controls.cvv as cvv">
							<label class="boxi7kr3 input-error-relative" style="width: 155.5px">
								<div class="boxi7kr3__title titleCvv">
									CVV
									<ui-bb-tooltip tooltipPosition="left">
										São os 3 números que aparecem na parte de trás do seucartão.
									</ui-bb-tooltip>
								</div>

								<input
									class="boxi7kr3__input"
									mask="000"
									[placeholder]="!cvv.dirty ? '000' : ''"
									formControlName="cvv"
									type="text"
								/>
								<div
									class="input-error input-error--touched"
									*ngIf="cvv.touched && cvv.invalid"
								>
									Insira CVV válido
								</div>
								<ui-common-divisor
									bottom="8px"
									[ngStyle]="{
										'--content-divider':
											cvv.touched && cvv.invalid ? 'var(--background-negative)' : ''
									}"
								/>
							</label>
						</ng-container>
					</div>
				</div>

				<div class="inputs" formGroupName="billingAddress">
					<p style="margin-top: 32px; margin-bottom: 12px; font-weight: 500">
						Endereço de cobrança
					</p>
					<ng-container *ngIf="creditCardForm.controls.billingAddress.controls.zipCode as zipCode">
						<label class="boxi7kr3 input-error-relative">
							<div class="boxi7kr3__title">CEP</div>
							<input
								class="boxi7kr3__input"
								[placeholder]="!zipCode.dirty ? '00000 - 000' : ''"
								mask="00000-000"
								formControlName="zipCode"
							/>
							<div
								class="input-error input-error--touched"
								*ngIf="zipCode.touched && zipCode.invalid"
							>
								Insira CEP válido.
							</div>
						</label>
						<ui-common-divisor
							bottom="8px"
							[ngStyle]="{
								'--content-divider':
									zipCode.touched && zipCode.invalid ? 'var(--background-negative)' : ''
							}"
						/>
					</ng-container>
					<ng-container *ngIf="creditCardForm.controls.billingAddress.controls.address as address">
						<label class="boxi7kr3 input-error-relative">
							<div class="boxi7kr3__title">Endereço</div>
							<input
								class="boxi7kr3__input"
								[placeholder]="!address.dirty ? 'Seu endereço' : ''"
								formControlName="address"
								readonly
								style="background-color: var(--background-disabled); opacity: 0.5"
							/>
							<div
								class="input-error input-error--touched"
								*ngIf="address.touched && address.invalid"
							>
								Insira um endereço
							</div>
						</label>
						<ui-common-divisor
							bottom="8px"
							[ngStyle]="{
								'--content-divider':
									address.touched && address.invalid ? 'var(--background-negative)' : ''
							}"
						/>
					</ng-container>

					<div style="display: flex; gap: 16px; width: 100%">
						<div>
							<label class="boxi7kr3 input-error-relative" style="width: 155.5px">
								<div style="width: 155.5px">
									<ui-bb-select
										title="Estado"
										[disabled]="true"
										[options]="states"
										[selectedOption]="selectedState"
										(optionSelected)="setState($event)"
									></ui-bb-select>
								</div>
							</label>
							<ui-common-divisor bottom="8px" top="4px" />
						</div>
						<div *ngIf="creditCardForm.controls.billingAddress.controls.city as city">
							<label class="boxi7kr3 input-error-relative" style="width: 155.5px">
								<div class="boxi7kr3__title">Cidade</div>
								<input
									class="boxi7kr3__input"
									[placeholder]="!city.dirty ? 'Cidade' : ''"
									formControlName="city"
									readonly
									style="background-color: var(--background-disabled); opacity: 0.5"
								/>
								<div class="input-error" *ngIf="city.dirty && city.hasError('required')">
									Insira uma cidade
								</div>
							</label>
							<ui-common-divisor
								top="4px"
								bottom="8px"
								[ngStyle]="{
									'--content-divider':
										city.dirty && city.hasError('required')
											? 'var(--background-negative)'
											: ''
								}"
							/>
						</div>
					</div>
				</div>

				<div class="main-card">
					<span>Cartão padrão</span>
					<ui-common-divisor />
					<ui-bb-confirm-slider
						[line]="true"
						style="margin-left: auto"
						(sliderValueChange)="toggleMainCard($event)"
					></ui-bb-confirm-slider>
				</div>

				<ui-bb-alert-box>
					Para validação do seu cartão será feita uma cobrança de até R$5,00, em instantes será
					cancelada e o valor estornado
				</ui-bb-alert-box>
			</div>

			<div style="height: 40px"></div>

			<ui-bb-footer position="fixed">
				<div class="page px-16">
					<ui-bb-button
						footer
						type="secondary"
						[disabled]="creditCardForm.invalid"
						(clickButton)="handleSubmit()"
					>
						CADASTRAR
					</ui-bb-button>
				</div>
			</ui-bb-footer>
		</form>
	}
}
