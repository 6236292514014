import { Component, Input } from '@angular/core'

@Component({
	standalone: true,
	imports: [],
	selector: 'ui-common-edit-svg',
	template: `
		@if (type === 'default') {
			<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M20 5.24002C20.0008 5.10841 19.9756 4.97795 19.9258 4.85611C19.876 4.73427 19.8027 4.62346 19.71 4.53002L15.47 0.290017C15.3766 0.197335 15.2658 0.12401 15.1439 0.0742455C15.0221 0.0244809 14.8916 -0.000744179 14.76 1.67143e-05C14.6284 -0.000744179 14.4979 0.0244809 14.3761 0.0742455C14.2543 0.12401 14.1435 0.197335 14.05 0.290017L11.22 3.12002L0.290017 14.05C0.197335 14.1435 0.12401 14.2543 0.0742455 14.3761C0.0244809 14.4979 -0.000744179 14.6284 1.67143e-05 14.76V19C1.67143e-05 19.2652 0.105374 19.5196 0.29291 19.7071C0.480446 19.8947 0.7348 20 1.00002 20H5.24002C5.37994 20.0076 5.51991 19.9857 5.65084 19.9358C5.78176 19.8858 5.90073 19.8089 6.00002 19.71L16.87 8.78002L19.71 6.00002C19.8013 5.9031 19.8757 5.79155 19.93 5.67002C19.9397 5.59031 19.9397 5.50973 19.93 5.43002C19.9347 5.38347 19.9347 5.33657 19.93 5.29002L20 5.24002ZM4.83002 18H2.00002V15.17L11.93 5.24002L14.76 8.07002L4.83002 18ZM16.17 6.66002L13.34 3.83002L14.76 2.42002L17.58 5.24002L16.17 6.66002Z"
					[attr.fill]="color ?? '#AFAFAF'"
				/>
			</svg>
		}
		@if (type === 'line') {
			<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M3 15.9999H7.24C7.37161 16.0007 7.50207 15.9755 7.62391 15.9257C7.74574 15.8759 7.85656 15.8026 7.95 15.7099L14.87 8.77994L17.71 5.99994C17.8037 5.90698 17.8781 5.79637 17.9289 5.67452C17.9797 5.55266 18.0058 5.42195 18.0058 5.28994C18.0058 5.15793 17.9797 5.02722 17.9289 4.90536C17.8781 4.7835 17.8037 4.6729 17.71 4.57994L13.47 0.289939C13.377 0.196211 13.2664 0.121816 13.1446 0.0710475C13.0227 0.0202789 12.892 -0.00585938 12.76 -0.00585938C12.628 -0.00585937 12.4973 0.0202789 12.3754 0.0710475C12.2536 0.121816 12.143 0.196211 12.05 0.289939L9.23 3.11994L2.29 10.0499C2.19732 10.1434 2.12399 10.2542 2.07423 10.376C2.02446 10.4979 1.99924 10.6283 2 10.7599V14.9999C2 15.2652 2.10536 15.5195 2.29289 15.707C2.48043 15.8946 2.73478 15.9999 3 15.9999ZM12.76 2.40994L15.59 5.23994L14.17 6.65994L11.34 3.82994L12.76 2.40994ZM4 11.1699L9.93 5.23994L12.76 8.06994L6.83 13.9999H4V11.1699ZM19 17.9999H1C0.734784 17.9999 0.48043 18.1053 0.292893 18.2928C0.105357 18.4804 0 18.7347 0 18.9999C0 19.2652 0.105357 19.5195 0.292893 19.707C0.48043 19.8946 0.734784 19.9999 1 19.9999H19C19.2652 19.9999 19.5196 19.8946 19.7071 19.707C19.8946 19.5195 20 19.2652 20 18.9999C20 18.7347 19.8946 18.4804 19.7071 18.2928C19.5196 18.1053 19.2652 17.9999 19 17.9999Z"
					[attr.fill]="color ?? '#4969E6'"
				/>
			</svg>
		}
	`,
})
export class EditSVGComponent {
	@Input() color?: string
	@Input() type: 'line' | 'default' = 'default'
}
