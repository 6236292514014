@if (vm$ | async; as vm) {
	<div class="px-16 page">
		<h2 class="subTitle">Check-in</h2>
		<ui-bb-title type="secondary" *ngIf="vm.venue" [styles]="{ paddingTop: '0' }">
			{{ vm.venue.name }}
		</ui-bb-title>
	</div>
	<main class="page px-16">
		<div
			[style]="isMobile ? 'height: calc(100vh - 300px)' : 'height: 29vh'"
			*ngIf="vm.statusAvailability === 'pending' || vm.statusConfirm === 'pending'"
		>
			<ui-bb-loading-page>Carregando</ui-bb-loading-page>
		</div>
		<ng-container
			*ngIf="
				vm.statusAvailability === 'success' &&
				vm.statusConfirm !== 'pending' &&
				vm.statusConfirm !== 'success'
			"
		>
			<div class="box-info">
				<div class="info" *ngIf="vm.client">
					<p class="info__title">Nome</p>
					<p class="info__value">{{ vm.client.fullName }}</p>
				</div>
				<div class="info">
					<p class="info__title">Data</p>
					<p class="info__value">
						{{ checkinDate.today | date: "EEEE, d 'de' MMMM" | capitalizeFirst }}
					</p>
				</div>
			</div>
			<div style="height: 16px"></div>
			<ui-bb-counter
				[digit]="partySize"
				[maxDigit]="10"
				[minDigit]="1"
				(counter)="setPartysize($event)"
			>
				Pessoas por mesa
			</ui-bb-counter>
			<div style="height: 32px"></div>
			<ui-bb-confirm-slider (sliderValueChange)="changeSlider($event)" [isChecked]="sliderValue">
				Confirmo que estou acomodado em uma mesa.
			</ui-bb-confirm-slider>
		</ng-container>

		<ng-container *ngIf="vm.statusAvailability === 'unavailable'">
			<ui-bb-box-message template="checkinUnavailable" />

			<p *ngIf="vm.availabilities">
				Check-In disponível entre {{ vm.availabilities.startTime }} e {{ vm.availabilities.endTime }}
			</p>
		</ng-container>
		<ng-container *ngIf="vm.statusConfirm === 'success' && vm.venue && vm.client">
			<ui-bb-order-success
				type="checkin"
				[partySize]="partySize"
				[venue]="vm.venue"
				[orderDate]="checkinDate.today"
				[reservationTime]="checkinDate.time"
			/>
		</ng-container>
	</main>

	<div style="height: 100px" *ngIf="isMobile"></div>
	<div
		[ngClass]="isMobile ? 'boxTerms' : 'boxTermsDesk'"
		*ngIf="!vm.client?.agreements?.eula && vm.statusAvailability === 'success'"
		terms
		style="background-color: var(--background-primary)"
	>
		<ui-bb-terms-and-conditions (valueChange)="acceptTerms($event)"></ui-bb-terms-and-conditions>
	</div>
	<ui-bb-footer [position]="isMobile ? 'fixed' : 'static'" *ngIf="vm.statusConfirm !== 'success'">
		<div class="page px-16">
			<ui-bb-button
				style="width: 100%"
				type="secondary"
				[disabled]="isDisabled || vm.statusConfirm === 'pending'"
				*ngIf="vm.statusAvailability === 'success'"
				(clickButton)="confirmCheckin()"
			>
				FAZER CHECK-IN
			</ui-bb-button>
			<ui-bb-button
				style="width: 100%"
				type="secondary"
				*ngIf="vm.statusAvailability === 'failure' || vm.statusAvailability === 'unavailable'"
				(clickButton)="goToReservation()"
			>
				FAZER RESERVA
			</ui-bb-button>
		</div>
	</ui-bb-footer>
	<ui-bb-footer [position]="isMobile ? 'fixed' : 'static'" *ngIf="vm.statusConfirm === 'success'">
		<div class="page px-16" style="display: flex; gap: 19px">
			<ui-bb-button
				*ngIf="vm.client?.selectedCategory?.isRealCard"
				type="secondary-ghost"
				style="width: 100%"
				(clickButton)="goToOrders()"
			>
				VER MINHAS RESERVAS
			</ui-bb-button>
			<ng-container *ngIf="!vm.client?.selectedCategory?.isRealCard">
				<ui-bb-button type="secondary" style="width: 100%" (clickButton)="registerCard.emit()">
					CADASTRAR CARTÃO
				</ui-bb-button>
				<ui-bb-button
					type="secondary-ghost"
					style="width: 100%"
					(clickButton)="router.navigate([''])"
				>
					MAIS TARDE
				</ui-bb-button>
			</ng-container>
		</div>
	</ui-bb-footer>
}
