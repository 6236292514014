import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	Renderer2,
	booleanAttribute,
	inject,
} from '@angular/core'
import { CommonModule } from '@angular/common'

@Component({
	selector: 'ui-bb-modal',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalUiComponent implements OnChanges {
	@Input({ required: true }) modal!: 'modalBottom' | 'modalLeft' | 'modalCenter'
	@Input({ required: true }) isOpen!: boolean
	@Input() title: string | undefined
	@Input() height: string | undefined
	@Output() clickOutside = new EventEmitter<void>()
	@Output() touchMoveHeader = new EventEmitter<void>()
	@Input({ transform: booleanAttribute }) preventClickOutside = false
	private renderer = inject(Renderer2)

	ngOnChanges(): void {
		this.toggleBodyScroll()
	}

	clickOverlay() {
		if (this.preventClickOutside) return
		this.clickOutside.emit()
	}

	get toggleModal() {
		const style: { [key: string]: string } = {}
		if (this.modal === 'modalBottom' && this.isOpen) {
			style['bottom'] = '0'
		}
		if (this.modal === 'modalLeft' && this.isOpen) {
			style['left'] = '0'
		}
		if (this.modal === 'modalCenter' && this.isOpen) {
			style['top'] = '50%'
			style['left'] = '50%'
			style['transform'] = 'translate(-50%, -50%)'
			style['width'] = '373px'
			style['--modal-border-radius'] = '16px'
			style['--modal-position-bottom'] = 'unset'
		}
		if (this.height) {
			style['--modal-heigth'] = this.height
		}
		return style
	}

	private toggleBodyScroll(): void {
		const body = document.body
		if (this.isOpen) {
			this.renderer.setStyle(body, 'overflow', 'hidden')
		} else {
			this.renderer.removeStyle(body, 'overflow')
		}
	}
}
