import { Component, inject, ViewChild } from '@angular/core'
import { WalletBodyComponent } from './wallet-body/wallet-body.component'
import { HeaderComponent } from '../../header/header.component'
import {
	ButtonUiComponent,
	FooterUiComponent,
	ModalBottomUiComponent,
} from '@monorepo-channels/components/ui-bb'
import { Store } from '@ngrx/store'
import { ClientActions } from '@monorepo-channels/channels/domain'
import { Router } from '@angular/router'

@Component({
	standalone: true,
	imports: [
		WalletBodyComponent,
		HeaderComponent,
		ModalBottomUiComponent,
		FooterUiComponent,
		ButtonUiComponent,
	],
	template: `
		<bb-header></bb-header>
		<bb-wallet-body
			(mainClicked)="openModalBottomDefaultCard($event)"
			(deleteClicked)="openModalBottomDeleteCard($event)"
			(registerCardClicked)="router.navigate(['/carteira/cadastrar-cartao'])"
		/>
		<ui-bb-modal-bottom
			[title]="modal.title"
			style="color: var(--primitives-grey-500)"
			height="400px"
			#modalBottomWalletOptions
		>
			<p style="margin-top: 20px; font-size: 16px">{{ modal.text }}</p>
			<ui-bb-footer position="static" footer style="padding: 0 16px">
				<ui-bb-button
					type="secondary"
					style="width: 100%; font-size: 12px"
					(clickButton)="modal.click()"
				>
					{{ modal.buttonText }}
				</ui-bb-button>
			</ui-bb-footer>
		</ui-bb-modal-bottom>
	`,
})
export class WalletPageComponent {
	private store = inject(Store)
	@ViewChild('modalBottomWalletOptions')
	modalBottomWalletOptions!: ModalBottomUiComponent
	public router = inject(Router)
	modal = {
		title: '',
		text: '',
		buttonText: '',
		click: () => {},
	}
	openModalBottomDefaultCard(lastFourDigits: string) {
		this.modal = {
			title: 'Tornar cartão padrão',
			text: `Tem certeza que deseja tornar o cartão de final ${lastFourDigits} como seu cartão padrão?`,
			buttonText: 'TORNAR PADRÃO',
			click: () => {
				this.store.dispatch(ClientActions.updateMainCard({ lastFourDigits }))
				this.modalBottomWalletOptions.closeModal()
			},
		}
		this.modalBottomWalletOptions.openModal()
	}

	openModalBottomDeleteCard(lastFourDigits: string) {
		this.modal = {
			title: 'Deletar cartão',
			text: `Tem certeza que deseja remover o cartão de final ${lastFourDigits} da sua carteira?`,
			buttonText: 'DELETAR',
			click: () => {
				this.store.dispatch(ClientActions.removeCard({ lastFourDigits }))
				this.modalBottomWalletOptions.closeModal()
			},
		}
		this.modalBottomWalletOptions.openModal()
	}
}
